<template>
  <div class="filter">
    <!-- <div class="filter-inner-container">
      <span>Contracts:-</span>
      <v-select
        v-model="selectedContract"
        class="filter-select"
        label="name"
        :options="contractOptions"
        :reduce="(contract) => contract.name"
        @update:model-value="contractFilterChange"
      />
    </div> -->
    <div class="filter-inner-container">
      <span>Sites:-</span>
      <v-select
        v-model="selectedSite"
        class="filter-select"
        label="name"
        :options="siteOptions"
        :reduce="(site) => site.name"
        @update:model-value="sitesFilterChange"
      />
    </div>
    <div class="filter-inner-container">
      <span>Shifts:-</span>
      <v-select
        v-model="selectedShift"
        class="filter-select"
        label="name"
        :options="shiftOptions"
        :reduce="(shift) => shift.name"
        @update:model-value="shiftFilterChange"
      />
    </div>
    <div class="filter-inner-container">
      <span>Designation:-</span>
      <v-select
        v-model="selectedDesignation"
        class="filter-select"
        label="name"
        :options="designationOptions"
        :reduce="(designation) => designation.name"
        @update:model-value="designationFilterChange"
      />
    </div>
    <div class="filter-inner-container">
      <span>Department:-</span>
      <v-select
        v-model="selectedDepartment"
        class="filter-select"
        label="name"
        :options="departmentOptions"
        :reduce="(department) => department.name"
        @update:model-value="departmentFilterChange"
      />
    </div>
    <div
      v-if="gridView === 'attendance'"
      class="filter-inner-container"
    >
      <span>Attendance Type:-</span>
      <v-select
        v-model="selectedAttendanceType"
        style="--vs-dropdown-min-width: 290px;"
        class="filter-select"
        label="name"
        :options="attendanceTypeOptions"
        :reduce="(attendanceType) => attendanceType.name"
        @update:model-value="attendanceTypeFilterChange"
      />
    </div>
    <div
      v-if="isApprovalManager && showFilter"
      class="filter-inner-container"
    >
      <span>Reporting Officer:-</span>
      <v-select
        v-model="reportingManager"
        class="filter-select"
        label="name"
        :options="reportingManagerOptions"
        :reduce="(reportingManger) => reportingManger.name"
        @update:model-value="reportingMangerFilterChange"
      />
    </div>
  </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';

export default {
  name: 'EmployeesFilter',
  props: {
    currentFolder: {
      type: Object,
      default: () => {}
    },
    filtersData: {
      type: Object,
      default: () => {}
    },
    onGridReady: {
      type: Function,
      default: () => {}
    },
    updateFilterValues: {
      type: Function,
      default: () => {}
    },
    clearSelection: {
      type: Function,
      default: () => []
    }
  },
  setup(props) {
    const store = useStore();
    const currentViewFolder = props.currentFolder;
    const gridView = ref(currentViewFolder.name);
    let form = null;
    if (currentViewFolder.forms !== null && currentViewFolder.forms !== undefined) {
      form = currentViewFolder.forms?.attendance_create_form;
    }
    const showFilter = ref(true);
    if (form !== null && form !== undefined) {
      for (let i = 0; i < form.length; i++) {
        if (form[i].name === 'reporting_manager') {
          showFilter.value = form[i].visible_expr;
        }
      }
    }
    const selectedContract = ref();
    const selectedSite = ref();
    const selectedShift = ref();
    const selectedDesignation = ref();
    const selectedDepartment = ref();
    const selectedAttendanceType = ref();
    const contractOptions = computed(() => {
      const options = [];
      const contracts = props.filtersData?.contracts_id || [];
      for (let i = 0; i < contracts.length; i++) {
        options.push({ name: contracts[i]?.name?.eng || '', id: contracts[i]?.id, startPayrollOn: contracts[i]?.start_payroll_on, datePreviousMonth: contracts[i]?.date_of_previous_month});
      }
      return options;
    })
    const siteOptions = computed(() => {
      const options = [];
      const sites = props.filtersData?.sites_id || [];
      for (let i = 0; i < sites.length; i++) {
        options.push({ name: sites[i]?.name?.eng || '', id: sites[i]?.id});
      }
      return options;
    });

    const shiftOptions = computed(() => {
      const options = [];
      const shifts = props.filtersData?.shifts_id || [];
      for (let i = 0; i < shifts.length; i++) {
        options.push({ name: shifts[i]?.name || '', id: shifts[i]?.id});
      }
      return options;
    });

    const designationOptions = computed(() => {
      const options = [];
      if (gridView.value === 'attendance') {
        const products = props.filtersData?.designation || [];
        for (let i = 0; i < products.length; i++) {
          options.push({ name: products[i]?.name || '', id: products[i]?.id});
        }
      } else {
        const products = props.filtersData?.products_id || [];
        for (let i = 0; i < products.length; i++) {
          options.push({ name: products[i]?.name || '', id: products[i]?.id});
        }
      }
      return options;
    });

    const departmentOptions = computed(() => {
      const options = [];
      const departments = props.filtersData?.departments || [];
      for (let i = 0; i < departments.length; i++) {
        options.push({ name: departments[i]?.name?.eng || '', id: departments[i]?.id});
      }
      return options;
    })

    const attendanceTypeOptions = computed(() => {
      const options = [];
      const attendanceTypes = props.filtersData?.att_type || [];
      for (let i = 0; i < attendanceTypes.length; i++) {
        options.push({ name: attendanceTypes[i]?.name?.eng || '', id: attendanceTypes[i]?.id});
      }
      return options;
    })
    const reportingManager = ref();
    const reportingManagerOptions = ref([]);
    const bSettings = store.getters['bSettings'];
    let isApprovalManager = false;
    const reportingMangerList = [];
    const { getAOandRoList } = attendanceGridUtils();
    if (bSettings.env.code === 'admin') {
      isApprovalManager = true;
    } else if (bSettings.env.isAO) {
      isApprovalManager = true;
    }

    (async() => {
      if (isApprovalManager && showFilter.value) {
        const list = await getAOandRoList(reportingMangerList);
        reportingManagerOptions.value = list?.reportingMangerList;
      }
    })();

    function contractFilterChange() {
      if (selectedContract.value) {
        if (contractOptions.value.length !== 0) {
          for (let i = 0; i < contractOptions.value.length; i++) {
            if (Object.values(contractOptions.value[i]).indexOf(selectedContract.value) > -1) {
              props.updateFilterValues({type:'contract', value:contractOptions.value[i]})
              break;
            }
          }
        }
      } else {
        props.updateFilterValues({type:'contract', value:null})
      }
    }

    function sitesFilterChange() {
      if (selectedSite.value) {
        if (siteOptions.value.length !== 0) {
          for (let i = 0; i < siteOptions.value.length; i++) {
            if (Object.values(siteOptions.value[i]).indexOf(selectedSite.value) > -1) {
              props.updateFilterValues({type:'site', value:siteOptions.value[i]})
              break;
            }
          }
        }
      } else {
        props.updateFilterValues({type:'site', value:null})
      }
      props.onGridReady();
    }

    function shiftFilterChange() {
      if (selectedShift.value) {
        if (shiftOptions.value.length !== 0) {
          for (let i = 0; i < shiftOptions.value.length; i++) {
            if (
              Object.values(shiftOptions.value[i]).indexOf(selectedShift.value) > -1
            ) {
              props.updateFilterValues({type:'shift', value:shiftOptions.value[i]})
              break;
            }
          }
        }
      } else {
        props.updateFilterValues({type:'shift', value:null})
      }
      props.onGridReady();
    }

    function designationFilterChange() {
      if (selectedDesignation.value) {
        if (designationOptions.value.length !== 0) {
          for (let i = 0; i < designationOptions.value.length; i++) {
            if (
              Object.values(designationOptions.value[i]).indexOf(selectedDesignation.value) > -1
            ) {
              props.updateFilterValues({type:'designation', value:designationOptions.value[i]})
              break;
            }
          }
        }
      } else {
        props.updateFilterValues({type:'designation', value:null})
      }
      props.onGridReady();
    }

    function departmentFilterChange() {
      if (selectedDepartment.value) {
        if (departmentOptions.value.length !== 0) {
          for (let i = 0; i < departmentOptions.value.length; i++) {
            if (
              Object.values(departmentOptions.value[i]).indexOf(selectedDepartment.value) > -1
            ) {
              props.updateFilterValues({type:'department', value:departmentOptions.value[i]})
              break;
            }
          }
        }
      } else {
        props.updateFilterValues({type:'department', value:null})
      }
      props.onGridReady();
    }


    function attendanceTypeFilterChange() {
      if (selectedAttendanceType.value) {
        if (attendanceTypeOptions.value.length !== 0) {
          for (let i = 0; i < attendanceTypeOptions.value.length; i++) {
            if (
              Object.values(attendanceTypeOptions.value[i]).indexOf(selectedAttendanceType.value) > -1
            ) {
              props.updateFilterValues({type:'attendanceType', value:attendanceTypeOptions.value[i]})
              break;
            }
          }
        }
      } else {
        props.updateFilterValues({type:'attendanceType', value:null})
      }
      props.onGridReady();
    }

    function reportingMangerFilterChange() {
      if (reportingManager.value) {
        if (reportingManagerOptions.value.length !== 0) {
          for (let i = 0; i < reportingManagerOptions.value.length; i++) {
            if (
              Object.values(reportingManagerOptions.value[i]).indexOf(reportingManager.value) > -1
            ) {
              props.updateFilterValues({type:'reportingManager', value:reportingManagerOptions.value[i]})
              break;
            }
          }
        }
      } else {
        props.updateFilterValues({type:'reportingManager', value:null})
      }
      props.onGridReady();
    }

    return {
      gridView,
      showFilter,
      selectedContract,
      contractOptions,
      selectedSite,
      siteOptions,
      selectedShift,
      shiftOptions,
      selectedDesignation,
      designationOptions,
      selectedDepartment,
      departmentOptions,
      selectedAttendanceType,
      attendanceTypeOptions,
      isApprovalManager,
      reportingManager,
      reportingManagerOptions,
      contractFilterChange,
      sitesFilterChange,
      shiftFilterChange,
      designationFilterChange,
      departmentFilterChange,
      attendanceTypeFilterChange,
      reportingMangerFilterChange
    }
  }
}
</script>

<style>
.vs__actions {
  justify-content: flex-end;
}

.vs--searchable .vs__dropdown-toggle {
  height: 30px;
}

.filter {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: normal;
  background-color: #ffffff;
  color: #292929;
}

.filter-inner-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 2px;
  padding: 2px;
}

.filter-inner-container span {
  font-size:14px;
  margin: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filter-select {
  font-size: 13px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

  </style>
