import { toast } from 'vue3-toastify';
import attendanceGridUtils from './attendanceGridUtils';
import getProjections from './getProjections';
import postData from './postData';
import getFatRow from './fatRowGenerator';
import { sendMessage } from '../services/websocket';


const dataProcessor = (data) => {

  async function attendanceList(filterModel, params, monthYear, currentFolder, filterValues) {
    try {
      const { createTimeRange } = attendanceGridUtils();
      const dateRange = createTimeRange(monthYear)
      const { projectionForFolder } = getProjections();
      const projections = projectionForFolder('attendance');
      const txnParams = {
        refRows: true,
        page: {
          limitBag: {
            path: 'attendance.employees_id.id',
            limit: 100,
            offset: params.startRow,
            orderBy: 'code',
          },
          getRowCountBag: {
            path: 'attendance.employees_id.id',
            value: true,
          },
          orderByBag: {
            path: 'attendance.employees_id.code',
            value: 'desc'
          }
        },
        filters: {
          filterInput: [
            {
              path: 'attendance.attendance_date',
              operator: 'between',
              value: [dateRange[0], dateRange[1]],
            },
          ],
          joinop: 'and',
        },
        projections: projections,
      };

      if (filterValues.contract !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.allocations_id.contracts_id.id',
          value: [filterValues.contract.id],
        });
      }

      if (filterValues.designation !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.designation.id',
          value: [filterValues.designation.id],
        });
      }

      if (filterValues.department !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.employees_id.departments.id',
          value: [filterValues.department.id],
        });
      }

      if (filterValues.site !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.sites_id.id',
          value: [filterValues.site.id],
        });
      }

      if (filterValues.shift !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.shifts_id.id',
          value: [filterValues.shift.id],
        });
      }

      if (filterValues.attendanceType !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.att_type.id',
          value: [filterValues.attendanceType.id],
        });
      }

      if (filterValues.reportingManger !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.employees_id.reporting_manager.id',
          value: [filterValues.reportingManger.id],
        });
      }

      if (Object.keys(filterModel).length !== 0) {
        const filterInput = [];
        filterInput.push({
          operator: 'ilike',
          path: 'attendance.employees_id.persons_id.first_name',
          value: '%' + filterModel.employees.filter + '%',
        });
        filterInput.push({
          operator: 'ilike',
          path: 'attendance.employees_id.code',
          value: '%' + filterModel.employees.filter + '%',
        });
        txnParams.filters.filterInput.push({filterInput, joinop: 'or'});
      }
      const { listData } = postData();
      const list = await listData(currentFolder, txnParams);
      const txnAttendanceSummery = currentFolder.txns.txn_attendance_summary;
      txnAttendanceSummery['params'] = txnParams;
      const summary = await sendMessage(txnAttendanceSummery);
      const records = JSON.parse(summary.output.data.records[0]);
      console.log('I am here', records)
      return list;
    } catch (e) {
      console.log(e)
    }
  }

  async function dataCreation(data, sortModel, monthYear) {
    try {
      console.time('test1');
      const { fatRowGenerator } = getFatRow();
      const finalData = fatRowGenerator(data);
      const attData = finalData.reduce((att, dataForm) => {
        const employeeId = dataForm?.employees_id?.[0]?.id;
        const existingData = att.find((item) => item?.employees?.[0].id === employeeId);
        if (existingData) {
          const retVal = getFormattedDateField(dataForm.attendance_date);
          existingData[retVal] = dataGenerator(dataForm)[retVal];
        } else {
          const newData = dataGenerator(dataForm);
          newData.employees = dataForm.employees_id;
          att.push(newData);
        }
        return att;
      }, []);
      console.log('ATTDATA', attData);
      const { quickSort } = attendanceGridUtils();
      const sortedRow = quickSort(attData, 'code');
      console.timeEnd('test1');
      if (sortModel.length !== 0 && sortModel[0].sort !== null) {
        if (sortModel[0].sort === 'desc') {
          sortedRow.reverse();
        }
      }
      return sortedRow;
    }
    catch (e) {
      toast.error(e)
    }
  }

  function dataGenerator(dataForm) {
    const retVal = getFormattedDateField(dataForm.attendance_date);
    return {
      [retVal]: {
        id: dataForm.id,
        attendance_date: dataForm.attendance_date,
        sites_id: dataForm.sites_id,
        shifts_id: dataForm.shifts_id,
        att_type: dataForm.att_type,
        designation: dataForm.designation,
        working_minutes: dataForm.working_minutes,
        overtime_minutes: dataForm.overtime_minutes,
        exception: dataForm.exception,
        allocations_id: dataForm.allocations_id,
        attendance_status: dataForm.attendance_status,
        in_time: dataForm.observed_in_tmstmp,
        out_time: dataForm.observed_out_tmstmp,
        last_processed_by: dataForm.last_processed_by,
        leave_type: dataForm.leave_type,
        deficit_minutes: dataForm.deficit_minutes
      }
    };
  }

  function getFormattedDateField(date) {
    const formattedDate = new Date(date);
    formattedDate.setHours(formattedDate.getHours() + 5);
    formattedDate.setMinutes(formattedDate.getMinutes() + 30);
    const day = formattedDate.getDate();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[formattedDate.getMonth()];
    const year = formattedDate.getFullYear();
    return `${day}${month}${year}`;
  }
  return { attendanceList, dataCreation };
};

export default dataProcessor;
