const getters = {
  bSettings: (state, params) => {
    return state.bSettings;
  },
  folderGetters: (state, params) => {
    return state;
  },
  fieldName: (state, params) => {
    return state.fieldName;
  },
  propsGetter: (state, params) => {
    return state.props;
  },
  sessionIdGetter: (state, params) => {
    return localStorage.getItem('sessions_id');
  },
  serverPortGetter: (state, params) => {
    return localStorage.getItem('server_port');
  },
  listTxnArrayGetter: (state, params) => {
    return state.listTxnArray;
  }
};

export default getters;
