<template>
  <div class="d-flex radio-box">
    <div class="mt-1">
      {{ choices.name }}:
    </div>
    <div
      v-for="(item,index) in optionsToShow"
      :key="index"
    >
      <div
        class="form-check ms-2"
        :class="item.checked?'text-success':''"
      >
        <input
          :id="item.name"
          class="form-check-input mt-2"
          type="radio"
          name="flexRadioDefault"
          :checked="item.checked"
          @change="toggle(item.name)"
        >
        <label :for="item.name">
          {{ item.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
export default {
  name: 'WfmRadioButton',
  props:{
    choices:{
      type:Object,
      default:null
    }
  },
  emits: ['handle-change'],
  setup(props, context) {
    const optionsToShow = ref(props.choices.options)
    let activeChoice = ''
    function toggle(name) {
      optionsToShow.value.forEach((each) => {
        if (each.name === name) {
          each.checked = true
          activeChoice = name
        }
        else {
          each.checked = false
        }
      })
      context.emit('handle-change', activeChoice)
    }

    return {
      toggle,
      optionsToShow
    }
  }
}
</script>

<style>
.radio-box{
  font-size:1.2rem;
}
</style>
