const mutation = {
  bSettingsMutations: (state, params) => {
    state.bSettings = params;
  },
  folderMutations: (state, params) => {
    state.folderValue = params;
  },
  fieldNameMutations: (state, params) => {
    state.fieldName = params;
  },
  propsMutation: (state, params) => {
    state.props = params;
  },
  sessionIdMutation: (state, params) => {
    localStorage.setItem('sessions_id', params.sessions_id);
  },
  serverPortMutation: (state, params) => {
    localStorage.setItem('server_port', params);
  },
  listTxnArrayMutation: (state, params) => {
    state.listTxnArray = params;
  }
};

export default mutation;
