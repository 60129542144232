<template>
  <div
    v-if="readOnly"
    :data-test-id="dataTestId"
    class="list-view-class"
  >
    <div v-if="isMultipleDocp">
      <span
        v-for="(item, index) in value"
        :key="index"
      >
        {{ item?.label
        }}{{ value?.length > 1 && index != value.length - 1 ? ',' : '' }}
      </span>
    </div>
    <div v-else>
      {{ value }}
    </div>
  </div>
  <div v-else-if="showField">
    <span
      v-if="!showInTable && !readOnly"
      class="input-group my-2"
    >{{ docpOptions?.label[currentLanguage] }}
      <span
        v-if="isMandatory"
        class="ms-2"
        style="color: red"
      >*</span>
    </span>
    <div
      v-if="!showInTable"
      :class="{ 'd-block': isMissing || !isValid }"
      class="invalid-feedback p-0 text-xs"
    >
      {{ errorText }}
    </div>
    <v-select
      ref="testing"
      v-model="value"
      :class="{
        'mandatory-field': isMandatory && isMissing,
        'multi-docp-style form-input': isMultipleDocp,
        'style-choose form-input': !isMultipleDocp,
        'invalid-field':!isValid
      }"
      :multiple="isMultipleDocp"
      append-to-body
      :autoscroll="true"
      :clearable="!showInTable"
      :disabled="isReadOnly || isEditNotAllowed"
      :data-test-id="dataTestId"
      :uid="dataTestId"
      :input-id="dataTestId"
      :options="finalDocpToShow"
      :placeholder="placeHolder"
      :reduce="option => option"
      :components="{ Deselect, OpenIndicator }"
      clear-search-on-select
      :calculate-position="calculatePosition"
      @update:model-value="handleChange"
      @open="handleOpen"
      @search="searchDocp"

      @search:blur="closed"
    >
      <!-- no options slot -->
      <template #no-options="{ search, searching }">
        <template v-if="searching && loading">
          searching for <em>{{ search }}</em>.
        </template>
        <template v-else-if="!exceptionFolder && loading">
          loading list...
        </template>
        <template v-else-if="exceptionFolder">
          Type to Search..
        </template>
        <template v-else>
          {{ errorMsg || 'no record found' }}
        </template>
      </template>
      <!-- loader slot -->
      <template #spinner>
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm text-info"
          role="status"
          aria-hidden="true"
        />
      </template>
      <!-- options slot -->
      <template #option="option">
        <!-- <span :class="{'selected-option':value && (value === option.label || value?.[0]?.id === option?.id)}"> -->
        <span :class="{ 'selected-option': markSelectedOption(option) }">
          <div class="d-flex justify-space-between">
            <div name="label">{{ option.label }}</div>
          </div>
        </span>
      </template>
      <!-- footer slot -->
      <template #list-footer>
        <div
          v-if="!showInTable && !readOnly && isMultipleDocp && currentDocpFolder.name!='employees'"
          class="select-all-div"
        >
          <hr class="mb-0" />
          <input
            id="multiselect"
            v-model="selectAllInputValue"
            :data-test-id="dataTestId+'selectAll'"
            class="ms-4"
            type="checkbox"
            @change="selectAllOptions"
          />
          <label class="ms-2">Select All</label>
        </div>
      </template>

      <!-- <template #selected-option>
        <div v-if="selectAllValue">
          All {{ currentDocpFolder.name }} selected
        </div>
      </template> -->
    </v-select>
    <v-tooltip
      v-if="showInTable && value!=null && !isMultipleDocp"
      activator="parent"
      open-delay="600"
      location="top"
    >
      {{ value }}
    </v-tooltip>
  </div>
</template>

<script>
import getFolders from '../../composables/getFolders'
import postData from '../../composables/postData.js'
import { useStore } from 'vuex'
import makeFilter from '../../composables/makeFilter'
import { ref, watch, reactive, computed, inject, nextTick, toRef} from 'vue'
import { keys } from '../../provideKeys.js'
import { useI18n } from 'vue-i18n'
import getFatRow from '../../composables/fatRowGenerator.js'
import processExpr from '../../composables/processExprs'
import DropdownClose from '../../custom-renderer/DropdownClose.vue'
import DropdownToggle from '../../custom-renderer/DropdownToggle.vue'
import controlUtility from '../../composables/controlUtility'
export default {
  name: 'WfmDocpicker',
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  emits: ['input-change', 'mandatory-check'],
  setup(props, context) {
    //variables
    const testing = ref()
    const store = useStore()
    const i8vue = useI18n()
    const errorMsg = ref(null)
    const Deselect = DropdownClose
    const OpenIndicator = DropdownToggle
    const { listData } = postData()
    const currentLanguage = i8vue.locale.value
    const searchTerm = ref('')
    const docPickerList = ref([])
    const isValid = ref(true)
    const filterParams = ref([])
    const exceptionFolder = ref(false)
    const exceptionText = ref(null)
    let txnParams = reactive({ refRows: true })
    const showInTable = props.params?.showInTable ? true : false
    const readOnly = props.params?.readOnly ? true : false
    const docpOptions = props.params.fieldOptions
    const isMultipleDocp = ref(docpOptions?.is_multiple)
    const docpParams = toRef(props.params.data)
    const propertyName = docpOptions?.name
    const folderName = docpOptions?.path?.split('.')[0]
    const idOfTheFolder = docpParams.value?.[folderName]?.[0]?.id
      ? docpParams.value?.[folderName]?.[0]?.id
      : docpParams.value?.id
    const fieldPath = docpOptions?.path
    const detailType = showInTable ? true : false
    const docpInDetail = docpOptions?.docpInDetail ? true : false
    const detailTableName = docpInDetail ? docpOptions.detailTableName : null
    const currentDocp =
      docpParams.value?.[propertyName] ||
      docpParams.value?.[folderName]?.[0]?.[propertyName] ||
      docpParams.value?.[folderName]?.[0]?.[detailTableName]?.[0]?.[propertyName] ||
      docpParams.value?.[folderName]?.[0]?.[detailTableName]?.[0][propertyName]?.[0]
    const selectedValue = ref(currentDocp)
    const valueEntered = ref(false)
    const loading = ref(false)
    const { getAllFoldersList, getCurrentFolder } = getFolders()
    const mandatoryFields = props.params.mandatoryFields
    const invalidFields = props.params.invalidFields
    const { invalidCheck, mandatoryCheck } = controlUtility(invalidFields, mandatoryFields)
    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields
    const bSettings = store.getters['bSettings']
    const folderList = getAllFoldersList(bSettings)
    const currentFolder = ref(props.params.currentFolder)
    const leavesQuota = inject(keys.leavesQuota, null)
    // const folderFields = ref(props.folderFields || props.params.fieldParams.folderFields)
    const {
      processReadOnly,
      processVisibleExpr,
      processMandatoryExpr,
      processDefaultValue
    } = processExpr(currentFolder.value, docpParams.value)
    let currentValue = null

    const value = ref(isMultipleDocp.value ? [] : null)
    //to make the docp in detail hold its value when new row is added
    if (isMultipleDocp.value && selectedValue.value) {

      //if the docp has more than one value coming
      for (var i = 0; i < selectedValue.value?.length; i++) {
        if (selectedValue.value[i]?.code) {
          selectedValue.value[i].docpType = true
          selectedValue.value[i].detailType = detailType
          // docPickerList.value.push(selectedValue.value[i])
        }
      }
      currentValue = selectedValue.value

      const arrayValue = Array.isArray(currentValue)
        ? currentValue
        : [currentValue]
      //while updating values to display. filtering out undefined and 'incorrect' values(to stop ui crash)
      const validValues = arrayValue.map((item) => {

        if (item && item.name?.eng) {
          return { ...item, label: item.name.eng }
        } else if (item && item?.name) {
          return { ...item, label: item.name }
        }
      }).filter(Boolean)

      value.value = validValues
    } else {
      currentValue = selectedValue.value?.[0] ? selectedValue.value[0] : selectedValue.value
      if (currentValue) {
        value.value = currentValue?.persons_id?.[0]?.first_name?.eng || currentValue?.name?.eng || currentValue?.name || currentValue?.first_name?.eng || currentValue?.name?.eng || currentValue?.code
      }
      if (typeof selectedValue.value?.name != 'undefined') {
        selectedValue.value.docpType = true
        selectedValue.value.detailType = detailType
        docPickerList.value.push(selectedValue.value)
      }
    }
    watch(
      () => docPickerList.value,
      (newValue) => {
        try {
          docPickerList.value = newValue
        } catch (error) {
          console.log(error)
        }
      }
    )
    //computed
    const placeHolder = computed(() => {
      return getPlaceHolderValue()
    })
    const finalDocpToShow = computed(() => {
      return docPickerList.value.map((each) => {
        if (each.label == null) {
          if (exceptionFolder.value) {
            each.label = `${each?.persons_id?.[0]?.first_name?.eng || each?.name?.eng}  (${each.code})`
          }
          else {
            each.label = each?.persons_id?.[0]?.first_name?.eng || each?.name?.eng || each?.name || each?.code

          }
        }
        return each
      })
    })
    const currentDocpFolder = computed(() => {
      let folderName = null
      if (!docpOptions.source?.folder) {
        folderName = docpOptions.name
      } else {
        folderName = docpOptions.source.folder
      }
      return getCurrentFolder(folderName, folderList)
    })
    const defaultFilters = computed(() => {
      const df = docpOptions?.filters || null
      return df

    })
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        if (valueEntered.value) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(docpOptions?.readonly_expr)
      return retVal
    })

    const isVisible = computed(() => {
      const retVal = processVisibleExpr(docpOptions?.visible_expr)
      return retVal
    })
    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(docpOptions?.mandatory_expr) && isVisible.value && !isReadOnly.value
      if (!showInTable) {
        mandatoryCheck({
          propertyName: dataTestId.value,
          value: retVal
        })
      }
      return retVal
    })

    const defaultValue = computed(() => {
      const retVal = processDefaultValue(docpOptions?.default_value)
      return retVal
    })
    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      } else {
        if (isMandatory.value) {
          return true
        } else {
          return false
        }
      }
    })
    const errorText = computed(() => {
      if (!isValid.value) {
        return 'Invalid Input'
      }
      if (isMissing.value) {
        return 'Mandatory field'
      }
      else {
        return ''
      }
    })
    const dataTestId = computed(() => {
      if (showInTable || readOnly) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value?.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value?.name}/${propertyName}`
        } else {
          return `${docpOptions?.type}/${propertyName}`
        }
      }
    })
    const isAllValueSelected = computed(() => {
      if (finalDocpToShow.value?.length === selectedValue.value?.length) {
        return true
      }
      else {
        return false
      }
    })
    const selectAllInputValue = ref(isAllValueSelected.value)

    //methods
    const handleOpen = () => {
      nextTick(() => {
        getDocpickerList()
      });
    };

    const calculatePosition = async(dropdownList, component, { width, top, left }) => {
      if (!dropdownList) {
        console.error('Dropdown list not found');
        return;
      }
      await nextTick(); // Ensure the DOM is updated
      let topChanged = null
      const rect = dropdownList.getBoundingClientRect();
      const topDefault = parseInt(top, 10);

      if (topDefault > 770) {
        //if dropdown top value is going over 770 show on top
        topChanged = topDefault - rect.height - 38

      }
      else {
        topChanged = topDefault
      }

      dropdownList.style.position = 'absolute';
      dropdownList.style.top = `${topChanged}px`;
      dropdownList.style.left = left;
      dropdownList.style.width = width;

      dropdownList.style.zIndex = '1000';
    };


    function getPlaceHolderValue() {
      if (defaultValue.value) {
        return defaultValue.value
      } else {
        return docpOptions?.placeHolder || `select ${propertyName}`
      }
    }
    if (defaultValue.value) {
      getDefaultValue(defaultValue.value)
    }
    function markSelectedOption(option) {
      if (Array.isArray(value.value)) {
        //in case of mutidocp
        return value.value.some((each) => each.label === option.label)
      } else {
        if (value.value === option.label) {
          return true
        }
      }
    }
    async function getDefaultValue(defaultValue) {
      const defaultSelectedValue = defaultValue
      let itemsInReadOnlyDocp = []
      const readOnlyDocpFolder = currentDocpFolder.value
      //trying search docp with specific tag
      const { generateFilterParams } = makeFilter(
        defaultSelectedValue,
        readOnlyDocpFolder
      )
      filterParams.value = generateFilterParams()
      //getting the docplist from with the filter value defined}
      txnParams.filters = filterParams.value
      const list = await listData(readOnlyDocpFolder, txnParams)
      if (list.output.type === 'error') {
        console.log(list.output.message)
      } else {
        if (list.output.data.records.length > 0) {
          const { fatRowGenerator } = getFatRow()
          itemsInReadOnlyDocp = fatRowGenerator(list)
        }
      }
      for (let i = 0; i < itemsInReadOnlyDocp.length; i++) {
        if (defaultValue === itemsInReadOnlyDocp[i].code) {
          handleChange(itemsInReadOnlyDocp[i])
          break
        }
      }
    }

    function handleChange(item, removeAll, addAll) {
      try {
        let valueToSend = []
        if (item == null && !isMandatory.value) {
          item = {}
          item = currentDocp[0] || currentDocp
          console.log('current', currentDocp[0], currentDocp)
          item.toBeDeleted = true
        }
        if (item) {
          isValid.value = true
          invalidCheck({ propertyName: propertyName, value: false })
          valueEntered.value = true
          if (isMultipleDocp.value) {
            valueToSend = multiDocpChanged(item, removeAll, addAll)
          } else {
            valueToSend = [singleDocpChanged(item)]
          }
          //if the data is changed in ag grid
          if (showInTable) {
            docpParams.value[propertyName] = isMultipleDocp.value ? item : [item]
            props.params.cellValueChanged(docpParams.value)
          }
          //else emit event to control
          else {
            console.log('valSend', valueToSend)
            context.emit('input-change', {
              path: fieldPath,
              value: valueToSend,
              folderId: idOfTheFolder,
              mandatory: docpOptions?.mandatory_expr,
              type: 'docpicker'
            })
          }
        }
        else {
          isValid.value = false
          invalidCheck({ propertyName: propertyName, value: true })
        }
      } catch (err) {
        console.log(err)
      }
    }

    function multiDocpChanged(item, removeAll, addAll) {
      //handle multidocp changes
      let removedValues = []
      let addedValues = []
      if (removeAll) {
        removedValues = item
      }
      else if (addAll) {
        addedValues = item
      }
      else {
        removedValues = currentDocp?.filter((current) => !item?.some((newItem) => newItem.id === current.id)) || []
        addedValues = item?.filter((newItem) => !currentDocp?.some((current) => current.id === newItem.id)) || []
      }

      removedValues?.forEach((each) => {
        each.toRemove = true
        each.toAdd = false
        each.isMultipleDocp = true
        each.docpType = true
        each.detailType = detailType
      })
      addedValues?.forEach((each) => {
        each.toAdd = true
        each.toRemove = false
        each.isMultipleDocp = true
        each.docpType = true
        each.detailType = detailType
      })
      // console.log('add', addedValues, 'remove', removedValues)
      return removedValues?.concat(addedValues)
    }

    function singleDocpChanged(item) {
      //handle single docp changes
      const currentDocpId = currentDocp?.[0]?.id
      if (!currentDocpId) {
        item.docpNotSet = true
      } else {
        item.docpNotSet = false
        item.oldDocpId = currentDocpId
      }
      const newValue =
        item?.persons_id?.[0]?.first_name?.eng ||
        item?.name?.eng ||
        item?.name ||
        item?.first_name?.eng ||
        item?.name?.eng ||
        item?.code
      if (newValue) {
        if (!isReadOnly.value && !item.toBeDeleted) {
          value.value = newValue
        }
        else if (item.toBeDeleted) {
          item = {}
        }
      }
      item.docpType = true
      item.detailType = detailType
      item.isMultipleDocp = false
      return item
    }
    async function searchDocp(event) {
      try {
        // let filtersToAnd = {}
        searchTerm.value = event
        //TODO:- make filter composable will get a list of fields to filter on if any decide on object or array
        const filterFields = [
          {key:'code'},
          {key:'first_name'},
          {key:'last_name'},
          {key:'middle_name'},
          {key:'name'}
        ]
        if (propertyName === 'reporting_manager') {
          //add additional fields to filter
          filterFields.push({key:'employee_status', value:'approved', operator:'=', joinop:'and', propertyName:propertyName})
          filterFields.push({key:'employee_type', value:'regular', operator:'=', joinop:'and', propertyName:propertyName})
        }
        if (propertyName === 'employees_id' && currentFolder.value.name === 'leaves') {
          filterFields.push({key:'employee_status', value:'approved', operator:'=', joinop:'and', propertyName:propertyName})
          filterFields.push({key:'employee_type', value:'user', operator:'!=', joinop:'and', propertyName:propertyName})
          filterFields.push({key:'employee_type', value:'regular', operator:'!=', joinop:'and', propertyName:propertyName})

        }
        const { generateFilterParams } = makeFilter(
          searchTerm.value,
          currentDocpFolder.value,
          filterFields
          // filtersToAnd
        )
        if (searchTerm.value.length >= 2) {
          filterParams.value = generateFilterParams()
          if (currentDocpFolder.value.name !== 'employees' && currentDocpFolder.value.name !== 'persons') {
            if (Object.keys(filterParams.value).length !== 0) {
              loading.value = true
              getDocpickerList()
            }
          } else {
            loading.value = true
            getDocpickerList()
          }
        } else {
          filterParams.value = []
          getDocpickerList()
        }
      } catch (err) {
        console.log(err)
      }
    }
    async function getDocpickerList() {
      docPickerList.value = []
      try {
        if (currentDocpFolder.value != null && (currentDocpFolder.value.name === 'employees' || currentDocpFolder.value.name === 'persons')) {
          exceptionFolder.value = true
          exceptionText.value = `search for ${currentDocpFolder.value.name}`
          if (Object.keys(filterParams.value).length !== 0) {
            txnParams.filters = filterParams.value
            const list = await listData(currentDocpFolder.value, txnParams)
            if (list.output.type === 'error') {
              console.log(list.output.message)
            } else {
              if (list.output?.data?.records?.length > 0) {
                const { fatRowGenerator } = getFatRow()
                const listData = fatRowGenerator(list)
                docPickerList.value = []
                for (let i = 0; i < listData.length; i++) {
                  if (listData[i].deleted === false) {
                    docPickerList.value.push(listData[i])
                  }
                }
              }
            }
            loading.value = false
          }
        } else {
          if (currentDocpFolder.value?.name === 'leave_types' && currentFolder.value?.name === 'leaves') {
            loading.value = true
            const leaveTypesApplicable = []
            //gets the full list of leaveTypes
            txnParams.filters = filterParams.value

            const list = await listData(currentDocpFolder.value, txnParams)
            if (list.output.type === 'error') {
              console.log(list.output.message)
            } else {
              if (list.output.data.records.length > 0) {
                const { fatRowGenerator } = getFatRow()
                const listData = fatRowGenerator(list)
                for (let i = 0; i < listData.length; i++) {
                  if (
                    listData[i].deleted === false &&
                    listData[i].blanket_policy === false
                  ) {
                    leaveTypesApplicable.push(listData[i])
                  }
                }
              }
            }
            if (leavesQuota?.value) {
              // console.log('leaves quota is', leavesQuota.value)
              //if leavequota result is out match the code of the leaves and add to docpiker list to show in dropdown
              docPickerList.value = []
              if (leavesQuota.value.status != 'error') {
                const applicableLeaves =
                  leavesQuota?.value?.applicable_leave_types
                for (var i = 0; i < leaveTypesApplicable?.length; i++) {
                  for (var j = 0; j < applicableLeaves?.length; j++) {
                    if (
                      applicableLeaves[j].code === leaveTypesApplicable[i].code
                    ) {
                      leaveTypesApplicable[i].mandatory_document_upload =
                        applicableLeaves[j].mandatory_document_upload
                      leaveTypesApplicable[i].allow_half_day =
                        applicableLeaves[j].allow_half_day
                      docPickerList.value.push(leaveTypesApplicable[i])
                    }
                  }
                }
              } else {
                errorMsg.value = leavesQuota.value.message
              }
            } else {
              //if no employee is selected show all list
              //  docPickerList.value = []
              docPickerList.value = leaveTypesApplicable
            }
          } else {
            loading.value = true
            if (currentDocpFolder.value) {
              //default filter if given goes here
              txnParams.filters = defaultFilters.value
              const list = await listData(currentDocpFolder.value, txnParams)
              if (list.output.type === 'error') {
                console.log(list.output.message)
              } else {
                if (list.output.data.records.length > 0) {
                  const { fatRowGenerator } = getFatRow()
                  const listData = fatRowGenerator(list)
                  docPickerList.value = []
                  for (let i = 0; i < listData.length; i++) {
                    if (!listData[i].deleted) {
                      if (currentDocpFolder.value.name === 'leave_types') {
                        if (listData[i].blanket_policy === false) {
                          docPickerList.value.push(listData[i])
                        }
                      } else {
                        docPickerList.value.push(listData[i])
                      }
                    }
                  }
                }
              }
            } else {
              if (docpOptions.options) {
                //if options to show is given
                docPickerList.value = docpOptions?.options
              } else {
                //get the options values to show
                const listToShow = docpOptions?.name
                const folderName = listToShow.split('_')[0]
                const { getFolderByName } = getFolders()
                const folderToUse = getFolderByName(folderName, bSettings)
                const txnParams = {}
                txnParams.refRows = true
                const retVal = await listData(folderToUse, txnParams)
                const { fatRowGenerator } = getFatRow()
                const optionsToShow = fatRowGenerator(retVal)
                docPickerList.value = optionsToShow
              }
            }
          }
        }
        loading.value = false
        filterParams.value = []
        txnParams = { refRows: true }
      } catch (err) {
        loading.value = false
        console.log(err)
      }
    }
    const allSelectedString = ref()
    function selectAllOptions() {
      if (selectAllInputValue.value) {
        value.value = 'all selected'
        //if all the values to be added ,call handleChange with proper flags
        handleChange(finalDocpToShow.value, false, true)
      }
      else {
        handleChange(finalDocpToShow.value, true, false)
        value.value = []
      }
      //closing the dropdown after selection
      const inputElement = document.getElementById(dataTestId.value)
      inputElement.blur()
    }
    function getValue() {
      return value.value
    }
    function closed() {}

    // onMounted(() => {
    //   selectedValue.value = []
    // })
    // onMounted(() => {
    // on mounted setting data-test-id on selected element in docpicker
    // const docpElement = document.getElementById(`vs${controlId.value}__combobox`)
    // if (docpElement) {
    // const selectedDiv = docpElement.getElementsByClassName("vs__selected")[0]
    // selectedDiv?.setAttribute("data-test-id", dataTestId.value)
    // }

    // })

    return {
      value,
      readOnly,
      docpOptions,
      showInTable,
      currentLanguage,
      searchDocp,
      getDocpickerList,
      handleChange,
      isMandatory,
      isReadOnly,
      currentDocpFolder,
      exceptionFolder,
      isMissing,
      errorText,
      finalDocpToShow,
      isMultipleDocp,
      placeHolder,
      Deselect,
      OpenIndicator,
      selectAllInputValue,
      loading,
      showField,
      isEditNotAllowed,
      getValue,
      closed,
      testing,
      dataTestId,
      markSelectedOption,
      errorMsg,
      isValid,
      selectAllOptions,
      allSelectedString,
      handleOpen,
      calculatePosition,
      defaultFilters
    }
  }
}
</script>
<style>
.select-all-div{
  cursor: pointer;
}
.select-all-div:hover{
  color:green;
}
.style-choose .vs__dropdown-toggle {
  height: 38px !important;
  overflow: hidden;
  border-radius: 4px !important;
  border:none !important;
  color: var(--controls-font-color);

}
.vs--disabled {
  opacity: 0.5 !important;
}

.style-choose .vs__dropdown-menu {
  color: #2e688dff;
  max-height: 300px !important;
}
.selected-option {
  font-weight: 700;
  color: green;
}
.selected-option :hover {
  color: white;
}
.multi-docp-style .vs__dropdown-toggle {
  height: fit-content !important;
  border-radius: 4px !important;
}

.style-chooser .vs__selected {
  font-size: 2px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
}

.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black;
  border-radius: 4px;
}
.form-input {
  min-height: 5px;
  width: 100%;
  background-color: var(--controls-background-color) !important;
  color:var(--controls-font-color) !important;
  /* border:0.2px solid var(--controls-border-color); */
  cursor: pointer;
  border-radius: 8px;
}
</style>
<style scoped>
.input-group-text {
  background-color: antiquewhite;
}

.input-group {
  text-align: left;
}

.invalid-field{
    border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black;
    border-radius: 4px !important;


}
</style>
