<template>
  <div
    v-if="readOnly"
    :data-test-id="dataTestId"
    class="list-view-class"
  >
    {{ value }}
  </div>
  <div
    v-else-if="showField"
  >
    <span
      v-if="!showInTable && !readOnly && !inputOptions.fieldOnlyView"
      class="input-group my-2"
    >{{ inputOptions.label[currentLanguage] }}
      <span
        v-if="isMandatory"
        class="ms-2"
        style="color: red"
      >
        *
      </span>
    </span>
    <div
      v-if="!showInTable"
      :class="{ 'd-block': isMissing || !isValid }"
      class="invalid-feedback p-0 text-xs"
    >
      {{ errorText }}
    </div>
    <div
      class="d-flex input-field justify-space-between"
      :class="{ 'mandatory-field': isMandatory && isMissing,
                'invalid-field':!isValid,
                'form-control':isPassword}"
    >
      <input
        :id="dataTestId"
        v-model="value"
        :data-test-id="dataTestId"
        :disabled="isReadOnly || isEditNotAllowed"
        :type="(isPassword && showPassword )? 'password' : 'text'"
        :maxlength="isPassword ? 8 : null"
        autocomplete="current-password"
        :placeholder="placeHolder"
        :class="isPassword?'':'form-control form-input'"
        name="text"
        @input="testFunc"
        @change="handleChange"
        @keydown="onKeyDown"
      />
      <div
        v-if="isPassword"
        class="d-flex"
      >
        <v-icon
          fill="grey"
          :name="showPassword?'bi-eye-slash':'bi-eye'"
          scale="1.3"
          style="cursor: pointer;"
          @click="togglePassword()"
        />
        <span
          v-if="passwordStatus && !passwordStatus?.status && passwordStatus.strength!=0"
          class="invalid-password ms-1 my-auto"
        >
          <v-icon
            name="bi-question-circle"
            scale="1.2"
            style="cursor: pointer;"
          />
          <v-tooltip
            activator="parent"
            open-delay="600"
            color="red"
            location="top"
          >
            password should have 8 characters,
            atleast one captical letter,
            atleast one small letter,
            and atleast one symbol
          </v-tooltip>
        </span>
      </div>
    </div>
    <div
      v-if="isPassword && passwordStatus&& passwordStatus?.strength!=0"
      class="progress"
    >
      <div
        class="progress-bar d-none"
        :class="passwordStatusClass()"
        role="progressbar"
        :v-bind:aria-valuenow="passwordStatus?.strength"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: `${passwordStatus?.strength}%`}"
      >
        {{ passwordStatus?.msg }}
      </div>
    </div>

    <v-tooltip
      v-if="showInTable && value!=null"
      activator="parent"
      open-delay="600"
      location="top"
    >
      {{ value }}
    </v-tooltip>
  </div>
</template>

<script>
// oninput="this.style.height = &quot;&quot;;this.style.height = this.scrollHeight + &quot;px&quot;"

import {ref, computed, toRef} from 'vue'
import {useI18n} from 'vue-i18n';
import processExpr from '../../composables/processExprs';
import controlUtility from '../../composables/controlUtility';
import passwordStrength from '../../composables/passwordSrength'
export default {
  name: 'WfmText',
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  emits: ['input-change', 'invalid-check', 'mandatory-check'],

  setup(props, context) {
    //variables
    const i8vue = useI18n()
    const currentLanguage = i8vue.locale.value
    const value = ref(null)
    const showPassword = ref(true)
    const {checkStrength} = passwordStrength()
    const showInTable = props.params?.showInTable ? true : false
    const readOnly = props.params?.readOnly ? true : false
    const inputOptions = props.params.fieldOptions
    const inputParams = toRef(props.params.data)
    const folderName = inputOptions?.path?.split('.')[0]
    const fieldPath = inputOptions.path
    const idOfTheFolder = inputParams.value?.[folderName]?.[0]?.id ? inputParams.value?.[folderName]?.[0]?.id : inputParams.value?.id
    const propertyName = inputOptions?.name;
    const passwordStatus = ref()
    const isPassword = ref(false);

    if (propertyName === 'password_hash') {
      isPassword.value = true;
    }
    value.value = inputParams.value?.[propertyName] ? inputParams.value?.[propertyName] : inputParams.value?.[folderName]?.[0]?.[propertyName]
    const isValid = ref(true)
    const valueEntered = ref(false)

    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields
    const mandatoryFields = props.params.mandatoryFields
    const invalidFields = props.params.invalidFields
    const {invalidCheck, mandatoryCheck} = controlUtility(invalidFields, mandatoryFields)

    const currentFolder = ref(props.params.currentFolder)
    const { processReadOnly, processVisibleExpr, processMandatoryExpr, processDefaultValue, processValidExpr} = processExpr(currentFolder.value, inputParams.value)
    //computed
    const placeHolder = computed(() => {
      return inputOptions.placeHolder ? inputOptions.placeHolder : inputOptions.label.eng
    })
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        if (valueEntered.value) {
          return false
        }
        else {
          return true

        }
      }
      else {
        return false
      }
    })
    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(inputOptions.mandatory_expr) && isVisible.value
      if (!showInTable) {
        mandatoryCheck({
          propertyName: dataTestId.value,
          value: retVal,
          array:mandatoryFields
        })
      }
      return retVal
    })
    const isVisible = computed(() => {
      const retVal = processVisibleExpr(inputOptions.visible_expr)
      return retVal
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(inputOptions.readonly_expr)
      return retVal
    })
    const errorText = computed(() => {
      if (!isValid.value) {
        return 'Invalid Input'
      }
      if (isMissing.value) {
        return 'Mandatory field'
      }
      else {
        return ''
      }
    })
    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        if (isMandatory.value) {
          return true
        }
        else {
          return false
        }
      }
    })
    const defaultValue = computed(() => {
      const retVal = processDefaultValue(inputOptions.default_value)
      return retVal
    });
    if (defaultValue.value) {
      getDefaultValue()
    }
    const dataTestId = computed(() => {
      if (showInTable || readOnly) {
        if (showInTable) {
          console.log(props)
        }
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value.name}/${propertyName}`
        }
        else {
          return `${inputOptions.type}/${propertyName}`

        }
      }
    })

    //methods
    function getDefaultValue() {
      const defaultSelectedValue = defaultValue.value
      value.value = defaultSelectedValue
      handleChange()
    }
    function testFunc() {
      if (isPassword.value) {
        passwordStatus.value = checkStrength(value.value)
        isValid.value = passwordStatus.value?.status
        if (passwordStatus.value.strength == 0) {
          isValid.value = true
        }

      }

    }
    function passwordStatusClass() {
      const ret = {
        'd-block bg-poor': passwordStatus.value?.status == false,
        'd-block bg-weak': passwordStatus.value?.status && passwordStatus.value?.strength < 50,
        'd-block bg-good':passwordStatus.value?.status && passwordStatus.value?.strength > 50 && passwordStatus.value?.strength < 90,
        'd-block bg-great':passwordStatus.value?.status && passwordStatus.value?.strength > 90 && passwordStatus.value?.strength < 100,
        'd-block bg-strong':passwordStatus.value?.status && passwordStatus.value?.strength >= 100

      }
      return ret
    }
    function togglePassword() {
      showPassword.value = !showPassword.value
    }
    function handleChange() {
      const newValue = value.value
      if (newValue) {
        valueEntered.value = true
      }
      if (!/\S/.test(newValue)) {
        isValid.value = false
      }
      else {
        isValid.value = true
      }
      if (inputOptions.valid_expr !== undefined) {
        isValid.value = processValidExpr(inputOptions.valid_expr, newValue)
      }
      //empty check

      if (isValid.value) {
        //emit to check invalid
        // context.emit("invalid-check", { propertyName: propertyName, value: false })
        invalidCheck({ propertyName: propertyName, value: false })
        //if the data is changed in ag grid
        if (showInTable) {
          inputParams.value[propertyName] = newValue
          props.params.cellValueChanged(inputParams.value, props.params.rowNode)
        }
        //else emit event to control
        else {
          context.emit('input-change', { path: fieldPath, value: newValue, folderId: idOfTheFolder, mandatory: inputOptions.mandatory_expr })
        }
      }
      else {
        // context.emit("invalid-check", { propertyName: propertyName, value: true })
        invalidCheck({ propertyName: propertyName, value: true})
      }
    }
    function onKeyDown(event) {
      event.stopPropagation();
    }


    return {
      value,
      isPassword,
      onKeyDown,
      handleChange,
      inputOptions,
      showInTable,
      readOnly,
      currentLanguage,
      isMandatory,
      isReadOnly,
      isMissing,
      isValid,
      errorText,
      isVisible,
      propertyName,
      showField,
      isEditNotAllowed,
      placeHolder,
      dataTestId,
      testFunc,
      passwordStatus,
      passwordStatusClass,
      showPassword,
      togglePassword
    }
  }
}
</script>
<style lang="css">
.invalid-field{
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: red !important;

}

</style>

<style scoped>

.input-field input {
  font-size: 16px;
  display: block;
  width: 100%;
  outline: none;
  transition: all 0.2s;
}
.bg-poor{
background: #D2122E;
}
.bg-good{
background: #30a6b3;
}
.bg-weak{
  background: #fd5c63;

}
.bg-strong{
background: #027446;
}
.bg-great{
background: #50C878;
}
.invalid-password{
  font-size: 10px;
  font-style: italic;
  color:red;
}
.progress{
  height: 20px !important;
  margin-top: 10px !important;
}
.invalid-field{
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: red !important;

}
.input-group-text {
  background-color: antiquewhite;
}

/* .form-input {
  min-height: 5px;
  width: 100%;
  background-color: var(--controls-background-color);
  color:var(--controls-font-color);
  border:1px solid var(--controls-border-color);
} */

.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black
}

</style>
