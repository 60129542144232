<!-- eslint-disable vue/require-toggle-inside-transition -->
<template>
  <div
    :key="tabKey"
    class="mt-2"
  >
    <!-- navigation tabs -->
    <div class="px-4 d-flex justify-space-between">
      <div class="d-flex">
        <div
          v-for="(eachTab, index) in tabsList"
          :key="index"
          :data-test-id="tabsDataTestId(eachTab)"
          class="me-1 tabs"
          :class="{'active':(index == selectedIndex)}"
          @click="changeTab(index)"
        >
          <div class="box-title-profile">
            {{ eachTab.name }}
          </div>
          <!--making the first tab in tabList not closable -->
          <div
            v-if="index!=0"
            class="btn close-button"
            :data-test-id="tabsDataTestId(eachTab)+'/close'"
            @click.stop="delTab(index)"
          >
            <v-icon
              name="bi-x"
              scale="1"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- <hr class="hr mt-0 mb-1 "> -->
    <div
      class="tab-content mt-0 p-3"
      :class="customClass"
    >
      <!-- tab content -->
      <keep-alive>
        <component
          :is="tabsList[selectedIndex]?.component"
          v-bind="tabsList[selectedIndex]?.params"
          :key="tabsList[selectedIndex]?.name"
          @update-keep-alive-state="updateKeepAliveState"
          @add-tab="addTab"
          @del-tab="delTab"
          @refresh-data="refreshData"
        />
      </keep-alive>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import WfmButton from '../common/wfm-button.vue';
import ResetPassword from '../views/settings/ResetPassword.vue';
import WfmListItems from '../common/wfm-list-items.vue';
import WfmCalendar from '../common/wfm-calendar.vue';
import WfmGlobalSearch from './wfm-global-search.vue';
import FolderList from '../views/wfm-folders/FolderList.vue';
import wfmBoolenVue from './form-controls/wfm-boolen.vue';
import FolderForm from '../views/wfm-folders/FolderForm.vue';
import PayslipGenerate from '../views/quick-actions/PayslipGenerate.vue';
import ReportsView from '../views/reports/ReportsView.vue';
import PayslipsTemplate from '../views/wfm-folders/PayslipsTemplate.vue';
export default {
  name: 'WfmTabsHorizontal',
  components: {
    WfmButton,
    FolderList,
    ResetPassword,
    WfmListItems,
    WfmCalendar,
    WfmGlobalSearch,
    wfmBoolenVue,
    FolderForm,
    PayslipGenerate,
    ReportsView,
    PayslipsTemplate
  },
  props: {
    tabsList: {
      type: Array,
      default: () => []
    },
    handleClick : {
      type: Function,
      default: () => {}
    },
    selectedIndex:{
      type:Number,
      default:0
    },
    customClass:{
      type:String,
      default:null
    }
  },
  emits: ['add-tab', 'del-tab', 'handle-search', 'update-keep-alive-state', 'change-tab', 'handle-click', 'refresh-data'],
  setup(props, context) {
    const tabKey = ref(0)

    // construct the tabsList as a ref object so the tabs are reactive to changes
    // const tabsList = props.tabsList
    // Refs
    //not assigning props to variable. as props is reactive to the changes it receives
    // const selIndex = props.selectedIndex;
    const selectedTab = computed(() => props.tabsList[props.selectedIndex]);
    function changeTab(index) {
      //using the index to set the tab active
      context.emit('change-tab', index)
    }


    function handleClickFn(tabName) {
      console.log(tabName, props.tabsList, props.selectedIndex)
      // Call the handleClick function passed as a prop
      context.emit('handle-click', tabName)
    }
    function addTab(tabSpec) {
      console.log('wfm-tabs-horizontal add-tab emitter', tabSpec)
      context.emit('add-tab', tabSpec)
    }
    function refreshData(rowData) {
      context.emit('refresh-data', rowData)
    }
    function delTab(index, name) {
      console.log('wfm-tabs-horizontal del-tab emitter')
      const indexToPass = index ? index : props.selectedIndex
      if (props.tabsList.length > 1) {
        if (indexToPass) {
        //cases of tab delete
          if (indexToPass == props.selectedIndex) {
          //if selected tab is deleted change tab to the one previous to it
            changeTab(indexToPass - 1)
          }
          else {
          //if tabs before selected is deleted change the selectedindex-1
            if (indexToPass < props.selectedIndex) {
              changeTab(props.selectedIndex - 1)
            }
          }
        }
        context.emit('del-tab', indexToPass)
      }
    }
    // function handleSearch(searchTerm) {
    //   context.emit("handle-search", searchTerm, tabsList[selIndex.value])
    // }

    function updateKeepAliveState(selectionInfo) {
      context.emit('update-keep-alive-state', selectionInfo)
    }
    function tabsDataTestId(tab) {
      const baseTab =  props.tabsList[0].label

      return `${baseTab}/tabs/${tab.label}`
    }
    //commenting because selectedIndex is being changed properly when adding , changing, and removing tabs
    onMounted(() => {
      console.log('this is selectedIndex', selectedTab.value, props.tabsList)
    });

    return {
      selectedTab,
      changeTab,
      addTab,
      delTab,
      handleClickFn,
      updateKeepAliveState,
      tabsDataTestId,
      refreshData,
      tabKey
    };
  }
};
</script>

<style scoped>

  .tabs {
    cursor: pointer;
    padding: 15px;
    margin-top:2px;
    padding-bottom: 0px;
    background-color: rgb(229, 227, 210);
    margin-bottom: 0px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    position: relative;
    color:grey;
    font-weight: 700;
    text-transform: capitalize;
    font-size: small;
  }

  .active {
    margin-top:0px;
    display: inline-block;
    text-decoration: underline;
    text-decoration-color: black;
    text-decoration-thickness: 2px;
    background-color:var(--active-tab);
    color:black;
  }

  .tab-content {
    width: 100%;
    padding:3px;
    /* height: 100%; */
    height: 88vh;
    margin-bottom: 10px;
    background-color: var(--active-tab);
    border-radius: 8px;
  }

  .tab-content {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.tab-content::-webkit-scrollbar {
  display: none !important;
  /* Safari and Chrome */
     overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}
.close-button {
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;
  }

</style>
