import store from '../../store';
import { inject, computed } from 'vue';
import {keys} from '../provideKeys.js'

const processExpr = (currentFolder, params) => {
  //current folder set
  const folderToUse = currentFolder
  //currentFolderFields will be fields from current folder with values in case of update and without values in case of create
  //if injection not found, using default value of currentFoler.fields
  const currentFolderFields = inject(keys.folderFields, currentFolder?.fields)

  //getting bSettings from store
  const bSettings = store.getters['bSettings']
  const env = bSettings.env
  // Create an object to store dynamic variables
  const dynamicVariable = computed(() => {
    if (currentFolderFields?.value) {
      return  {
        [folderToUse?.name]: currentFolderFields.value
      }
    }
    else {
      return  {
        [folderToUse?.name]: currentFolderFields
      }
    }

  })
  // Function to process visibility expression
  const processVisibleExpr = (expr) => {
    const size = dynamicVariable.value?.[folderToUse?.name] ? Object.keys(dynamicVariable.value[folderToUse?.name]).length : 0;
    if (size > 0) {
      try {
        const tosend = {[folderToUse.name]:dynamicVariable.value[folderToUse.name], env, params}
        return !!new Function(`with(this){return ${expr}}`).call(tosend);
      } catch (error) {
        return false;
      }
    }

    return false;
  };
  // Function to process mandatory expression
  const processMandatoryExpr = (expr) => {
    const size = dynamicVariable.value?.[folderToUse?.name] ? Object.keys(dynamicVariable.value[folderToUse?.name]).length : 0;
    if (size > 0) {
      try {
        const tosend = {[folderToUse.name]:dynamicVariable.value[folderToUse.name], env, params}

        return !!new Function(`with(this){return ${expr}}`).call(tosend);
      } catch (error) {
        return false;
      }
    }

    return false;
  };

  // Function to process read-only expression
  const processReadOnly = (expr) => {
    const size = dynamicVariable.value?.[folderToUse?.name] ? Object.keys(dynamicVariable.value[folderToUse?.name]).length : 0;

    if (size > 0) {
      try {
        const tosend = {[folderToUse.name]:dynamicVariable.value[folderToUse.name], env, params}
        console.log('this is', params)

        return !!new Function(`with(this){return ${expr}}`).call(tosend);
      } catch (error) {
        return false;
      }
    }

    return false;
  };

  // Function to process read-only expression
  const processValidExpr  = (expr, value) => {
    try {

      const tosend = {[folderToUse.name]:dynamicVariable.value[folderToUse.name], value, params}
      return !!new Function(`with(this){return ${expr}}`).call(tosend);
    } catch (error) {
      return false;
    }

  };
  //Function to process defaultExpr
  const processDefaultValue = (expr) => {
    const size = dynamicVariable.value?.[folderToUse?.name] ? Object.keys(dynamicVariable.value[folderToUse?.name]).length : 0;
    const tosend = {[folderToUse?.name]:dynamicVariable.value[folderToUse?.name], env, params}
    if (size > 0) {
      try {
        return new Function(`with(this){return ${expr}}`).call(tosend);
      } catch (error) {
        return expr;
      }
    }

  };

  const formatExpr = (value, expr) => {
    try {
      return  eval(expr)
    } catch (error) {
      return value;
    }
  }

  return { processVisibleExpr, processMandatoryExpr, processReadOnly, processValidExpr, processDefaultValue, formatExpr};
};

export default processExpr;
