import _ from 'lodash';
const agGridModule = {
  namespaced: true,
  state: {
    detailGridApi: [],
    attendanceGridApi:null,
    gridTxns: [],
    attendanceGridRows: [],
  },
  getters: {
    detailGridApi: function(state) {
      return state.detailGridApi
    },
    attendanceGridApi:function(state) {
      return state.attendanceGridApi
    },
    gridTxnsGetter: (state) => {
      return state.gridTxns;
    },
    attendanceGridRows: function(state) {
      return state.attendanceGridRows;
    },
  },
  mutations: {

    detailGridApiMutation: function(state, value) {
      state.detailGridApi = value;
    },
    attendanceGridApiMutation:function(state, value) {
      state.attendanceGridApi = value
    },
    gridTxnsMutations: (state, value) => {
      state.gridTxns = value;
    },
    attendanceGridRowsMutation: function(state, value) {
      state.attendanceGridRows = value;
    },
  },
  actions: {
    detailGridApiSetter(context, value) {
      if (Object.keys(value).length === 0) {
        context.commit('detailGridApiMutation', value)
      }
      //if value is not empty and is not stored in the array of detailGridApis store it
      else if (
        !_.find(context.state.detailGridApi, { tableName: value.tableName })
      ) {
        value = [...context.state.detailGridApi, value]
        context.commit('detailGridApiMutation', value)
      }
      //if value is stored in array but needs to update with new state
      else {
        const indexOfDetailGrid = _.findIndex(context.state.detailGridApi, {tableName:value.tableName}, 0)
        context.state.detailGridApi.splice(indexOfDetailGrid, 1, value);
      }
    },
    attendanceGridApiSetter(context, value) {
      context.commit('attendanceGridApiMutation', value)
    },
    gridTxnsSetter(context, value) {
      const txnList = JSON.parse(JSON.stringify(context.state.gridTxns));
      txnList.push(value);
      context.commit('gridTxnsMutations', txnList);
    },
    attendanceGridRowsSetter(context, value) {
      context.commit('attendanceGridRowsMutation', value);
    },
  }
}

export default agGridModule;
