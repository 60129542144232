/*
makes filter params to send with txn when searching in list
searchTerm: current keyword to search
currentFolder: the folder detail in which to search
*/
//TODO: decide on type pf filedstoFilter and come up with loops to create filter accordingly
import getFolders from './getFolders'
import store from '../../store/index';
const makeFilter = (searchTerm, currentFolder, fieldsToFilter, pathToSearch) => {
  const bSettings = store.getters['bSettings'];
  const {getFolderByName} = getFolders()
  const personsFolder = getFolderByName('persons', bSettings)
  const folderName = currentFolder.name
  const fieldsInFolder = currentFolder?.fields
  const personFolderFields = personsFolder?.fields
  const excludedTypes = ['uuid', 'timestamp', 'number', 'detail', 'tsrange', 'boolean', 'dow_type', 'float', 'source_type', 'file', 'numeric', 'shift_range']
  let finalFilter = {}
  const orFilterInput = []
  const andFilterInput = []
  let filters = {}
  const searchTermToSend = searchTerm?.split(' ').join('%')
  function fieldsToFilterOn() {
    if (fieldsToFilter && fieldsToFilter.length > 0) {
      return fieldsToFilter
    }
    else {
      const retVal = []
      for (var key in fieldsInFolder) {
        if (!excludedTypes.includes(fieldsInFolder[key].type)) {
          retVal.push(key)
        }
      }
      return retVal
    }
  }
  function addToFilter(value, joiningOp) {
    if (joiningOp == 'or') {
      orFilterInput.push(value)
    }
    else if (joiningOp == 'and') {
      andFilterInput.push(value)
    }
  }
  function generateFilterParams() {
    const finalFields = fieldsToFilterOn()
    //normal folder filters
    // console.log('path to search', pathToSearch)
    if (pathToSearch && pathToSearch != '') {
      const toAdd = {path:pathToSearch, operator:'=', value:searchTerm}
      addToFilter(toAdd, 'or')
    }
    else {
      //if fields to filter given
      for (var j = 0; j < finalFields.length; j++) {
        const joiningOp = finalFields[j].joinop || 'or'
        const fieldKey = finalFields[j].key || finalFields[j]
        const propertyName = finalFields[j].propertyName || null
        const fieldValue = finalFields[j].value || '%' + searchTermToSend + '%'
        const operator = finalFields[j].operator || 'iLike'
        //addition to that employee filter to look in persons folder

        if (currentFolder.fields[fieldKey]?.type === 'docpicker') {
          if (!['registration_image', 'registration_device', 'documents'].includes(fieldKey)) {
            if (fieldsToFilter?.length > 0) {

              if (fieldsToFilter.some((each) => each.key === fieldKey)) {
                const keyArr = fieldKey.split('_');
                if (keyArr[keyArr.length - 1] !== 'id') {
                  const toAdd = {path:`${folderName}.${fieldKey}.code`, operator:operator, value:fieldValue}
                  addToFilter(toAdd, joiningOp)
                }

              }
            }
            else {
              const keyArr = fieldKey.split('_');
              if (keyArr[keyArr.length - 1] !== 'id') {
                const toAdd = {path:`${folderName}.${fieldKey}.code`, operator:operator, value:fieldValue}
                addToFilter(toAdd, joiningOp)
              }
            }
          }
          if (fieldKey === 'designation' || fieldKey === 'employee_type' || fieldKey === 'departments') {
            if (propertyName !== 'reporting_manager') {
              const toAdd = {path:`${folderName}.${fieldKey}.name`, operator:operator, value:fieldValue}
              addToFilter(toAdd, joiningOp)

            }
          }
        }
        else {
          if (currentFolder.fields[fieldKey]?.type != null) {
            const toAdd = {path:`${folderName}.${fieldKey}`, operator:operator, value:fieldValue}
            addToFilter(toAdd, joiningOp)
          }

        }

      }
      if (currentFolder.name === 'employees' || currentFolder.name === 'payslips' || currentFolder.name === 'leaves' || currentFolder.name === 'departments') {
        for (const key in personFolderFields) {
          let basePath = null
          if (currentFolder.name === 'employees') {
            basePath = 'employees'
          }
          else if (currentFolder.name === 'leaves' || currentFolder.name === 'payslips') {
            basePath = currentFolder.name + '.employees_id'
          }
          else if (currentFolder.name === 'departments') {
            basePath = currentFolder.name + '.department_head'
          }
          if (!excludedTypes.includes(personsFolder.fields[key].type) && personsFolder.fields[key].type !== 'docpicker') {
            if (fieldsToFilter?.length > 0) {
              if (fieldsToFilter.some((each) => each.key === key)) {
                const toAdd = {path:`${basePath}.persons_id.${key}`, operator:'iLike', value:'%' + searchTermToSend + '%'}
                addToFilter(toAdd, 'or')
              }
            }
            else {
              const toAdd = {path:`${basePath}.persons_id.${key}`, operator:'iLike', value:'%' + searchTermToSend + '%'}
              addToFilter(toAdd, 'or')
            }
          }
          if (personsFolder.fields[key]?.type === 'docpicker') {
            if (!['photo', 'registration_device', 'identity_details'].includes(key)) {
              if (fieldsToFilter?.length > 0) {
                if (fieldsToFilter.some((each) => each.key === key)) {
                  const keyArr = key.split('_');
                  if (keyArr[keyArr.length - 1] !== 'id') {
                    if (!['identity_complexion', ''].includes(key)) {
                      const toAdd = {path:`${basePath}.persons_id.${key}.code`, operator:'iLike', value:'%' + searchTermToSend + '%'}
                      addToFilter(toAdd, 'or')
                    }
                  }
                }
              }
              else {
                const keyArr = key.split('_');
                if (keyArr[keyArr.length - 1] !== 'id') {
                  if (!['identity_complexion', ''].includes(key)) {
                    const toAdd = {path:`${basePath}.persons_id.${key}.code`, operator:'iLike', value:'%' + searchTermToSend + '%'}
                    addToFilter(toAdd, 'or')
                  }
                }
              }
            }
          }
        }
      }

    }

    andFilterInput.push({
      filterInput:orFilterInput,
      joinop:'or'
    })
    finalFilter = {
      filterInput:andFilterInput,
      joinop:'and'
    }
    filters = {
      ...finalFilter}
    return filters;
  }
  return { generateFilterParams }
}

export default makeFilter;
