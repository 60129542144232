let retVal = {}
const getFieldOptions = () => {
  function fieldsData(path, currentFolder, bSettings, field) {
    /*
    WORKING..
    first get the path and know the number of elements in it
    traverse thru each element
    check if its docPicker..
    if its docPiker and its not end of the path,  change the folder and continue traverse
    if its docPicker and its end of path. return the field
    if its detail type in currentFolder and there is path following it
    check if that path points to docpicker.
    if it does point to a docpicker
    check it is the end of the path, if it is then return the docp
    else change folder and continue
    */
    //get the path array
    const pathArray = path.split('.')
    for (var i = 0; i < pathArray.length; i++) {

      //if its docp and we are not at the end of the path change folder and call fields data with new path
      if (currentFolder.fields[pathArray[i]]?.type === 'docpicker' && i + 1 != pathArray.length && i !== 0) {
        const docpSource = currentFolder.fields[pathArray[i]].source
        const newFolder = changeWorkingFolder(docpSource, bSettings)
        const newPathArray = pathArray.splice(i)
        const newPath = newPathArray.join('.')
        return fieldsData(newPath, newFolder, bSettings, field)
      }
      /* if detail type found */
      if (currentFolder.fields[pathArray[i]]?.type === 'detail') {
        const retVal = currentFolder.fields[pathArray[i]].fields[pathArray[i + 1]]
        retVal.docpInDetail = true,
        retVal.detailTableName = pathArray[i]
        retVal.path = path
        return retVal
      }
      if (i + 1 === pathArray.length && currentFolder.fields[pathArray[i]]) {
        retVal = currentFolder.fields[pathArray[i]]
        if (retVal) {
          Object.assign(retVal, {path})
          if (field?.file_upload) {
            Object.assign(retVal, {'file_upload':field?.file_upload})
          }
          if (field.format_expr != null) {
            retVal.format_expr = field.format_expr

          }

        }
        return retVal
      }
    }
  }
  //function to change the current working folder (when a docpicker type comes , change to source folder so that we can get the needed fields )
  function changeWorkingFolder(docpSource, bSettings) {
    const container = docpSource.container || docpSource.containers
    const foldertype = docpSource.foldertype
    const folderName = docpSource.folder
    const newCurrentFolder = bSettings[container].containers[foldertype].folders[folderName]
    return newCurrentFolder
  }


  return {  fieldsData, changeWorkingFolder }
}

export default getFieldOptions
