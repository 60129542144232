const generatePatch = () => {
  const allowedMainFolderUpdate = {'business.folders.persons': true};

  function handleDocp(curData, curField, curBaseData, indexes, curBaseDataIdx) {
    curData[curField] = [];
    curBaseData[curField].forEach((curDocpData) => {
      const docpOp = curDocpData.op;
      const docpKey = curDocpData.key;
      curData[curField].push(curDocpData.data.id);
      updateIndexes(indexes, `${curBaseData.id}__${curDocpData.data.id}`, curBaseDataIdx, docpOp, docpKey)
    })
  }

  function handleDetail(baseFolderConfig, curData, curField, curBaseData, indexes, curBaseDataIdx, nextIdx, txnBag, mainFolderPatches, params) {
    const detailFolderConfig = baseFolderConfig.fields[curField];
    curData[curField] = [];
    curBaseData[curField].forEach((curDetailObj) => {
      const finalDetailData = {};
      const curDetailData = curDetailObj.data;
      const detailFields = Object.keys(curDetailData);
      updateIndexes(indexes, curDetailData.id, curBaseDataIdx, curDetailObj.op, curDetailObj.key)
      for (let k = 0; k < detailFields.length; k++) {
        console.log('already', detailFields, detailFolderConfig.fields[detailFields[k]], k)

        if (detailFolderConfig.fields[detailFields[k]].type == 'docpicker') {
          handleMainFolder(curDetailData, detailFolderConfig, detailFields[k], nextIdx, indexes, txnBag, mainFolderPatches, params)
          handleDocp(finalDetailData, detailFields[k], curDetailData, indexes, curBaseDataIdx)
        }
        else {
          finalDetailData[detailFields[k]] = curDetailData[detailFields[k]];
        }
      }
      curData[curField].push(finalDetailData);
    })
  }
  function handleMainFolder(curBaseData, baseFolderConfig, curField, nextDataIdx, indexes, txnBag, mainFolderPatches, params) {
    const source = baseFolderConfig.fields[curField].source;
    const curPath = `${source.container}.${source.foldertype}.${source.folder}`;
    if (allowedMainFolderUpdate[curPath]) {
      const dataArr = curBaseData[curField];
      const finalDataArr = []
      const curfolder = source.folder;
      const curBaseDataIdx = nextDataIdx + (mainFolderPatches[curPath] != null ? mainFolderPatches[curPath].curIdx : 0);
      const newBaseFolderConfig = params[source.container]['containers'][source.foldertype]['folders'][curfolder];
      getPatchForPath(dataArr, curPath,  curBaseDataIdx, indexes, txnBag, newBaseFolderConfig, finalDataArr, curfolder, mainFolderPatches, params)
    }
  }
  function getPatchForPath(dataArr, curPath, curBaseDataIdx, indexes, txnBag, baseFolderConfig, finalDataArr, curfolder, mainFolderPatches, params) {
    const patchDataArr = [];
    const prevIdx = curBaseDataIdx;
    if (!mainFolderPatches.orderOfPaths.includes(curPath)) {
      mainFolderPatches.orderOfPaths.push(curPath);
      indexes.pathIndexes.push(curBaseDataIdx);
    //console.log('PATH->>',curPath, curBaseDataIdx)
    }
    /*Update indexes*/

    for (let i = 0; i < dataArr.length; i++) {
      const curData = {};
      const curBaseData = dataArr[i].data;
      const curBaseOp = dataArr[i].op;
      const curBaseKey = dataArr[i].key;
      curBaseDataIdx = curBaseDataIdx + 1; //For Data
      //console.log(curPath, 'data of ', i, curBaseDataIdx)
      //console.log(JSON.stringify(curData, null, 2))
      updateIndexes(indexes, curBaseData.id, curBaseDataIdx, curBaseOp, curBaseKey)
      if (curPath === txnBag.baseFolderPath) {
        updateFatrowPatch(finalDataArr, curfolder, curBaseData, baseFolderConfig.sql.pkey_colname)
      }
      const basefields = Object.keys(curBaseData);
      for (let j = 0; j < basefields.length; j++) {
        const curField = basefields[j];
        //console.log(curField)
        //console.log(baseFolderConfig.fields, baseFolderConfig.fields[curField], curField)
        let nextIdx = null;
        console.log('last', baseFolderConfig.fields, curField)

        if (baseFolderConfig.fields[curField].type === 'docpicker') {
          if (mainFolderPatches[curPath] != null) {
            nextIdx =  prevIdx + dataArr.length + 1 + mainFolderPatches[curPath].curIdx;
            mainFolderPatches[curPath].curIdx = mainFolderPatches[curPath].curIdx + 1;
          }
          else {
            nextIdx = prevIdx + dataArr.length + 1;
          }

          handleMainFolder(curBaseData, baseFolderConfig, curField, nextIdx, indexes, txnBag, mainFolderPatches, params)
          handleDocp(curData, curField, curBaseData, indexes, curBaseDataIdx)
        }
        else if (baseFolderConfig.fields[curField]?.type === 'detail') {
          handleDetail(baseFolderConfig, curData, curField, curBaseData, indexes, curBaseDataIdx, nextIdx, txnBag, mainFolderPatches, params)
        }
        else {
          curData[curField] = curBaseData[curField];
        }
      }
      patchDataArr.push(curData)
    }
    if (mainFolderPatches[curPath] != null) {
      mainFolderPatches[curPath].data.push(...patchDataArr)
    }
    else {
      mainFolderPatches[curPath] = {curIdx: 1, data:patchDataArr};
    }
  }

  function updateIndexes(indexes, id, idx, op, key) {
    if (op === 'U' && key == null) { throw Error('Please provide key with update operation') }
    indexes[id] = op === 'U' ? `${idx}__${op}__${key}` : `${idx}__${op}`;
  }
  function updateFatrowPatch(finalDataArr, basefolder, curBaseData, pkey) {
    try {
      const fatrowData = {}
      if (curBaseData.id == null) {
        throw Error('Please provide baseFolder id');
      }
      fatrowData[`${basefolder}_${pkey}`] = curBaseData.id;
      finalDataArr.push(fatrowData);
    }
    catch (err) {
      throw err
    }
  }

  function genPatch(params, txnBag) {
    try {
      const dataParams = txnBag.params;
      if (Object.keys(dataParams).length > 1) {
        throw Error('Can only apply data of one folder at a time');
      }
      let retval = [{}];
      if (Object.keys(dataParams).length > 0) {
        const finalDataArr = [{_path:'BASEDATA'}]
        const indexes = {}, mainFolderPatches = {orderOfPaths:[]};
        let curBaseDataIdx = 0;
        const curPath = txnBag.baseFolderPath;
        const dataArr =  dataParams[curPath]
        const curPathArr = curPath.split('.');
        const container = curPathArr[0];
        const foldertype = curPathArr[1];
        const curfolder = curPathArr[2];
        const baseFolderConfig = params[container]['containers'][foldertype]['folders'][curfolder];
        if (curPath === txnBag.baseFolderPath) {
          curBaseDataIdx = curBaseDataIdx + dataArr.length + 1;
          indexes.dataStart = 1;
          indexes.dataEnd =  dataArr.length + 1;
          indexes.pathIndexes = [] //[curBaseDataIdx];
        }
        getPatchForPath(dataArr, curPath, curBaseDataIdx, indexes, txnBag, baseFolderConfig, finalDataArr, curfolder, mainFolderPatches, params)
        //console.log(JSON.stringify(mainFolderPatches, null, 2))
        mainFolderPatches.orderOfPaths.forEach((path) => {
          finalDataArr.push({_path: path});
          finalDataArr.push(...mainFolderPatches[path].data);
        })
        //finalDataArr.push({_path: curPath});
        //finalDataArr.push(...patchDataArr);
        finalDataArr.push(indexes)
        //console.log(JSON.stringify(finalDataArr, null, 2))
        retval = finalDataArr;
      }
      return retval;
    //  console.log(JSON.stringify(indexes, null, 2))
    }
    catch (err) {
      throw err
    }
  }


  return { genPatch };
};

export default generatePatch;
