<template>
  <div
    v-if="totalRowsCount != 0"
    :data-test-id="dataTestId"
    class="d-flex justify-content-end pagination"
  >
    <div class="d-flex">
      <div class="my-auto me-3">
        Page Size
        <input
          v-model="limit"
          name="page-size"
          type="number"
          class="page-size"
          @input="limitValidation"
        />
      </div>
      <div
        name="info-string"
        class="my-auto me-2"
      >
        <span>{{ infoString }}</span>
      </div>
    </div>
    <div class="my-auto d-flex">
      <div
        class="me-3"
        name="first-page"
        @click="goToFirstPage(1)"
      >
        <v-icon
          name="md-firstpage"
          scale="1.1"
          class="mt-1"
        />
      </div>
      <div
        class="me-3"
        name="previous-page"
        @click="goToPreviousPage(currentPage)"
      >
        <v-icon
          name="md-navigatebefore"
          scale="1.1"
          class="mt-1"
        />
      </div>
      <div class="mx-2 my-auto">
        Page {{ currentPage }} of {{ totalPageCount }}
      </div>
      <div
        class="me-3"
        name="next-page"
        @click="goToNextPage(currentPage)"
      >
        <v-icon
          name="md-navigatenext"
          scale="1.1"
          class="mt-1"
        />
      </div>
      <div
        class="me-3"
        name="last-page"
        @click="gotoLastPage(totalPageCount)"
      >
        <v-icon
          name="md-lastpage"
          scale="1.1"
          class="mt-1"
        />
      </div>
    </div>
  </div>
  <div
    v-else
    :data-test-id="dataTestId"
    class="d-flex justify-content-end p-1 pagination"
  >
    No Pages To Show
  </div>
</template>

<script>
import { ref, computed, toRefs } from 'vue'
import getUtility from '../composables/getUtility'

export default {
  name: 'WfmPagination',
  props: {
    totalRowsCount: {
      type: Number,
      default: null
    },
    recordsShowing: {
      type: Number,
      default: null
    },
    dataTestId:{
      type:String,
      default:null
    }
  },
  emits: ['get-page'],
  setup(props, context) {
    //variables
    const offsetValue = ref(0)
    const currentPage = ref(1)
    const limit = ref(50)
    const { recordsShowing, totalRowsCount } = toRefs(props) // props to ref
    const { debounce } = getUtility()

    //computed
    const totalPageCount = computed(() => {
      const remainder = totalRowsCount.value % limit.value
      const quotient = totalRowsCount.value / limit.value
      if (!isNaN(remainder)) {
        if (remainder > 0) {
          return Math.floor(quotient) + 1
        } else {
          return Math.floor(quotient)
        }
      } else {
        return 1
      }
    })

    const infoString = computed(() => {
      const start = offsetValue.value + 1
      const finish = Math.min(
        offsetValue.value + recordsShowing.value,
        totalRowsCount.value
      )
      const result = `Showing ${start} - ${finish} of ${totalRowsCount.value}`
      return result
    })
    //methods

    const debouncedGetPage = debounce(() => {
      //make pagination params and emit to call listTxn with updated limit and offset
      const paginationParams = {
        limit: limit.value,
        offset: offsetValue.value
      }
      context.emit('get-page', paginationParams)
    }, 1500)

    async function goToFirstPage() {
      if (currentPage.value !== 1) {
        currentPage.value = 1
        offsetValue.value = null
        debouncedGetPage()
      }
    }

    async function gotoLastPage() {
      const lastPage = totalPageCount.value
      if (currentPage.value !== lastPage) {
        currentPage.value = lastPage
        offsetValue.value = limit.value * (totalPageCount.value - 1)
        debouncedGetPage()
      }
    }
    async function goToNextPage() {
      if (currentPage.value < totalPageCount.value) {
        currentPage.value++
        offsetValue.value += limit.value
        debouncedGetPage()
      }
    }
    async function goToPreviousPage() {
      if (currentPage.value > 1) {
        currentPage.value--
        offsetValue.value = Math.max(offsetValue.value - limit.value, 0)
        debouncedGetPage()
      }
    }
    function limitValidation(event) {
      if (event.target.value <= 0) {
        limit.value = null
      }
      changeTheLimit()

    }

    //changing the number of rows to show
    function changeTheLimit() {
      offsetValue.value = 0
      currentPage.value = 1
      debouncedGetPage()
    }


    return {
      offsetValue,
      infoString,
      currentPage,
      totalPageCount,
      goToFirstPage,
      goToPreviousPage,
      goToNextPage,
      gotoLastPage,
      limit,
      changeTheLimit,
      limitValidation
    }
  }
}
</script>

<style scoped>
.page-size {
  border: 1px solid var(--main-font-color);
  padding: 4px;
  border-radius: 5px;
  color:var(--main-font-color);
  width: 70px;
}

.pagination {
  padding:5px !important;
  background-color: var(--main-background-color);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 1rem;
  color: var(--main-font-color);
  cursor: pointer;
}
</style>
