<template>
  <div
    v-for="(eachFolder, index) in filterByTerm"
    :key="index"
    :data-test-id="folderListTestId(eachFolder)"
    @click="getValue(eachFolder)"
  >
    <div
      class="mt-1 folders"
    >
      <v-icon
        :name="getIcon(eachFolder.name).name"
        scale="1.3"
      />
      <span class="folder-names ms-1">{{ convertToTitleCase(eachFolder?.label[currentLanguage] || currentLanguage) }}</span>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import getFolders from '../../../composables/getFolders'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n';
import useIcons from '../../../composables/getIcons'

export default {
  name: 'FolderListTab',
  props:{
    searchTerm:{
      type:String,
      default:''
    }
  },
  emits: ['hide-sidebar'],
  setup(props, context) {
    //store
    const store = useStore()
    const bSettings = store.getters['bSettings']
    //Refs
    const i8vue = useI18n()
    const currentLanguage = ref(i8vue.locale.value)
    const router = useRouter()
    const {getIcon} = useIcons()
    //computed
    const folderList = computed(() => {
      return getFoldersList(bSettings)
    })

    function convertToTitleCase(str) {
      return str.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    const filterByTerm = computed(() => {
      return folderList.value.filter((folder) => {
        return (
          folder?.label.eng
            .toLowerCase()
            .indexOf(props.searchTerm.toLowerCase()) != -1
        )
      })
    })


    //composable
    const { getFoldersList } = getFolders();
    //Methods
    async function getValue(folder) {
      try {
      // store.dispatch("commonModule/formDataSetter", {});
        if (folder.uiFlags.grid_view) {
        //when grid view flag is on we show attendance grid
          folder = bSettings.output.data.records[0].business.containers.folders.folders.attendance_edits;
        }
        if (folder.name === 'allocations') {
          router.push({ name: 'Allocation' });
        } else if (folder?.uiFlags?.list_view) {
        //when list_view is on we show list view
          router.push({ name: 'tabs', params: { name: folder.name } });
        } else {
          router.push({ name: 'Attendance' });
        }
        context.emit('hide-sidebar')

      } catch (err) {
        console.log('error', err)
      }
    }


    function folderListTestId(folder) {
      return `sidebarTab/${folder.name}`
    }
    return {
      getValue,
      convertToTitleCase,
      filterByTerm,
      currentLanguage,
      getIcon,
      folderListTestId
    }
  }
}
</script>

<style>
.folder-names {
  font-size: 0.8rem;
  font-weight: 800;
  text-transform: capitalize;
}

.folders {
  background-color: var(--folder-background);
  color: var(--main-color);
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 8px;
  font-size: 1rem;
  margin: 10px;
  font-weight: 400;
  border-radius: 10px;
  border-left:2px solid var(--main-color);
  box-shadow: 0px 10px 20px -6px var(--folder-shadow);
}

.folders:hover {
  transform: scale(1.02);
  background-color: var(--toolbar-color);
  color:white;
  border-radius: 0px;
  padding-left: 40px;
  border-radius: 10px;
}

</style>
