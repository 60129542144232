<template>
  <div
    v-if="currentView === 'attendance'"
    class="totalHrsContainer"
  >
    <div
      v-for="val in totalRows"
      :key="val"
      class="totalRow"
    >
      <span>
        <span>
          {{ val }}
        </span>
      </span>
    </div>
  </div>
  <div
    v-else-if="currentView === 'allocations'"
    class="totalHrsContainer"
    style="justify-content: center;"
  >
    <div
      v-for="val in totalRows"
      :key="val"
      class="totalCell"
    >
      <span>
        <span>
          {{ val }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';

export default {
  name: 'TotalHoursCellRenderer',
  setup(props) {
    const { getTotalDays, formatHrsAndDays } = attendanceGridUtils();
    const currentView = props.params?.currentFolder?.name;

    const totalRows = computed(() => {
      let retVal;
      if (currentView !== null && currentView !== undefined) {
        if (currentView === 'attendance') {
          if (props.params['data'] !== undefined) {
            console.log('I am here', props.params.data)
            const result = { present: 0, partialAttendance: 0, punchRequest: 0, absent: 0, rest: 0, weeklyOff: 0, holidays: 0, leaves: 0, leaveWithoutPay: 0, deficitHours: 0 };
            const totalDays = getTotalDays(props.params['data']);
            result.present += totalDays.present || 0;
            result.absent += totalDays.absent || 0;
            result.rest += totalDays.rest || 0;
            result.holidays += totalDays.holidays || 0;
            result.leaves += totalDays.leaves || 0;
            result.deficitHours += totalDays.deficitHours || 0;
            result.partialAttendance += totalDays.partialAttendance || 0;
            result.punchRequest += totalDays.punchRequest || 0;
            result.weeklyOff += totalDays.weeklyOff || 0;
            result.leaveWithoutPay += totalDays.leaveWithoutPay || 0;
            retVal = formatHrsAndDays(result);
          }
        } else if (currentView === 'allocations') {
          let regularDays = 0
          for (const key in props.params.data) {
            if (key !== 'employees') {
              regularDays++;
            }
          }
          retVal = 'R:' + regularDays;
        }
      }
      return retVal;
    });

    return { currentView, totalRows };
  }
}
</script>

<style>
.ag-cell .totalHrsContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.ag-cell .totalHrsContainer .totalRow {
  height: 20px;
  font-size: 12px;
  margin: 5px;
}

.ag-cell .totalCell {
  height: 20px;
  margin: 1px;
  font-size: 12px;
}
</style>
