<template>
  <div
    v-if="!showInTable && !readOnly && showField"
    class="input-group my-auto"
  >
    <div class="form-check d-inline-flex">
      <div
        v-if="!showInTable"
        :class="{ 'd-block': isMissing }"
        class="invalid-feedback p-0 text-xs"
      >
        {{ errorText }}
      </div>
      <input
        :id="dataTestId"
        v-model="value"
        :data-test-id="dataTestId"
        :class="{ 'mandatory-field': isMandatory && isMissing}"
        class="form-check-input checkbox-inline"
        type="checkbox"
        :disabled="isReadOnly || isEditNotAllowed"
        @change="handleChange"
      />
      <!-- <v-switch
        :id="dataTestId"
        v-model="value"
        :data-test-id="dataTestId"
        :class="{ 'mandatory-field': isMandatory && isMissing}"
        hide-details
        inline
        color="#66BB6A"
        :disabled="isReadOnly || isEditNotAllowed"
        @update:model-value="handleChange"
      /> -->
      <label class="form-check-label ms-2 my-auto"><div>{{ booleanOptions?.label[currentLanguage] }}</div></label>
    </div>
  </div>
  <div
    v-else-if="showField && readOnly"
    class="input-group"
    :data-test-id="dataTestId"
  >
    <v-icon
      v-if="value!==undefined && value==true"
      fill="green"
      name="bi-check-circle"
      scale="1"
      class="mx-auto mt-3"
    />
    <v-icon
      v-else-if="value!==undefined && value==false"
      fill="red"
      name="bi-x-circle"
      scale="1"
      class="mx-auto mt-3"
    />
  </div>
  <div
    v-else-if="showField && showInTable"
    class="input-group"
  >
    <v-icon
      v-if="propertyName === 'deleted'"
      id="remove-btn"
      :data-test-id="dataTestId"
      name="bi-trash"
      scale="1.8"
      :class="{ 'disabled-icon': isReadOnly }"
      :fill="!isReadOnly?`var(--trash-can)`:`#d3d3d3`"
      hover
      @click="handleChange"
    />
    <div
      v-else
      class="form-check"
    >
      <input
        v-model="value"
        :data-test-id="dataTestId"
        class="checkbox-inline"
        type="checkbox"
        :disabled="isReadOnly || isEditNotAllowed"
        @change="handleChange"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import processExpr from '../../composables/processExprs';
import controlUtility from '../../composables/controlUtility';
export default {
  name: 'WfmBoolean',
  props: {
    params: {
      type: Object,
      default: null,
    }
  },
  emits: ['input-change', 'mandatory-check'],
  setup(props, context) {
    //variables
    const i8vue = useI18n();
    const value = ref(false);
    const showInTable = props.params?.showInTable ? true : false;
    const readOnly = props.params?.readOnly ? true : false;
    const booleanOptions = props.params.fieldOptions
    const booleanParams = toRef(props.params.data)
    const folderName = booleanOptions?.path?.split('.')[0]
    const idOfTheFolder = booleanParams.value?.[folderName]?.[0]?.id ? booleanParams.value?.[folderName]?.[0]?.id : booleanParams.value?.id
    const fieldPath = booleanOptions.path
    const propertyName = booleanOptions.name;
    value.value = booleanParams.value?.[propertyName] ? booleanParams.value?.[propertyName] : booleanParams.value?.[folderName]?.[0]?.[propertyName];
    const currentLanguage = i8vue.locale.value;

    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields
    const mandatoryFields = props.params.mandatoryFields

    const currentFolder = ref(props.params.currentFolder)
    const { processReadOnly, processVisibleExpr, processMandatoryExpr, processDefaultValue} = processExpr(currentFolder.value, booleanParams.value)
    const {mandatoryCheck} = controlUtility(null, mandatoryFields)


    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        return true
      }
      else {
        return false
      }
    })
    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        if (isMandatory.value) {
          return true
        }
        else {
          return false
        }
      }
    })

    const isReadOnly = computed(() => {
      const retVal = processReadOnly(booleanOptions.readonly_expr)
      console.log('checking trash', booleanOptions?.readonly_expr)
      return retVal
    })

    const isVisible = computed(() => {
      const retVal = processVisibleExpr(booleanOptions.visible_expr)
      return retVal
    })
    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(booleanOptions.mandatory_expr) && isVisible.value
      if (!showInTable) {
        mandatoryCheck({propertyName:dataTestId.value, value:retVal})
      }
      return retVal
    })

    const defaultValue = computed(() => {
      const retVal = processDefaultValue(booleanOptions.default_value)
      return retVal
    });

    const errorText = computed(() => {
      if (isMissing.value) {
        return 'Mandatory field'
      }
      else {
        return ''
      }
    })
    const dataTestId = computed(() => {
      if (showInTable || readOnly) {

        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value.name}/${propertyName}`

        }
        else {
          return `${booleanOptions.type}/${propertyName}`
        }
      }
    })

    //methods
    function getDefaultValue() {
      const defaultSelectedValue = defaultValue.value
      value.value = defaultSelectedValue
      handleChange()
    }
    if (defaultValue.value) {
      getDefaultValue()
    }

    function handleChange() {
      try {
        let newValue = value.value;
        newValue = propertyName === 'deleted' ? true : newValue
        //if the trash can is pressed set detlete true
        if (showInTable) {
          booleanParams.value[propertyName] = newValue
          props.params.cellValueChanged(booleanParams.value, props.params.rowNode);
        } //else emit event to control
        else {
          context.emit('input-change', {
            path: fieldPath, value: newValue,
            folderId: idOfTheFolder,
            mandatory: booleanOptions.mandatory_expr,
            isBoolean:true
          });
        }
      } catch (error) {
        throw error
      }

    }

    //hooks

    return {
      value,
      handleChange,
      showInTable,
      booleanOptions,
      readOnly,
      currentLanguage,
      propertyName,
      isMandatory,
      isReadOnly,
      isMissing,
      errorText,
      showField,
      isEditNotAllowed,
      dataTestId
    };
  },
};
</script>
<style>
.disabled-icon {
  color:green;
  pointer-events: none;
}
</style>
<style scoped>

.form-check-input {
  width: 25px;
  height: 25px;
}

.form-check-label {
  font-size: 13px;
  font-weight: 600;
  text-align: bottom;
}


.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black
}

</style>
