//this composable defines icons to use
import { ref } from 'vue';
const useIcons = () => {
  const icons = ref({
    employees: {name:'bi-person-badge'},
    allocations: {name:'bi-person-check-fill'},
    jobs: {name:'bi-person-workspace'},
    partners: {name:'fa-network-wired'},
    products: {name:'bi-postcard-fill'},
    reports: {name:'bi-clipboard2-data-fill'},
    salary_structures: {name:'fa-money-check-alt'},
    contracts: {name:'fa-file-contract'},
    persons: {name:'bi-person-circle'},
    attendance_edits: {name:'bi-file-earmark-spreadsheet'},
    loans: {name:'fa-piggy-bank'},
    agreement: {name:'bi-flag'},
    print_templates: {name:'bi-printer-fill'},
    leaves: {name:'bi-calendar-x'},
    shifts: {name:'fa-clock'},
    devices:{name:'md-devices'},
    bizdevices:{name:'md-importantdevices'},
    payroll_adjustments: {name:'fa-money-check-alt'},
    currency: {name:'bi-currency-exchange'},
    patients:{name:'fa-bed'},
    visitors:{name:'fa-hospital-user'},
    registrations:{name:'md-personadd'},
    leave_types:{name:'bi-calendar3'},
    bulk_upload:{name:'fa-file-upload'},
    sites:{name:'fa-route'},
    invoices:{name:'fa-file-invoice-dollar'},
    attendance:{name:'md-groupadd-outlined'},
    departments:{name:'ri-organization-chart'},
    payslips: {name:'fa-cash-register'},
    holidays: {name:'fa-umbrella-beach'},
    Create: {name:'bi-plus-circle-fill', fill:'#72d3fe'},
    Apply: {name:'bi-plus-circle-fill', fill:'#72d3fe'},
    Delete: {name:'fa-trash', fill:'#f97c7c'},
    Terminate:{name:'bi-x-circle-fill', fill:'#f97c7c'},
    Reject: {name:'fa-ban', fill:'#f97c7c'},
    Approve: {  name:'bi-check-circle-fill', fill:'#85e085'},
    Update: { name:'fa-regular-edit', fill:'#72d3fe'},
    Copy: { name:'fa-copy', fill:'#85e085'},
    Export: { name:'bi-filetype-xlsx', fill:'#f97c7c'},
    Download:{name:'bi-download', fill:'#72d3fe'},
    Send:{name:'ri-mail-send-line', fill:'#85e085'},
    Generate:{name:'fa-receipt', fill:'#72d3fe'},
    profile:{name:'bi-person-badge'},
    workflow:{name:'ri-organization-chart'},
    groups:{name:'md-groups'}
  });

  const getIcon = (key) => {
    return icons.value[key] || '';
  };

  return {getIcon};
}
export default useIcons;
