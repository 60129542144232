<template>
  <div
    ref="test2"
    class="sidebar"
  >
    <div class="sidebar-menu">
      <WfmSidebarTabs
        :tabs-state="sidebarTabsState"
        @change-tab="changeTab"
      />
      <div class="m-3">
        <WfmGlobalSearch
          v-if="sidebarTabsState.folders || sidebarTabsState.reports"
          search-input-class="sidebar-global-search"
          :placeholder="searchBoxPlaceHolder"
          :data-test-id="sidebarSearchTestId"
          @handle-search="handleSearch"
        />
      </div>
      <div class="sidebar-body mt-2">
        <div v-if="sidebarTabsState.actions">
          <ActionsTab
            @hide-sidebar="hideSidebar"
          />
        </div>

        <div v-if="sidebarTabsState.folders">
          <FolderListTab
            :search-term="searchTerm"
            @hide-sidebar="hideSidebar"
          />
          <!-- folder List -->
        </div>
        <div v-if="sidebarTabsState.reports">
          <!-- reports List -->
          <ReportsTab
            :search-term="searchTerm"
            @hide-sidebar="hideSidebar"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref} from 'vue'
import WfmGlobalSearch from './../../wfm-global-search.vue'
import WfmSidebarTabs from './wfm-sidebarTabs.vue'
import ActionsTab from './actions-tab.vue'
import FolderListTab from './folderList-tab.vue'
import ReportsTab from './reports-tab.vue'
// import _ from "lodash"
export default {
  name: 'WfmSidebar',

  components: {
    WfmGlobalSearch,
    WfmSidebarTabs,
    ActionsTab,
    FolderListTab,
    ReportsTab
  },
  emits: ['hide-sidebar'],
  setup(props, context) {
    //store
    const searchTerm = ref('')
    const searchBoxPlaceHolder = computed(() => {
      if (sidebarTabsState.value.folders) {
        return 'Search Folders..'
      }
      else {
        return 'Search Reports..'
      }
    })
    const sidebarSearchTestId = computed(() => {
      if (sidebarTabsState.value.folders) {
        return 'folders/sidebar/search-bar'
      }
      else {
        return 'reports/sidebar/search-bar'
      }
    })
    //Refs

    //initialize a ref with folder tab active
    const sidebarTabsState = ref({'actions':false, 'folders':true, 'reports':false})

    function handleSearch(searchParams) {
      searchTerm.value = searchParams
    }

    function hideSidebar() {
      context.emit('hide-sidebar')
    }
    function changeTab(tabName) {
      for (var key in sidebarTabsState.value) {
        if (key === tabName) {
          sidebarTabsState.value[key] = true
        }
        else {
          sidebarTabsState.value[key] = false
        }
      }

    }

    return {
      sidebarTabsState,
      searchBoxPlaceHolder,
      handleSearch,
      hideSidebar,
      searchTerm,
      changeTab,
      sidebarSearchTestId
    }
  }
}
</script>

<style>
.sidebar {
  width: 350px;
  cursor: pointer;
  box-shadow: 2px 2px 7px grey;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  overflow: hidden;
  position: absolute;
  background-color: var(--nav-header-color);
  z-index: 5;
  display: flex;
  flex-direction: column;
  height: 95vh;
}

@media screen and (max-width: 570px) {
  .sidebar {
    width: 200px;
    height: 100vh;
  }
}


.sidebar-body {
  height: 74vh;
  text-align: left;
  overflow-y: scroll;
}

.sidebar-body::-webkit-scrollbar {
  display: none;
}


.sidebar.enter {
  opacity: 1;
  left: 0;
}

.sidebar-header {
  border-radius: 12px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;

}

.sidebar-menu {
  list-style: none;
  border-radius: 2px;
}

.sidebar-global-search {
  min-width: 20px !important;
  color: var(--main-color) !important;
  background: var(--search-box-background) !important;
  border: black
  /* width: 100%;
  color: var(--main-color);
  height: 40px !important; */
}

</style>
