<template>
  <ag-grid-vue
    style="width: 100%; height: 100%;"
    :class="gridClassName"
    :column-defs="columnDefs"
    :row-data="rowData"
    :unique-key="uniqueKey"
    row-selection="multiple"
    animate-rows="true"
    :pagination="pagination"
    :suppress-pagination-panel="suppressPaginationPanel"
    :loading-overlay-component="loadingOverlayComponent"
    :overlay-no-rows-template="noRowsTemplate"
    :row-height="uniqueKey ? null : 40"
    :pagination-page-size="paginationPageSize"
    :row-model-type="rowModelType"
    :dom-layout="domLayout"
    auto-size-columns="true"
    :header-height="headerHeight"
    :on-cell-key-down="onCellKeyDown"
    @grid-ready="onGridReady"
    @row-double-clicked="rowDoubleClicked"
  />
</template>

<script>
import 'ag-grid-community/styles//ag-grid.css'
import 'ag-grid-community/styles//ag-theme-alpine.css'
import WfmLoader from '../common/loader/wfm-loader.vue'
import { AgGridVue } from 'ag-grid-vue3'
import { useStore } from 'vuex'
import { reactive, ref} from 'vue'

export default {
  name: 'AgGrid',
  components: {
    AgGridVue,
  },
  props: {
    rowData: {
      type: Array,
      default: null,
    },
    columnDefs: {
      type: Array,
      default: null,
    },
    uniqueKey: {
      type: String,
      default: null,
    },
    rowDoubleClicked: {
      type: Function,
      default: null,
    },
    onChangedSomething: {
      type: Function,
      default: null,
    },
    rowModelType: {
      type: String,
      default: null
    },
    domLayout: {
      type: String,
      default: null
    },
    paginationPageSize: {
      type: Number,
      default: null
    },
    pagination:{
      type:String,
      default:null
    },
    suppressPaginationPanel:{
      type:String,
      default:null
    },
    headerHeight:{
      type:String,
      default:null
    },
    gridClassName:{
      type:String,
      default:null
    }
  },
  emits: ['get-grid-data', 'get-detail-grid-api'],
  setup(props, context) {
    //variables
    const store = useStore();
    let gridApi = reactive({});
    const loadingOverlayComponent = WfmLoader;
    const noRowsTemplate = ref('<div><span role="status" style="width: 3rem; height: 3rem; color: var(--main-font-color)">No Data Found</span></div>');
    const agGridTableName = props.uniqueKey ? props.uniqueKey : '';
    const currentTableApi = ref(null)
    // const maxBlocksInCache = ref(0)
    // const cacheBlockSize = ref(0)
    // const maxConcurrentDatasourceRequests = ref(-1)

    function onGridReady(params) {
      currentTableApi.value = params.api;
      const headerCells = document.querySelectorAll('.ag-header-cell');
      headerCells.forEach((cell) => {
        cell.removeAttribute('tabIndex');
      });
      if (props.uniqueKey) {
        gridApi = {
          tableName: agGridTableName,
          gridApi: currentTableApi.value,
        };
        store.dispatch('agGridModule/detailGridApiSetter', gridApi);
      } else {
        //grid api for list tables
        context.emit('get-grid-data', currentTableApi.value)
      }
    }

    //copy text from grid by pressing C
    const onCellKeyDown = async(event) => {
      console.log('keyPressed', event.event.code)
      if (event.event.ctrlKey === true && event.event.code === 'KeyC') {
        var cellValue = event?.event?.target?.textContent;
        navigator?.clipboard?.writeText(cellValue);
      }
      if (event.event.returnValue === true && event.event.keyCode === 13 && props.uniqueKey == null) {
        props.rowDoubleClicked(event)
      }
      console.log(event.event)
    };

    return {
      onGridReady,
      noRowsTemplate,
      loadingOverlayComponent,
      onCellKeyDown,
    };
  },
};
</script>

<style>
::ng-deep .ag-root-wrapper {
  overflow: visible;
}

.ag-root-wrapper {
  overflow: visible !important;
}
/* Hide scrollbars in most browsers */
.ag-root-wrapper {
  overflow: hidden !important;
}

/* For Webkit-based browsers like Chrome and Safari */
.ag-root-wrapper::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.ag-root-wrapper {
  scrollbar-width: none; /* Firefox 64 and later */
}

/* Remove the grid's outer border */
.ag-theme-alpine .ag-root-wrapper {
    border: none;
}
.ag-theme-material .ag-root-wrapper {
    border: 1px solid var(--active-tab);
    border-radius: 2px;
}
.ag-container-header{
  margin:20px;
}
.ag-theme-alpine {
  --ag-header-height: 45px;
  --ag-foreground-color: var(--main-font-color);
  --ag-background-color: var(--main-background-color);
  --ag-header-background-color: var(--active-tab);
  --ag-header-foreground-color: #292929;
  --ag-odd-row-background-color: rgb(0, 0, 0, 0);
  --ag-overlay-background-color: var(--main-background-color) !important;
  --ag-font-family: 'Poppins', sans-serif;


}

.ag-theme-material {
  --ag-header-height: 45px;
  --ag-foreground-color: var(--main-font-color);
  --ag-background-color: var(--main-background-color);
  --ag-header-background-color: var(--field-group-header);
  --ag-header-foreground-color: #292929;
  --ag-odd-row-background-color: rgb(0, 0, 0, 0);
  --ag-overlay-background-color: var(--main-background-color) !important;
  --ag-font-family: 'Poppins', sans-serif;
}

.ag-theme-material {
    --ag-material-active-color: #2196f3;
    --ag-selected-row-background-color: rgba(33, 150, 243, 0.3);
    --ag-row-hover-color: rgba(33, 150, 243, 0.1);
    --ag-column-hover-color: rgba(33, 150, 243, 0.1);
    --ag-input-focus-border-color: rgba(33, 150, 243, 0.4);
    --ag-range-selection-background-color: rgba(33, 150, 243, 0.2);
    --ag-range-selection-background-color-2: rgba(33, 150, 243, 0.36);
    --ag-range-selection-background-color-3: rgba(33, 150, 243, 0.49);
    --ag-range-selection-background-color-4: rgba(33, 150, 243, 0.59);

}

.ag-theme-material .ag-overlay-loading-wrapper{
    background-color: var(--main-background-color) !important;
}

.ag-theme-alpine .ag-overlay-loading-wrapper {
  background-color: var(--main-background-color) !important;
}

.ag-theme-alpine .ag-header-cell {
    font-weight: normal;
    font-size: 1rem;
    border-right: 1px solid ag-param(secondary-border-color);
    align-items: left;
}

.ag-theme-material .ag-header-cell {
    font-weight: normal;
    font-size: 1rem;
    border-right: 1px solid ag-param(secondary-border-color);
    align-items: left;
}
.ag-theme-alpine .ag-cell {
  border-right: 0.1px solid #dde2eb !important;
}
.ag-theme-material .ag-cell {
  border-right: 0.1px solid #dde2eb !important;
}

.ag-row {
  z-index: 0;

  &.ag-row-focus {
    z-index: 1;
  }
}

.actions-button-cell {
  overflow: visible;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

.ag-cell-value,
.ag-cell {
  overflow: visible;
}

.actions-button-cell {
  overflow: visible;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

</style>
