import allocationGridUtils from './allocationGridUtils';
import attendanceGridUtils from './attendanceGridUtils'
import getFatRow from './fatRowGenerator';
import postData from './postData';
import getProjections from './getProjections';

const dataProcessor = () => {

  async function getAllocationRows(monthYear, currentFolder, filterValues, employeeFilter) {
    const { createTimeRange } = allocationGridUtils();
    const dateRange = createTimeRange(monthYear)
    const { projectionForFolder } = getProjections();
    const projections = projectionForFolder('allocations');

    const txnParams = {
      refRows: true,
      expand:[{
        path: 'allocations.allocations_period.allocations_range',
        interval: '1 day'
      }],
      page: {
        limitBag: {
          path: 'allocations.employees_id.id',
          limit: 100,
          offset:0,
          orderBy: 'code',
        },
        getRowCountBag: {
          path: 'allocations.employees_id.id',
          value: true,
        },
        orderByBag: {
          path: 'allocations.employees_id.code',
          value: 'desc'
        }
      },
      filters: {
        filterInput: [
          {
            path: 'allocations.allocations_period.allocations_range',
            operator: 'between',
            value: [dateRange[0], dateRange[1]],
          },
        ],
        joinop: 'and',
      },
      projections: projections,
    };
    txnParams['refRows'] = true;

    if (filterValues.contract !== null) {
      txnParams.filters.filterInput.push({
        operator: 'in',
        path: 'allocations.contracts_id.id',
        value: [filterValues.contract.id],
      });
    }

    if (filterValues.site !== null) {
      txnParams.filters.filterInput.push({
        operator: 'in',
        path: 'allocations.sites_id.id',
        value: [filterValues.site.id],
      });
    }

    if (filterValues.designation !== null) {
      txnParams.filters.filterInput.push({
        operator: 'in',
        path: 'allocations.products_id.id',
        value: [filterValues.designation.id],
      });
    }

    if (filterValues.department !== null) {
      txnParams.filters.filterInput.push({
        operator: 'in',
        path: 'allocations.employees_id.departments.id',
        value: [filterValues.department.id],
      });
    }

    if (filterValues.shift !== null) {
      txnParams.filters.filterInput.push({
        operator: 'in',
        path: 'allocations.allocations_period.shifts_id.id',
        value: [filterValues.shift.id],
      });
    }
    if (filterValues.reportingManger !== null) {
      txnParams.filters.filterInput.push({
        operator: 'in',
        path: 'allocations.employees_id.reporting_manager.id',
        value: [filterValues.reportingManger.id],
      });
    }

    if (Object.keys(employeeFilter).length !== 0) {
      const filterInput = [];
      filterInput.push({
        operator: 'ilike',
        path: 'allocations.employees_id.persons_id.first_name',
        value: '%' + employeeFilter.employees.filter + '%',
      });
      filterInput.push({
        operator: 'ilike',
        path: 'allocations.employees_id.code',
        value: '%' + employeeFilter.employees.filter + '%',
      });
      txnParams.filters.filterInput.push({filterInput, joinop: 'or'});
    }

    try {
      const { listData } = postData();
      const list = await listData(currentFolder, txnParams);
      return list;
    } catch (e) {
      console.log('Error', e);
    }
  }

  function rowDataCreator(data, sortModel) {
    console.time('test1');
    const { fatRowGenerator } = getFatRow();
    const finalData = fatRowGenerator(data);
    const alcData = finalData.reduce((alc, dataForm) => {
      if (dataForm.deleted === false && dataForm.allocations_period !== undefined && dataForm.allocations_period !== null) {
        const employeeId = dataForm?.employees_id?.[0]?.id;
        const existingData = alc.find((item) => item?.employees?.[0].id === employeeId);
        if (existingData) {
          for (let i = 0; i < dataForm.allocations_period.length; i++) {
            const field = getFormattedDateField(dataForm.allocations_period[i].allocations_range);
            existingData[field] = dataGenerator(i, dataForm)[field];
          }
        } else {
          let newData = {};
          for (let i = 0; i < dataForm.allocations_period.length; i++) {
            const keys = Object.keys(newData);
            if (keys.length === 0) {
              newData = dataGenerator(i, dataForm);
              newData['employees'] = dataForm.employees_id;
            } else {
              const field = getFormattedDateField(dataForm.allocations_period[i].allocations_range);
              newData[field] = dataGenerator(i, dataForm)[field];
            }
          }
          alc.push(newData);
        }
      }
      return alc;
    }, []);
    console.log('ATTDATA', alcData);
    let sortedRows = alcData;
    const { quickSort } = attendanceGridUtils();
    if (alcData.length > 0) {
      sortedRows = quickSort(alcData, 'code');
    }
    console.timeEnd('test1');
    if (sortModel.length !== 0 && sortModel[0].sort !== null) {
      if (sortModel[0].sort === 'desc') {
        sortedRows.reverse();
      }
    }
    return sortedRows;
  }

  function dataGenerator(i, dataForm) {
    const field = getFormattedDateField(dataForm.allocations_period[i].allocations_range);
    return {
      [field]: {
        id: dataForm.id,
        allocations_range: dataForm.allocations_period[i].allocations_range,
        sites_id: dataForm.sites_id,
        shifts_id: dataForm.allocations_period[i].shifts_id,
        allocations_id: dataForm.allocations_period[i].shifts_id,
        allocation_type: dataForm.allocations_period[i].allocation_type,
        departments_id: dataForm.allocations_period[i].departments_id,
        contracts_id: dataForm.contracts_id,
        name: dataForm.name,
        products_id: dataForm.products_id,
      }
    };
  }

  function getFormattedDateField(date) {
    const formattedDate = new Date(date);
    formattedDate.setHours(formattedDate.getHours() + 5);
    formattedDate.setMinutes(formattedDate.getMinutes() + 30);
    const day = formattedDate.getDate();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[formattedDate.getMonth()];
    const year = formattedDate.getFullYear();
    return `${day}${month}${year}`;
  }

  return { getAllocationRows, rowDataCreator };
};

export default dataProcessor;
