<template>
  <div style="width: 200px; height: 100px;">
    <v-select
      ref="multiselect"
      v-model="selected"
      :reduce="option => option"
      :placeholder="placeHolder"
      :options="finalDocpToShow"
      clear-search-on-select
      @input="searchDocp"
    >
      <template #no-options="{ search, searching }">
        <template v-if="searching && loading">
          searching for <em>{{ search }}</em>.
        </template>
        <template v-else-if="true && loading">
          Type to Search..
        </template>
        <template v-else>
          No records found
        </template>
      </template>
      <template #spinner>
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm text-info"
          role="status"
          aria-hidden="true"
        />
      </template>
      <template #option="option">
        <span :class="{'selected_option' : markSelectedOption(option)}">
          <div class="d-flex justify-space-between">
            <div name="label">{{ option.label }}</div>
          </div>
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick, computed } from 'vue';
import { useStore } from 'vuex';
import makeFilter from '../../../composables/makeFilter';
import postData from '../../../composables/postData';
import getFatRow from '../../../composables/fatRowGenerator.js';
import getFolders from '../../../composables/getFolders';

export default {
  name: 'EmployeeSelect',
  setup(props) {
    //variables
    const store = useStore();
    const selected = ref();
    const options = ref([]);
    const value = ref(null);
    const searchTerm = ref('');
    let filterParams = reactive([]);
    const employeesList = ref([]);
    const loading = ref(false)
    const bSettings = ref(store.getters['bSettings'])
    const { getAllFoldersList, getCurrentFolder } = getFolders();
    const currentViewFolder = props?.params?.currentFolder;
    let form = null;
    if (currentViewFolder !== null && currentViewFolder !== undefined && currentViewFolder.forms !== null && currentViewFolder.forms !== undefined) {
      form = currentViewFolder.forms?.attendance_create_form;
    }

    //computed
    const currentFolder = computed(() => {
      //getting attendance_edits folder to set as currentFolder
      const folderList = getAllFoldersList(bSettings.value);
      const attendanceEditsFolder = getCurrentFolder('attendance_edits', folderList);
      return attendanceEditsFolder
    })

    const finalDocpToShow = computed(() => {
      return options.value.map((each) => {
        if (each.label == null) {
          if (true) {
            each.label = `${each.first_name || each?.name?.eng}  (${each.code})`
          }
        }
        return each
      })
    })

    function markSelectedOption(option) {
      if (value.value === option.label) {
        return true
      }
    }

    const docpOption = currentFolder.value.fields.employees_id;
    const fields = ref({});
    fields.value = currentFolder.value.fields;

    const defaultValue = computed(() => {
      if (props.params['value'] !== undefined) {
        return props?.params?.value[0]?.persons_id[0]?.first_name?.eng;
      } else {
        return null;
      }
    })
    selected.value = defaultValue.value;
    const placeHolder = computed(() => {
      if (defaultValue.value !== null) {
        return defaultValue.value;
      } else {
        return 'search employees';
      }
    })

    //lifeCycle hooks
    onMounted(async() => {
      await nextTick();
      const multiselect = document.getElementsByClassName('vs__search');
      multiselect[multiselect.length - 1].focus();
    })

    //functions
    async function getDocpickerList() {
      const folderList = getAllFoldersList(bSettings.value);
      const currentDocpFolder = ref([]);
      let folderName = null;
      if (!docpOption.source?.folder) {
        folderName = docpOption.name;
      } else {
        folderName = docpOption.source.folder;
      }
      currentDocpFolder.value = getCurrentFolder(folderName, folderList);
      const { listData } = postData();
      options.value = []
      let txnParams = reactive({ refRows: true });
      try {
        // placeHolder.value = `search for ${currentDocpFolder?.label?.eng}`;
        if (Object.keys(filterParams).length !== 0) {
          txnParams.filters = filterParams;
          txnParams.projections = {
            'employees': ['id', 'deleted', 'code', 'persons_id', 'designation', 'departments'],
            'employees.persons_id':['id', 'first_name', 'last_name', 'middle_name']
          }
          const list = await listData(currentDocpFolder.value, txnParams);
          if (list.output.type === 'error') {
            console.log(list.output.message);
          } else {
            if (list.output?.data?.records?.length > 0) {
              const { fatRowGenerator } = getFatRow();
              employeesList.value = fatRowGenerator(list);
              options.value = [];
              options.value = employeesList.value.map((item) => ({ first_name: item['persons_id'][0]['first_name']['eng'], code: item.code }));
            }
          }
          loading.value = false;
        }
        filterParams = []
        txnParams = {refRows:true}
      }
      catch (err) {
        console.log(err)
      }
    }

    async function searchDocp(event) {
      try {
        searchTerm.value = event.target.value;
        //TODO:- make filter composable will get a list of fields to filter on if any decide on object or array
        const filterFields = [{key:'code'},
          {key:'first_name'},
          {key:'last_name'},
          {key:'middle_name'},
          {key:'name'}
        ];
        let isEmployeeFilter = false;
        if (form !== null && form !== undefined) {
          for (let i = 0; i < form.length; i++) {
            if (form[i].name === 'employee' && form[i].visible_expr) {
              isEmployeeFilter = true;
              filterFields.push({key:'employee_status', value:'approved', operator:'=', joinop:'and'})
            }
          }
        }
        if (!isEmployeeFilter) {
          filterFields.push({key:'employee_status', value:'approved', operator:'=', joinop:'and'})
          filterFields.push({key:'employee_type', value:'user', operator:'!=', joinop:'and'})
          filterFields.push({key:'employee_type', value:'regular', operator:'!=', joinop:'and'})
        }
        const folderList = getAllFoldersList(bSettings.value);
        const employeesFolder = getCurrentFolder('employees', folderList);
        const { generateFilterParams } = makeFilter(
          searchTerm.value,
          employeesFolder,
          filterFields
        );
        if (searchTerm.value.length >= 2) {
          filterParams = generateFilterParams();
          if (docpOption.name !== 'employees') {
            if (Object.keys(filterParams).length !== 0) {
              loading.value = true;
              getDocpickerList();
            }
          } else {
            loading.value = true;
            getDocpickerList();
          }
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    function handleInputChange(newValue, basePath) {
      console.log(newValue, basePath);
    }

    const isPopup = () => {
      return true;
    }

    const getValue = () => {
      if (selected.value !== undefined && selected.value !== null) {
        const desiredCode = selected.value.code;
        const foundObject = employeesList.value.find((item) => item.code === desiredCode);
        if (foundObject) {
          value.value = [foundObject];
          return value.value;
        } else {
          console.log('Object not found.');
        }
      }
      return props.params.value;
    }

    const isCancelBeforeStart = () => {
      return false;
    };

    const isCancelAfterEnd = () => {
      return false;
    };

    return {
      selected,
      finalDocpToShow,
      markSelectedOption,
      options,
      isPopup,
      placeHolder,
      searchDocp,
      loading,
      getValue,
      isCancelBeforeStart,
      isCancelAfterEnd,
      handleInputChange,
    }
  }
}
</script>
<style>
@import "vue-select/dist/vue-select.css";
.selected_option {
  font-weight: 700;
  color:green;
}
.selected_option :hover {
  color:white;
}
</style>
