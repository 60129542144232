const permissionCheck = () => {
  //checks invalid fields and stores
  function checkEnvPermission(pString) {
    try {
      const retVal = eval(pString)
      return retVal
    } catch (error) {
      console.log(error)

    }

  }

  function evalPermission(expr, env) {
    try {
      if (expr !== null && expr != '') {
        const fnBody = `try { ${expr} } catch(err) { throw new Error('error in expr evaluation ' + err.message) }`;
        const func = new Function('env', fnBody);
        return func(env);
      }
      else {
        //if no expr given default to true
        return true
      }

    } catch (err) {
    // Handle error
      return err;
    }
  }
  return { checkEnvPermission, evalPermission}
}

export default permissionCheck
