<template>
  <div class="ag-container">
    <AgGrid
      :column-defs="columnSpecs"
      :row-double-clicked="handleRowClick"
      :row-data="rowData"
      row-model-type="clientSide"
      pagination="true"
      :pagination-page-size="limit"
      suppress-pagination-panel="true"
      grid-class-name="ag-theme-alpine"
      @get-grid-data="getGridApi"
    />
    <WfmPagination
      class="pagination"
      :records-showing="recordsShowing"
      :total-rows-count="totalRowsCount"
      :data-test-id="listPaginationDataTestId"
      @get-page="getThePage"
    />
  </div>
</template>

<script>
import { ref, reactive, computed, onBeforeMount, provide, watch} from 'vue'
import {keys} from '../provideKeys.js'
import { useStore } from 'vuex'
import AgGrid from '../components/AgGrid.vue';
import WfmPagination from '../common/wfm-pagination.vue'
import getUtility from '../composables/getUtility'
import postData from '../composables/postData'
import getFieldOptions from '../composables/getFieldOptions'
import getFormInfo from '../composables/getFormInfo'
import gridConfig from '../composables/getGridData'
import getFatRow from '../composables/fatRowGenerator'
import makeFilter from '../composables/makeFilter'
import getProjections from '../composables/getProjections'
export default {
  name: 'WfmListItems',
  components: {
    AgGrid,
    WfmPagination
  },
  props: {
    listFolder : {
      type: Object,
      default: null
    },
    searchTerm : {
      type : String,
      default : null
    },
    searchPath:{
      type:String,
      default:null
    },
    rowDoubleClicked: {
      type: Function,
      default: () => {}
    },
    pagination : {
      type: Boolean,
      default : true
    },
    suppressPaginationPanel : {
      type : Boolean,
      default : true
    },
    currentTask:{
      type:String,
      default:null
    },
    fieldsToFilter:{
      type:Object,
      default:null
    }
  },
  emits:['send-grid-api'],
  setup(props, context) {
    const store = useStore()
    const { debounce, getNullObject } = getUtility()
    const {projectionForFolder} = getProjections()
    const limit = ref(50)
    const offsetValue = ref(0)
    const bSettings = store.getters['bSettings'];
    const recordsShowing = ref(0)
    const totalRowsCount = ref(0)
    const { fieldsData } = getFieldOptions()
    const { getFormObject, getCurrentTxn } = getFormInfo()
    const { columnDefs } = gridConfig()


    // transform props as required
    // const folder = ref(folderObjectFromName(props.folder))
    const searchTerm = props.searchTerm
    const searchPath = props.searchPath

    // watch for changes in the props.searchTerm
    watch(() => [props.searchTerm, props.fieldsToFilter], ([newSearchTerm, newFieldsToFilter]) => {
      try {
        handleSearchDebounced(newSearchTerm, null, newFieldsToFilter);
      } catch (error) {
        console.log('er', error)
      }
    },   { deep: true });
    const listCurrentFolder = ref(props.listFolder)
    const listPaginationDataTestId = computed(() => {
      return `${listCurrentFolder.value.name}/list/pagination`

    })


    const handleRowClick = props.rowDoubleClicked || handleRowClickFn
    // Refs
    const txnParams = reactive({})
    const list = ref()
    const columnSpecs = ref()
    const rowData = ref(null)

    const gridApi = ref(null)

    const listFormObject = computed(() => {
      //get the current txn object depending on the txntype
      //get the form object that is needed
      const currentTxn = getCurrentTxn('list', props.listFolder)
      const formName = currentTxn?.form?.form_name ? currentTxn.form?.form_name : currentTxn?.form
      return getFormObject(formName, props.listFolder)
    })

    const fields = computed(() => {
      return props.listFolder.fields
    })
    const columnsToShowField = computed(() => {
      const cloneOfFolder = JSON.parse(JSON.stringify(props.listFolder));
      let retVal = []
      retVal = listFormObject.value?.map((each) => {
        return fieldsData(each.path, cloneOfFolder, bSettings.output.data.records[0], each)
      })
      return retVal
    })

    const folderFields = computed(() => {
      if (props.currentTask === 'create') {
        const cloneOfFolderFields = JSON.parse(JSON.stringify(listCurrentFolder.value.fields));
        const emptyFolderFields = getNullObject(cloneOfFolderFields)
        return emptyFolderFields
      }
      else if (props.currentTask === 'update') {
        return rowData.value
      }
      else {
        return listCurrentFolder.value.fields
      }
    })
    //provide folderFields for list items (agGrid list)
    provide(keys.folderFields, folderFields)
    // begin data retrieval and search
    const handleSearchDebounced = debounce(async(searchTerm, searchPath, fieldsToFilter) => {
      await handleSearch(searchTerm, searchPath, fieldsToFilter);
    }, 1000);

    async function handleSearch(searchTerm, searchPath, fieldsToFilter) {
      try {
      // touchListKey()
      //updating the searchTerm to pass to wfmlistItems
      //get the filterParams
        const { generateFilterParams } = makeFilter(
          searchTerm,
          props.listFolder,
          fieldsToFilter, //specific fields to search on ,if any.(takes an array like [code,designation , etc])
          searchPath // if we have searchPath then its possible to generate specific filter params with the path
        )
        const filterParams = generateFilterParams()
        if (searchTerm && searchTerm.length >= 2 || fieldsToFilter?.length > 0) {
          txnParams.filters = filterParams
          await getData();
        // touchListKey()
        } else {
          txnParams.filters = null
          await getData();
        // touchListKey()
        }
      }
      catch (err) {
        console.log(err)
      }

    }
    //set the grid api and send it further up for use
    function getGridApi(api) {
      gridApi.value = api
      context.emit('send-grid-api', api)
    }

    async function getThePage(paginationParams) {
      limit.value = paginationParams.limit
      offsetValue.value = paginationParams.offset
      gridApi.value.showLoadingOverlay();
      await getData()
    }

    const getData  = async() => {
      console.log('wfm-list-items getData, folder: ',  props.listFolder)
      let orderBy = null
      if (['payslips', 'reports'].includes(props.listFolder.name)) {
        //in case of payslips and reports orderby last_modified , rest of the folder order as it is
        orderBy = {
          path: `${props.listFolder?.name}.last_modified`,
          value: 'desc'
        }
      }
      txnParams.refRows = true
      txnParams.page = {
        limitBag: {
          path: `${props.listFolder?.name}.id`,
          limit: limit?.value,
          offset: txnParams.filters === null || offsetValue.value !== 0 ? offsetValue.value : 0
        },
        getRowCountBag: {
          path: `${props.listFolder?.name}.id`,
          value: true
        },
        orderByBag: orderBy
      }
      txnParams.projections = projectionForFolder(props.listFolder.name)
      //txn params send
      const { listData } = postData()
      list.value = await listData(props.listFolder, txnParams)
      console.log('list-txn', list.value)
      if (list.value.output.type === 'error') {
        rowData.value = [];
      } else {
        console.log('tc', columnsToShowField.value)
        const fieldsToSend = columnsToShowField.value?.length > 0 ? columnsToShowField.value : fields.value
        const listTableName = 'list'
        columnSpecs.value = columnDefs(fieldsToSend, listTableName, listCurrentFolder.value, props.currentTask);

        if (Object.keys(list.value.output.data.records).length !== 0) {
          const records = list.value.output.data.records
          const idx = records[records.length - 1];
          const dataEnd = idx.dataEnd - 1
          //number of records being shown is dataEnd
          recordsShowing.value = dataEnd
          if (idx.rowCount) {
            //total records present is rowCount
            totalRowsCount.value = idx.rowCount
          }
          else {
            totalRowsCount.value = 0
          }
          const { fatRowGenerator } = getFatRow()
          const listData = fatRowGenerator(list.value)
          const finalList = listData.filter((each) => {
            if (each['deleted'] !== undefined) {
              if (each.deleted === false) {
                return each
              }
            } else {
              return each;
            }
          })
          // order the list being displayed by last_modified first
          // rowData.value = finalList.slice().sort((a, b) => new Date(b.last_modified) - new Date(a.last_modified));
          rowData.value = finalList
          if (props.listFolder.name === 'reports') {
            rowData.value?.sort((a, b) => a.label.localeCompare(b.label));
          }
          gridApi.value?.setRowData(rowData.value)
        }
        else {
          rowData.value = []
        }
      }
      // the list control needs to refresh after data is recevied
      // touchListKey()
    }

    // end data retrieval and search

    function handleRowClickFn(params) {
      console.log('wfm-list-items handleRowClick: ', params)
    }
    onBeforeMount(() => {
      if (searchTerm != '') {
        // touchListKey()
        handleSearchDebounced(searchTerm, searchPath)
      }
      else {
        getData()
      }
    })

    return {
      columnSpecs,
      getGridApi,
      handleRowClick,
      rowData,
      getThePage,
      recordsShowing,
      totalRowsCount,
      limit,
      listPaginationDataTestId
    };
  }
};
</script>

<style scoped>
.ag-container {
    /* position: absolute; */
    /* height: 78vh; */
    height: 78vh;
    z-index: 0;
    width: 100;
}
.pagination{
    width:100%;
  }
</style>
