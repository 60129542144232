<template>
  <div class="custom-header-group ag-header-group-cell-label ag-sticky-label">
    <span>{{ label }}</span>
    <WfmDateSwitcher
      :is-week-picker="isWeekPicker"
      @update-month-year="updateMonthYear"
    />
    <div
      class="actions"
    >
      <label v-if="gridView === 'attendance'">
        <input
          type="checkbox"
          @change="showInOut($event)"
        >
        <span
          style="font-size: 12px"
        >Show in/out time</span>
      </label>
      <div class="action-container">
        <div
          class="action-button"
        >
          <button @click="removeEmptyRows">
            Remove row
          </button>
        </div>
        <div
          class="action-button"
        >
          <button @click="clearSelections">
            Deselect all
          </button>
        </div>
        <div
          v-if="isApprovalManager && gridView === 'allocations'"
          class="action-button"
        >
          <button @click="deleteAllocations">
            Delete Cell
          </button>
        </div>
        <div
          v-if="isApprovalManager && gridView === 'attendance'"
          class="action-button"
        >
          <button @click="approveAttendanceCells">
            Approve
          </button>
        </div>
        <div
          v-if="isApprovalManager && gridView === 'attendance'"
          class="action-button"
        >
          <button @click="rejectAttendanceCells">
            Reject
          </button>
        </div>
        <div
          v-if="!isAdmin"
          class="action-save"
        >
          <button @click="saveChanges">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showFilter">
    <FilterComponent
      :current-folder="currentViewFolder"
      :filters-data="filtersData"
      :on-grid-ready="onGridReady"
      :update-filter-values="updateFilterValues"
      :clear-selection="clearSelection"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import WfmDateSwitcher from '../../../common/wfm-dateSwitcher.vue';
import FilterComponent from './FilterComponent.vue';
import { useStore } from 'vuex';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';
import allocationGridUtils from '../../../composables/allocationGridUtils';

export default {
  components: { WfmDateSwitcher, FilterComponent },
  props: {
    currentFolder: {
      type: Object,
      default: () => {}
    },
    isDutyChart: {
      type: Boolean,
      default: () => false
    },
    filtersData: {
      type: Object,
      default: () => {}
    },
    selectedCells: {
      type: Object,
      default: () => {}
    },
    onGridReady: {
      type: Function,
      default: () => {}
    },
    showInOutToggle: {
      type: Function,
      default: () => {}
    },
    updateFilterValues: {
      type: Function,
      default: () => {}
    },
    clearSelection: {
      type: Function,
      default: () => {}
    },
  },
  emits: ['update-month-year'],
  setup(props, context) {
    const store = useStore();
    const bSettings = store.getters['bSettings'];
    const gridApi = computed(() => {
      return store.getters['agGridModule/attendanceGridApi']
    })
    const label = ref(props?.currentFolder?.label?.eng)
    const isWeekPicker = ref(props.isDutyChart);
    const clearSelections = ref(props.clearSelection);
    const currentViewFolder = ref(props.currentFolder)
    const gridView = ref(currentViewFolder.value.name);
    const isApprovalManager = ref(false);
    const isReportingManager = ref(true);
    const isAdmin = ref(false);
    const showFilter = ref(true);
    const currentEmpCode = bSettings?.env?.code;
    if (bSettings.env.code === 'admin') {
      isApprovalManager.value = true;
    } else if (bSettings.env.isAO) {
      isApprovalManager.value = true;
    } else if (bSettings.env.isRO) {
      isApprovalManager.value = true;
    }
    if (currentEmpCode === 'admin') {
      isApprovalManager.value = true;
    }

    const { save, approveAttendance, rejectAttendance } = attendanceGridUtils();
    const { saveAllocation, deleteAllocation } = allocationGridUtils();

    async function saveChanges() {
      if (gridView.value === 'attendance') {
        await save(store);
      } else {
        await saveAllocation(store);
      }
    }

    async function deleteAllocations() {
      const cellsSelected = props.selectedCells;
      await deleteAllocation(store, cellsSelected, gridApi.value)
    }

    function showInOut(event) {
      const isChecked = event.target.checked;
      props.showInOutToggle(isChecked);
    }

    const removeEmptyRows = async() => {
      props.onGridReady();
    }

    const approveAttendanceCells = async() => {
      const cellsSelected = props.selectedCells;
      const selectedCells = await approveAttendance(store, gridApi.value, cellsSelected);
      props.clearSelection(selectedCells);
      console.log(selectedCells)
    }

    const rejectAttendanceCells = async() => {
      const cellsSelected = props.selectedCells;
      const selectedCells = await rejectAttendance(store, gridApi.value, cellsSelected);
      props.clearSelection(selectedCells);
      console.log(selectedCells)
    }

    function updateMonthYear(monthYear) {
      context.emit('update-month-year', monthYear)
    }

    return {
      label,
      gridView,
      isWeekPicker,
      currentViewFolder,
      updateMonthYear,
      isAdmin,
      showInOut,
      isApprovalManager,
      isReportingManager,
      showFilter,
      saveChanges,
      removeEmptyRows,
      approveAttendanceCells,
      rejectAttendanceCells,
      clearSelections,
      deleteAllocations,
    };
  }
};
</script>

<style>

.custom-header-group {
  width: 100%;
  justify-content: space-between;
}

.custom-header-group div {
  cursor: pointer;
  position: relative;
}

.custom-header-group span {
  font-size: 20px;
  color: #292929;
  margin: 10px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 10px;
}

.filter-icon {
  cursor: pointer;
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}

.filter-icon:hover {
  background-color: #83ACC8;
}

.action-container {
  display: flex;
  align-items: center;
  color: #292929;
}

.action-button {
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
  text-wrap: nowrap;
}

.action-button:hover {
  background-color: #83ACC8;
}

.action-save {
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}

.action-save:hover {
  background-color: #83ACC8;
}
</style>
