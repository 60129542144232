<template>
  <div
    class="progress square-edge"
    :data-test-id="`progress-bar/${rowIndex}`"
  >
    <div
      :data-test-id="`progressBar/${rowIndex}`"
      class="progress-bar bg-success text-dark overflow-visible"
      role="progressbar"
      :aria-valuenow="currentProgress"
      aria-valuemin="0"
      aria-valuemax="100"
      :style="{ width: `${currentProgress}%` }"
    >
      <b
        v-if="!processed"
        class="justify-content-center d-flex position-absolute w-100"
        name="status"
      > {{ msg }}% processed</b>
      <b
        v-else
        class="justify-content-center d-flex position-absolute w-100"
        name="message"
      >{{ msg }}</b>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
export default {
  name: 'WfmProgressLoader',
  props: {},
  setup(props) {
    //variables
    const stages = ref();
    const progress = ref();
    const msg = ref();
    const currentProcessId = ref(props?.params?.data?.id);
    const rowIndex = props.params.node.rowIndex

    const currentProgress = ref(0);
    const processed = ref(props?.params?.data?.processed);
    if (processed.value === true) {
      currentProgress.value = 100;
      msg.value = 'Completed';
    } else {
      msg.value = '0';
    }
    const handleBroadcastMessage = (event) => {
      // Log or process the broadcast message data
      // console.log('Broadcast message received:', event);
      // Handle the broadcast message as needed...
      if (currentProcessId.value === event?.detail?.id) {
        progress.value = event.detail.count;
        const [currentStage, totalStages] = event.detail.stage.split('/');
        const [processed, total] = event.detail.count.split('/');
        const stageProgress = (parseInt(currentStage) - 1) / parseInt(totalStages);
        const recordProgress = (parseInt(processed) / parseInt(total)) / parseInt(totalStages);
        currentProgress.value = (stageProgress * 100) + (recordProgress * 100);
        msg.value = Math.round(currentProgress.value * 100) / 100;
      }
    };

    onMounted(() => {
      // Add event listener for "broadcastReceived" event
      window.addEventListener('eventReceived', handleBroadcastMessage);
    });
    return {
      stages,
      msg,
      progress,
      currentProgress,
      processed,
      rowIndex
    };
  },
};
</script>

<style>

.progress {
  --bs-progress-bg: #cdd1ce !important;
}

.progressBar  {
  justify-content: center;
  align-items: center;
}

.square-edge {
  border-radius: 0;
  margin: 10px;
}
</style>
