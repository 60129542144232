<template>
  <div
    v-if="alcStatus.show"
    class="container"
  >
    <div class="alc_container">
      <div class="inner_container">
        <div class="alc_shift">
          <span class="shift">{{ alcStatus.shift }}</span>
        </div>
        <div class="alc_shift">
          <span class="shift">{{ alcStatus.site }}</span>
        </div>
      </div>
      <div class="alc_type">
        <span class="site">{{ alcStatus.allocationType }}</span>
      </div>
      <div class="alc_department">
        <span class="department">{{ alcStatus.department }}</span>
      </div>
    </div>
    <div
      id="designationID"
      class="designation"
    >
      designation
    </div>
  </div>
  <div
    v-else
    class="container"
  >
    <!-- comment -->
  </div>
</template>

<script>
import {
  computed,
} from 'vue';
export default {
  name: 'AllocationCellRenderer',
  setup(props) {

    function alcData(props, show) {
      let retVal = {};
      const shift = 'shifts_id' in props.params.value ? props.params.value['shifts_id']?.[0]?.name : null;
      const allocation_type = 'allocation_type' in props.params.value ? props.params.value['allocation_type']?.[0]?.name?.eng : null;
      const site = 'sites_id' in props.params.value ? props.params.value['sites_id'][0]?.code : null;
      let department = 'departments_id' in props.params.value ? props.params.value.departments_id?.[0]?.name?.eng : null
      if (department === undefined || department === null) {
        if (props.params.data?.employees?.[0]?.departments !== null) {
          department = props.params.data?.employees?.[0]?.departments?.[0]?.name?.eng;
        } else {
          department = null;
        }
      }
      retVal = { 'show':show, 'shift': shift, 'allocationType': allocation_type, 'site': site, 'department': department}
      const targetElement = props.params.eGridCell;
      // if (('sites_id' in props.params.value) && props.params.showContractsCellColor) {
      //   const backgroundColor = getRandomColor('sites_id', props.params.value.sites_id?.[0]?.code, 0.5);
      //   targetElement.style.backgroundColor = backgroundColor;
      // } else {
      //   targetElement.style.backgroundColor = '';
      // }
      if ('edited' in props.params.value) {
        if (!props.params.value.edited) {
          targetElement.style.border = 'solid red 1px';
          targetElement.style.boxShadow = '0 0 5px';
        } else {
          targetElement.style.border = 'solid lightgreen 1px';
          targetElement.style.boxShadow = '0 0 5px';
        }
      }
      return retVal;
    }

    const alcStatus = computed(() => {
      let retVal = {};
      let show = true;
      if (props.params['value'] !== undefined && props.params['value'] !== null) {
        retVal = alcData(props, show);
      }
      else {
        show = false;
        retVal = { 'show': show }
      }
      return retVal
    })

    return {
      alcStatus,
    };
  }
}
</script>

  <style>
  .ag-cell {
    text-align: center;
    width: 100%;
    padding: 0px;
    height: 100%;
    border: none;
  }

  .ag-cell:focus {
    border: 1px solid rgb(0, 0, 0) !important;
  }

  .ag-ltr .ag-cell {
    border: none;
  }

  .container {
    width: 100% !important;
    padding: 0px !important;
    height: 100% !important;
  }

  .alc_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    border: 0.01px solid #aebbcc;
    z-index: 0;
  }

  .inner_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
  }

  .alc_shift {
    z-index: 2;
    display: flex;
    height: 12px;
    margin: 3px;
    font-size: 10px;
    align-items: normal;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  .img {
    margin: 0;
    padding: 0;
    width: 5px;
    height: 5px;
  }

  .editing {
    margin: 0;
    padding: 0;
    width: 8px;
    height: 8px;
  }

  .alc_type {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    font-size: 12px;
  }

  .alc_department {
    font-size: 10px;
  }

  .site {
    margin: 0px;
    padding: 2px;
  }


  .designation {
  position: relative;
  z-index: 1;
  display: flex;
  height: 18px;
  font-size: 0px;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 39px;
}
  </style>
