import { sendMessage } from '../src/services/websocket.js';
import router from '../src/router/index.js';
import { toast } from 'vue3-toastify';

const actions = {

  async listTxnArraySetter(context, value) {
    const txnArray = JSON.parse(JSON.stringify(context.state.listTxnArray));
    txnArray.push(value);
    context.commit('listTxnArrayMutation', txnArray);
  },

  async beSettingsSetter(context) {
    try {
      const swHandle = context.getters['userModule/swHandle'];
      const sessionId = context.getters['sessionIdGetter'];
      const txnBSettings = {
        name: 'txn_compiled_folders_get',
        label: {ENG: 'get compiled folders'},
        foldertype: 'folders',
        swhandle: swHandle,
        schema: `${swHandle}_folders`,
        target_swhandle: swHandle,
        folder: 'folders',
        schedule: {type: 'immediate'},
        permissions: {},
        shared_permissions: {},
        params: {},
        client_props: {txn_op: 'R'},
        session_key: sessionId
      };
      const value = await sendMessage(txnBSettings)
      const statusType = value.output.type
      if (statusType === 'success') {
        value.output.data.records[0] = JSON.parse(value.output.data.records[0])
        console.log('BSETTINGS', value);
        context.commit('bSettingsMutations', value)
      }
      else if (statusType === 'error') {
        if (value.output.message === 'No employee found with this session id') {
          toast.error(value.output.message, {
            autoClose:4000
          })
          localStorage.clear();
          setTimeout(() => {
            router.go('/user-auth');
          }, 4000);
        }
      }
    }
    catch (err) {
      throw err;
    }
  },

  async folderSetters(context, value) {
    try {
      const txnParams = {
        refRows: true,
      };
      let listTxn = {}
      for (let i = 0; i < value.txn_list.length; i++) {
        // console.log(value.txn_list[i].slice(-4))
        if (value.txn_list[i].slice(-4) === 'list') {
          listTxn = value.txns[value.txn_list[i]]
          break
        }
      }
      listTxn.record_identifier = "getValue(record, ['code'])"
      listTxn.params = txnParams
      const retVal = await sendMessage(listTxn)
      if (retVal.output.type === 'success') {
        context.commit('folderMutations', retVal)
      }
      else {
        console.log('error', retVal)
      }
      router.push({
        name: 'List',
        params: {
          name: value.name
        }
      })
    }
    catch (err) {
      throw err;
    }
  }
};

export default actions;
