import { sendMessage } from '../services/websocket';
import { v4 as uuidv4 } from 'uuid';
import store from '../../store';
import getFormInfo from './getFormInfo';
const fetchData = () => {
  async function login(swHandle, empCode, password) {
    const txnLoginEmployee = {
      name: 'txn_user_login',
      folder: 'sessions',
      swhandle: swHandle,
      schema: swHandle + '_folders',
      foldertype: 'folders',
      label: {
        eng: 'User Login',
      },
      permissions: {
        env: '',
        row: '',
      },
      shared_permissions: {
        env: '',
        row: '',
      },
      schedule: {
        type: 'immediate',
      },
      params: {
        swhandle: swHandle,
        code: empCode,
        password: password,
      },
      client_props: {
        txn_op: 'X',
        cache_when: 'never',
      },
      display_order: 4,
      expected: [{}],
      template: 'body.tmpl',
      baseFolderPath: 'business.folders.sessions',
    };
    const retVal = await sendMessage(txnLoginEmployee);
    if (retVal.output.type === 'success') {
      const records = [];
      for (let i = 0; i < retVal.output.data.records.length; i++) {
        records.push(JSON.parse(retVal.output.data.records[i]));
      }
      retVal.output.data.records = records;
    }
    return retVal;
  }

  async function logout(swHandle) {
    const sessionId = store.getters['sessionIdGetter'];
    const txnLogoutEmployee = {
      name: 'txn_user_logout',
      folder: 'sessions',
      swhandle: swHandle,
      schema: swHandle + '_folders',
      foldertype: 'folders',
      label: {
        eng: 'User Logout',
      },
      permissions: {
        env: '',
        row: '',
      },
      shared_permissions: {
        env: '',
        row: '',
      },
      schedule: {
        type: 'immediate',
      },
      params: {},
      client_props: {
        txn_op: 'Y',
        cache_when: 'never',
      },
      display_order: 1,
      expected: [{}],
      session_key: sessionId,
      template: 'body.tmpl',
      baseFolderPath: 'business.folders.sessions',
    };
    return await sendMessage(txnLogoutEmployee);
  }

  async function signup(swHandle, empCode, password) {
    const currentDate = new Date();
    const joiningDate = currentDate
      .toISOString()
      .slice(0, 23)
      .replace('T', ' ');
    const txnBusinessSignup = {
      name: 'txn_businesses_sign_up',
      foldertype: 'folders',
      baseFolderPath: 'directory.folders.businesses',
      label: {
        eng: 'Business Sign Up',
      },
      permissions: {
        env: '',
        row: '',
      },
      shared_permissions: {
        env: '',
        row: '',
      },
      access_type: 'anonymous',
      schedule: {
        type: 'immediate',
      },
      template: 'body.tmpl',
      params: {
        swhandle: swHandle,
        code: empCode,
        password: password,
        joining_date: joiningDate,
        person_id: uuidv4(),
      },
      tmpl: {
        platform: 'nodejs',
        name: 'business_sign_up.tmpl',
        server_target: 'shard',
        export: true,
      },
      client_props: {
        txn_op: 'C',
        cache_when: 'never',
      },
      display_order: 3,
      folder: 'businesses',
      schema: 'businesses',
      swhandle: 'directory',
    };
    return await sendMessage(txnBusinessSignup);
  }

  async function listData(folder, txnParams) {
    try {
      const sessionId = store.getters['sessionIdGetter'];
      let listTxn = {};
      if (folder?.name === 'attendance') {
        listTxn = folder.txns['txn_attendance_list'];
      } else {
        for (let i = 0; i < folder.txn_list.length; i++) {
          if (folder.txn_list[i].slice(-4) === 'list') {
            listTxn = folder.txns[folder.txn_list[i]];
            break;
          }
        }
      }
      listTxn.record_identifier = "getValue(record, ['code'])";
      listTxn.session_key = sessionId;
      listTxn.params = txnParams;
      // await store.dispatch("listTxnArraySetter", listTxn);
      console.log('sending msg1', listTxn);
      // let waiting = true;
      const retVal = await sendMessage(listTxn);
      console.log('sending msg2', retVal);
      if (retVal.output.type === 'success') {
        const records = [];
        for (let i = 0; i < retVal.output.data.records.length; i++) {
          records.push(JSON.parse(retVal.output.data.records[i]));
        }
        retVal.output.data.records = records;
      }
      return retVal;
    } catch (err) {
      console.log(err);
    }
  }

  async function sendMail(folder) {
    const { getCurrentTxn } = getFormInfo()
    const emailTxn = getCurrentTxn(null, folder, 'txn_email_send')
    console.log('in the composable', emailTxn)
  }

  return { login, logout, signup, listData, sendMail };
};

export default fetchData;
