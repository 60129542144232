<template>
  <HeaderComponent
    class="month-switcher"
    :current-folder="currentFolder"
    :is-duty-chart="isDutyChart"
    :selected-cells="selectedCells"
    :on-grid-ready="onGridReady"
    :show-in-out-toggle="showInOutTimeToggle"
    :update-filter-values="updateFilterValues"
    :clear-selection="clearSelection"
    :filters-data="dataSource"
    @update-month-year="updateMonthYear"
  />
  <div
    class="grid-container"
    @keydown.capture="keyboardCommandsHandler"
  >
    <div v-if="showLoader">
      <WfmLoder />
    </div>
    <div
      id="my-grid"
      :style="{ visibility: !showLoader ? 'visible' : 'hidden' }"
      class="ag-theme-quartz"
    >
      <ag-grid-vue
        :style="{width: '100%', height: gridHeight}"
        class="ag-grid"
        row-height="70"
        :column-defs="columnDefs"
        :default-col-def="defaultColDef"
        :row-buffer="rowBuffer"
        :row-selection="rowSelection"
        :suppress-row-selection="suppressRowSelection"
        :suppress-row-click-selection="suppressRowClickSelection"
        :stop-editing-when-grid-loses-focus="stopEditingWhenGridLosesFocus"
        :loading-overlay-component="loadingOverlayComponent"
        :row-model-type="rowModelType"
        :cache-block-size="cacheBlockSize"
        :cache-overflow-size="cacheOverflowSize"
        :max-concurrent-data-source-requests="maxConcurrentDatasourceRequests"
        :infinite-initial-row-count="infiniteInitialRowCount"
        :max-blocks-in-cache="maxBlocksInCache"
        :pinned-bottom-row-data="pinnedBottomRowData"
        :on-cell-key-down="onCellKeyDown"
        :tooltip-mouse-track="true"
        :enable-browser-tooltips="true"
        header-height="60"
        @grid-ready="onGridReady"
        @cell-clicked="onCellClicked"
      />
    </div>
  </div>
  <WfmModal
    v-if="formOpen"
    class="calendar-modal"
    @close="closeCalendar"
  >
    <template #header>
      <div class="d-flex" />
    </template>
    <template #body>
      <div class="m-3 mt-0 h-100">
        <!-- eslint-disable vue/no-v-html -->
        <WfmTabsHorizontal
          :key="tabKey"
          :tabs-list="tabsList"
          :selected-index="selectedIndex"
          :custom-class="customTabsClass"
          @add-tab="addTab"
          @del-tab="delTab"
          @change-tab="changeTab"
          @update-keep-alive-state="updateKeepAliveState"
        />
      </div>

      <!--eslint-enable-->
    </template>
  </WfmModal>

  <HelperList
    grid-view="attendance"
    :helper-list-data="dataSource"
    :show-designation="showDesignations"
    :show-site="showSites"
  />
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import { onBeforeMount, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { toast } from 'vue3-toastify';
import { AgGridVue } from 'ag-grid-vue3';
import WfmLoder from '../../common/loader/wfm-loader.vue';
import AttendanceCellRenderer from './grid-components/CellRendrer.vue';
import HeaderComponent from '../grid/common-components/HeaderComponent.vue';
import GridHeaderComponent from '../grid/common-components/GridHeaderComponent.vue';
import EmployeesHeaderComponent from '../grid/common-components/EmployeesHeaderComponent.vue';
import TotalHoursHeaderComponent from '../grid/common-components/TotalHoursHeaderComponent.vue';
import dataProcessor from '../../composables/attendanceGridDataProcessor';
import attendanceGridUtils from '../../composables/attendanceGridUtils';
import allocationGridUtils from '../../composables/allocationGridUtils';
import calendarUtils from '../../composables/calendarUtils';
import EmployeeCellEditor from '../grid/common-components/EmployeeCellEditor.vue';
import cellEditor from './grid-components/CellEditor.vue';
import EmployeeCellRenderer from '../grid/common-components/EmployeeCellRenderer.vue';
import PinnedRowRenderer from './grid-components/PinnedRowRenderer.vue';
import TotalHoursCellRenderer from '../grid/common-components/TotalHoursCellRenderer.vue';
import CustomLoadingOverlay from '../grid/common-components/CustomLoadingOverlay.vue';
import HelperList from '../grid/common-components/HelperList.vue';
import getFolders from '../../composables/getFolders';
import WfmModal from '../../common/wfm-modal.vue';
import WfmTabsHorizontal from '../../common/wfm-tabs-horizontal.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'AttendanceGrid',
  components: {
    /* eslint-disable vue/no-unused-components */
    WfmLoder,
    HeaderComponent,
    AgGridVue,
    AttendanceCellRenderer,
    cellEditor,
    GridHeaderComponent,
    PinnedRowRenderer,
    HelperList,
    WfmModal,
    WfmTabsHorizontal
    /* eslint-enable */
  },
  setup(props) {
    //variables
    // Extract necessary data from the store
    const store = useStore();
    const { getAttendanceDate, txnDataSetter, generateTxn, save, attendancePeriodColumns, getDocpickerList, eventClassNames, refBy, events, eventContent, selectedCellsSetter } = attendanceGridUtils();
    const { getAllDocpickerList } = allocationGridUtils();
    const { getFoldersFormList, getCurrentFolder } = getFolders();

    const bSettings = store.getters['bSettings'];
    const folderList = getFoldersFormList(bSettings);
    const currentFolder = computed(() => {
      return getCurrentFolder('attendance', folderList)
    })
    const selectedIndex = ref(0)
    const tabKey = ref(0)
    const tabsList = ref([])

    let newRow = false;
    let isEdited = false;
    let deselectAllCalled = false;
    console.log(deselectAllCalled)
    let showInOut = false;
    let toggleCellColor = false;
    let showSitesCellColor = false;
    let showDesignationsCellColor = false;
    const onGridReadyCalled = ref(false);
    const dataSource = ref({});
    const filterValues = ref({
      contract: null,
      site:null,
      shift:null,
      designation: null,
      department:null,
      attendanceType:null,
      reportingManger:null
    });
    const selectedCells = ref([]);
    let selectAll = false;
    const cellSelected = ref([])
    const customTabsClass = computed(() => {
      //change background and give maximum height
      return 'bg-body h-100 p-2'
    })
    const currentDateTime = new Date();
    const timeRange = ref({
      startWeek: null,
      endWeek:null,
      day: 1,
      year: currentDateTime.getFullYear(),
      month: currentDateTime.getMonth() + 1
    });
    const gridApi = ref();
    const newRows = ref();
    let copyValue = null;
    const currEmpAttData = ref([]);
    let selectedRows = [];
    const defaultColDef = ref({
      flex: 1,
      resizable: true,
      minWidth: 100,
    });
    const gridHeight = ref('76vh');
    const rowBuffer = ref(null);
    const rowSelection = ref(null);
    const rowModelType = ref(null);
    const cacheBlockSize = ref(null);
    const cacheOverflowSize = ref(null);
    const maxConcurrentDatasourceRequests = ref(null);
    const infiniteInitialRowCount = ref(null);
    const maxBlocksInCache = ref(null);
    const gridData = ref(null);
    const pinnedBottomRowData = ref(null);
    const totalRowCount = ref(0);
    const showLoader = ref();
    const suppressRowSelection = ref();
    const suppressRowClickSelection = ref();
    const stopEditingWhenGridLosesFocus = ref();
    const loadingOverlayComponent = ref();
    // Define the column definitions for the ag-Grid
    const columnDefs = ref([
      {
        headerName: 'Employees',
        pinned: 'left',
        minWidth: 170,
        maxWidth: 180,
        suppressSizeToFit: false,
        field: 'employees',
        unSortIcon: true,
        sortable: true,
        suppressMovable: true,
        cellEditor: EmployeeCellEditor,
        headerComponent: EmployeesHeaderComponent,
        editable: (params) => {
          if (params.node.rowPinned) {
            return false;
          }
          return true;
        },
        filter:'agColumnsFilter',
        filterParams: {
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
        enableCellChangeFlash: true,
        cellStyle: {
          color: 'black',
          fontSize: '14px',
          border: '0.1px solid #dde2eb',
          'justify-content': 'center',
        },
        cellEditorParams: {
          'currentFolder':currentFolder.value
        },
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: PinnedRowRenderer,
            };
          } else {
            return {
              component: EmployeeCellRenderer,
            };
          }
        },
        cellRendererParams : {
          gridView: 'attendance',
          openCalendar : openCalendar
        }
      },
      ...attendancePeriodColumns(timeRange.value, dataSource.value, showSitesCellColor, showDesignationsCellColor, isEditedEnable, selectedCells.value, selectAll, showInOut),
      {
        field: 'total',
        maxWidth: 220,
        minWidth: 200,
        sortable: false,
        suppressMovable: true,
        enableCellChangeFlash: true,
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: PinnedRowRenderer,
            };
          }
          return {
            component: TotalHoursCellRenderer,
          };
        },
        cellRendererParams: {
          'currentFolder': currentFolder.value,
        },
        pinned: 'right',
        headerComponent: TotalHoursHeaderComponent,
        headerComponentParams: {
          addNewRow: addNewRow,
          clearSelection:clearSelection
        },
        cellStyle: {
          color: 'black',
          fontSize: '14px',
          border: '0.1px solid #dde2eb',
        },
      },
    ]);

    //calendar config
    const formOpen = ref(false)
    const formFolder =  bSettings?.output?.data?.records[0]?.business?.containers?.folders?.folders?.leaves;
    const txnForCalendar = bSettings?.output?.data?.records[0]?.business?.containers?.folders?.folders?.leaves?.txns?.txn_leaves_create;
    const keepAliveState = ref(null);
    const initialDate = computed(() => {
      if (Object.keys(timeRange.value).length !== 0) {
        const formattedDate = `${timeRange.value.year}-${String(timeRange.value.month).padStart(2, '0')}-01`;
        return formattedDate;
      }
      return new Date();
    });
    const calendarHeader = computed(() => {
      const empCode = currEmpAttData.value.employees?.[0]?.code
      const empName = currEmpAttData.value.employees?.[0]?.persons_id?.[0]?.first_name.eng
      return `${empName} (${empCode})`
    })
    const calendarParams = () => {
      const { empLeaveSelectAllow } = calendarUtils();
      return {
        'select-allow' : empLeaveSelectAllow(currEmpAttData),
        'readOnly':true,
        'selectable': true,
        'actionString': 'Apply for leave',
        'events': events(currEmpAttData),
        'eventClassNames': eventClassNames,
        'folder':formFolder,
        'txnToUse':txnForCalendar,
        'eventContent': eventContent,
        'header':calendarHeader.value,
        'refBy': refBy(currEmpAttData, formFolder),
        'initialDate': initialDate.value,
        'current-task':'create'
      }
    }

    function openCalendar(employeeAttData) {
      currEmpAttData.value = employeeAttData;
      formOpen.value = true
      selectedIndex.value = 0
      tabsList.value.push(
        {
          label:'Calender',
          component:'WfmCalendar',
          name:'calender',
          params:calendarParams()
        }
      )
    }

    function closeCalendar() {
      //empty the tabslist and close the modal
      tabsList.value = []
      console.log('AttendanceGrid closing form ...')
      formOpen.value = false;
      keepAliveState.value = null;
    }

    function updateKeepAliveState(selectionInfo) {
      if (selectionInfo !== undefined && selectionInfo !== null) {
        keepAliveState.value = selectionInfo;
      } else {
        keepAliveState.value = null
      }
    }

    function changeTab(index) {
      selectedIndex.value = index
    }
    function addTab(tabSpec) {
      const idx = tabsList.value.findIndex((obj) => obj.name === tabSpec.name)
      if (idx < 0) {
        tabsList.value.push(tabSpec)
        selectedIndex.value = tabsList.value.length - 1
      }
      else {
        selectedIndex.value = idx
      }
    }

    function delTab(index) {
      tabsList.value.splice(index, 1)
    }
    function isEditedEnable(isEditing) {
      isEdited = isEditing;
    }

    function showInOutTimeToggle(inOutTime) {
      showInOut = inOutTime;
      const monthGroupColumnsDefs = attendancePeriodColumns(timeRange.value, dataSource.value, showSitesCellColor, showDesignationsCellColor, isEditedEnable, selectedCells.value, selectAll, showInOut)
      const columnDefs = gridApi.value.getColumnDefs()
      const columnsArray = [];
      if (columnDefs !== undefined && columnDefs !== null) {
        columnsArray.push(columnDefs[0]);
        columnsArray.push(...monthGroupColumnsDefs);
        columnsArray.push(columnDefs[columnDefs.length - 1]);
        gridApi.value.setColumnDefs(columnsArray)
      }
    }

    function updateFilterValues(filterValue) {
      if (filterValue.type === 'contract') {
        filterValues.value.contract = filterValue.value;
        if (filterValues.value.contract !== null) {
          timeRange.value.day = filterValues.value.contract?.datePreviousMonth;
          let siteList = [];
          let shiftList = [];
          let productList = [];
          for (let i = 0; i < dataSource?.value?.contracts_id.length; i++) {
            if (dataSource?.value?.contracts_id?.[i]?.id === filterValues.value.contract?.id) {
              const filteredList = updateDataSource(dataSource?.value?.contracts_id?.[i], siteList, shiftList, productList);
              siteList = filteredList[0];
              shiftList = filteredList[1];
              productList = filteredList[2];
              break;
            }
          }
          dataSource.value['siteList'] = siteList;
          dataSource.value['shiftList'] = shiftList;
          dataSource.value['productList'] = productList;
        } else {
          timeRange.value.day = 1;
          let siteList = [];
          let shiftList = [];
          let productList = [];
          for (let i = 0; i < dataSource?.value?.contracts_id.length; i++) {
            const filteredList = updateDataSource(dataSource?.value?.contracts_id?.[i], siteList, shiftList, productList);
            siteList = filteredList[0];
            shiftList = filteredList[1];
            productList = filteredList[2];
          }
          dataSource.value['siteList'] = siteList;
          dataSource.value['shiftList'] = shiftList;
          dataSource.value['productList'] = productList;
        }
        setHeaderNames(JSON.parse(JSON.stringify(timeRange.value)));
      } else  if (filterValue.type === 'site') {
        filterValues.value.site = filterValue.value;
      } else if (filterValue.type === 'shift') {
        filterValues.value.shift = filterValue.value;
      } else if (filterValue.type === 'designation') {
        filterValues.value.designation = filterValue.value;
      } else if (filterValue.type === 'department') {
        filterValues.value.department = filterValue.value;
      } else if (filterValue.type === 'attendanceType') {
        filterValues.value.attendanceType = filterValue.value;
      } else if (filterValue.type === 'reportingManager') {
        filterValues.value.reportingManger = filterValue.value;
      }
    }

    function addNewRow() {
      newRow = true;
      gridApi.value.refreshInfiniteCache();
    }

    function clearSelection() {
      deselectAllCalled = true;
      selectedCells.value = [];
      cellSelected.value = []
      const selected = gridApi.value.getSelectedNodes();
      for (let i = 0; i < selected.length; i++) {
        selected[i].setSelected(false);
      }
      const columnDefs = gridApi.value.getColumnDefs();
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].field !== 'employees' && columnDefs[i].field !== 'total') {
          columnDefs[i].cellRendererParams = {
            'showSitesCellColor':showSitesCellColor,
            'showDesignationsCellColor':showDesignationsCellColor,
            'cellsSelected': selectedCells.value,
            'selectAllCells': selectAll
          }
        }
      }
      gridApi.value.setColumnDefs(columnDefs);
    }

    function showSites() {
      const checkedValue = document.querySelector('.sites').checked;
      showSitesCellColor = checkedValue;
      toggleCellColor = true;
      const columnDefs = gridApi.value.getColumnDefs();
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].field !== 'employees' && columnDefs[i].field !== 'total') {
          columnDefs[i].cellRendererParams = {
            'showSitesCellColor':showSitesCellColor,
            'showDesignationsCellColor':showDesignationsCellColor,
            'cellsSelected': selectedCells.value,
            'selectAllCells': selectAll
          }
        }
      }
      gridApi.value.setColumnDefs(columnDefs);
    }

    function showDesignations() {
      const checkedValue = document.querySelector('.designations').checked;
      showDesignationsCellColor = checkedValue;
      toggleCellColor = true;
      const columnDefs = gridApi.value.getColumnDefs();
      for (let i = 0; i < columnDefs.length; i++) {
        if (columnDefs[i].field !== 'employees' && columnDefs[i].field !== 'total') {
          columnDefs[i].cellRendererParams = {
            'showSitesCellColor':showSitesCellColor,
            'showDesignationsCellColor':showDesignationsCellColor,
            'cellsSelected': selectedCells.value,
            'selectAllCells': selectAll
          }
        }
      }
      gridApi.value.setColumnDefs(columnDefs);
    }

    async function keyboardCommandsHandler(event) {
      if (event.altKey === true && event.keyCode === 73) {
        addNewRow(gridApi.value);
      }
      if (event.altKey === true && event.keyCode === 83) {
        await save(store);
      }
      if (
        event.ctrlKey === true &&
        (event.keyCode === 65 || event.keyCode === 97)
      ) {
        gridApi.value.forEachNode((node) => {
          // Check if the row is already selected
          if (!selectedRows.includes(node.id)) {
            selectedRows.push(node.id);
            node.setSelected(true); // Select the row
            selectedRows = [];
          }
        });
      }
    }

    const onCellKeyDown = async(event) => {
      if (event.event.code === 'Space') {
        const colId = event.column.colId;
        const selectedRowNode = event.node;
        const isSelected = selectedRowNode.isSelected();
        if (colId !== 'employees') {
          selectedRowNode.setSelected(!isSelected);
        }
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll, false);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
      } else if (
        event.event.ctrlKey === true &&
        (event.event.keyCode === 65 || event.event.keyCode === 97)
      ) {
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll, false);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
      } else if (event.event.ctrlKey === true && event.event.keyCode === 67) {
        if (event.value !== null && (event.value.leave_type === undefined || event.value.leave_type === null)) {
          copyValue = event.value
        } else {
          toast.warning('Leaves cannot be copied', {
            position: toast.POSITION.TOP_CENTER
          }
          );
        }
      } else if (event.event.ctrlKey === true && event.event.keyCode === 86 && copyValue !== null) {
        const copiedCell = {[`${event.colDef.field}`] :  copyValue}
        const data = event.data;
        event.data = Object.assign(data, copiedCell)
        const newDate = getAttendanceDate(event);
        if (copyValue.attendance_edit !== undefined && copyValue.attendance_edit !== null) {
          event.value = copyValue.attendance_edit;
          copyValue = copyValue.attendance_edit
        } else {
          event.value = copyValue;
          copyValue['employees_id'] = event?.data?.employees;
          delete copyValue.attendance_status;
          delete copyValue.last_processed_by;
          delete copyValue.minWorkingHours;
          delete copyValue.exception;
          delete copyValue.allocations_id;
          delete copyValue.leave_type;
          if (copyValue.attendance_edit !== undefined) {
            delete copyValue.attendance_edit;
          }
        }
        copyValue.attendance_date = newDate
        copyValue['sync_timestamp'] = newDate
        copyValue.id = uuidv4()
        delete copyValue.in_time;
        delete copyValue.out_time;

        const txnData = txnDataSetter(copyValue);
        await generateTxn(txnData);
        gridApi.value.refreshCells();
      } else if (event.event.shiftKey === true && (event.event.keyCode === 37 || event.event.keyCode === 38 || event.event.keyCode === 39 || event.event.keyCode === 40)) {
        const focusedCell = gridApi.value.getFocusedCell();
        if (!focusedCell) return;
        const column = focusedCell.column;
        const nextColumn = column;
        const colId = nextColumn.colId;
        if (colId === undefined || colId === 'employees' || colId === 'total') {
          return;
        }
        const value = event.data[colId]
        event.value = value;
        event.column = nextColumn;
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll, false);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
        event.event.preventDefault();
      }
    };

    async function onCellClicked(event) {
      if (event.event.ctrlKey === true) {
        const colId = event.column.colId;
        const selectedRowNode = event.node;
        const isSelected = selectedRowNode.isSelected();
        if (colId === 'employees') {
          selectedRowNode.setSelected(!isSelected);
        } else {
          selectedRowNode.setSelected(false);
        }
        const result = await selectedCellsSetter(store, event, selectedCells.value, selectAll);
        selectedCells.value = result.cellsSelected;
        selectAll = result.selectAllCells;
        cellSelected.value = result.cellsSelected;
      }
    }

    function addObjectIfUnique(array, newObject) {
      // Check if the object with the same id already exists in the array
      if (newObject !== undefined && newObject !== null) {
        const exists = array.some((obj) => obj.id === newObject?.id);
        // If it doesn't exist, push the new object to the array
        if (!exists) {
          array.push(newObject);
        }
      }
      return array;
    }

    function updateDataSource(contract, siteList, shiftList, productList) {
      if (contract?.attendance_based_items !== null) {
        for (let j = 0; j < contract?.attendance_based_items.length; j++) {
          siteList = addObjectIfUnique(siteList, contract?.attendance_based_items?.[j]?.sites_id?.[0])
          shiftList = addObjectIfUnique(shiftList, contract?.attendance_based_items?.[j]?.shifts_id?.[0])
          productList = addObjectIfUnique(productList, contract?.attendance_based_items?.[j]?.products_id?.[0])
        }
      } else if (contract?.job_based_items !== null) {
        for (let j = 0; j < contract?.job_based_items.length; j++) {
          siteList = addObjectIfUnique(siteList, contract?.job_based_items?.[j]?.sites_id?.[0])
          productList = addObjectIfUnique(productList, contract?.job_based_items?.[j]?.sites_id?.[0])
        }
        if (siteList.length > 0) {
          for (let k = 0; k < siteList.length; k++) {
            for (let l = 0; l < dataSource?.value?.shifts_id?.length; l++) {
              if (siteList?.[k]?.id === dataSource?.value?.shifts_id?.[l]?.sites_id?.[0]?.id) {
                shiftList = addObjectIfUnique(shiftList, dataSource?.value?.shifts_id?.[l])
              }
            }
          }
        }
      }
      return [siteList, shiftList, productList];
    }

    async function filtersData() {
      //using composables to get attendance_edits folder to set as bSettings
      const folderList = getFoldersFormList(bSettings);
      const currentFolder = getCurrentFolder('attendance_edits', folderList);
      const allocationsFolder = getCurrentFolder('allocations', folderList);
      const employeesFolder = getCurrentFolder('employees', folderList);
      const fieldNames = ['att_type', 'shifts_id', 'departments', 'sites_id', 'designation'];
      const docpickerResults = await Promise.all(fieldNames.map((fieldName) =>
        getDocpickerList(fieldName !== 'departments' ? currentFolder?.fields[fieldName] : employeesFolder.fields[fieldName])
      ));
      const contractsList = await getAllDocpickerList(allocationsFolder?.fields['contracts_id']);
      for (let i = 0; i < docpickerResults.length; i++) {
        const docpObj = docpickerResults[i];
        dataSource.value = Object.assign(dataSource.value, docpObj)
      }
      dataSource.value['contracts_id'] = contractsList['contracts_id'];
      // let siteList = [];
      // let shiftList = [];
      // let productList = [];
      // for (let i = 0; i < dataSource?.value?.contracts_id.length; i++) {
      //   const filteredList = updateDataSource(dataSource?.value?.contracts_id?.[i], siteList, shiftList, productList);
      //   siteList = filteredList[0];
      //   shiftList = filteredList[1];
      //   productList = filteredList[2];
      // }
      // dataSource.value['siteList'] = siteList;
      // dataSource.value['shiftList'] = shiftList;
      // dataSource.value['productList'] = productList;
      if (dataSource.value['contracts_id'].length === 1) {
        if (dataSource.value['contracts_id']?.[0]?.date_of_previous_month !== null) {
          timeRange.value.day = dataSource.value['contracts_id']?.[0]?.date_of_previous_month;
        }
      }
      const monthGroupColumnsDefs = attendancePeriodColumns(timeRange.value, dataSource.value, showSitesCellColor, showDesignationsCellColor, isEditedEnable, selectedCells.value, selectAll, showInOut)
      const columnDefs = gridApi.value.getColumnDefs()
      const columnsArray = [];
      if (columnDefs !== undefined && columnDefs !== null) {
        columnsArray.push(columnDefs[0]);
        columnsArray.push(...monthGroupColumnsDefs);
        columnsArray.push(columnDefs[columnDefs.length - 1]);
        gridApi.value.setColumnDefs(columnsArray)
      }
      showLoader.value = false;
      if (newRows.value !== undefined) {
        if (newRows.value.length === 0) {
          gridApi.value.showNoRowsOverlay();
        }
      }
    }

    async function dataGenerator(params) {
      try {
        console.time('test2');
        console.time('test3');
        let attendanceRows = store.getters['agGridModule/attendanceGridRows'];

        if (newRow) {
          attendanceRows.unshift({});
          totalRowCount.value = attendanceRows.length;
          newRow = false;
          setTimeout(() => {
            gridApi.value.setFocusedCell(0, gridApi.value?.columnModel?.columnDefs[0]?.field);
            gridApi.value.startEditingCell({
              rowIndex: 0,
              colKey: gridApi.value?.columnModel?.columnDefs[0],
              keyPress: null,
              charPress: ''
            });
          }, 0);
        } else if (toggleCellColor) {
          toggleCellColor = false;
        } else if (isEdited) {
          isEdited = false;
        } else {
          deselectAllCalled = false;
          const { dataCreation, attendanceList } = dataProcessor();
          gridData.value = await attendanceList(params.filterModel, params, timeRange.value, currentFolder.value, filterValues.value);
          const idx = gridData.value.output?.data?.records[gridData.value.output?.data?.records.length - 1];
          if (idx?.dataStart !== idx?.dataEnd) {
            const rowCount = idx?.rowCount;
            totalRowCount.value = rowCount;
          }
          console.timeEnd('test2');
          attendanceRows = await dataCreation(gridData.value, params.sortModel, timeRange.value);
          onGridReadyCalled.value = false;
          console.timeEnd('test3');
        }
        isEdited = false;
        return attendanceRows;
      } catch (err) {
        console.log(err);
      }
    }

    const onGridReady = (params) => {
      if (gridApi.value === undefined) {
        gridApi.value = params.api;
      }
      store.dispatch('agGridModule/attendanceGridApiSetter', gridApi.value);
      const dataSource = {
        rowCount: 200,
        getRows: async(params) => {
          try {
            console.log(
              'asking for ' + params.startRow + ' to ' + params.endRow
            );
            newRows.value = await dataGenerator(params);
            await store.dispatch(
              'agGridModule/attendanceGridRowsSetter',
              newRows.value
            );
            let lastRow = totalRowCount.value;
            if (newRows.value.length === 0) {
              params.endRow = 0;
              lastRow = 0;
              if (showLoader.value === false) {
                gridApi.value.showNoRowsOverlay();
              }
            } else {
              params.endRow = newRows.value.length;
              gridApi.value.hideOverlay();
            }
            params.successCallback(newRows.value, lastRow);
            // gridApi.value.refreshCells();
            gridApi.value.setPinnedBottomRowData([{}]);
          } catch (err) {
            console.error('Error loading data:', err);
            toast.error(err);
            params.failCallback();
          }
        },
      };
      if (params !== undefined) {
        params.api.setDatasource(dataSource);
        filtersData()
      } else {
        gridApi.value.setDatasource(dataSource);
      }
      gridApi.value.sizeColumnsToFit();
    };

    function setHeaderNames(monthYear) {
      const monthGroupColumnsDefs = attendancePeriodColumns(monthYear, dataSource.value, showSitesCellColor, showDesignationsCellColor, isEditedEnable, selectedCells.value, selectAll, showInOut);
      const newColumnDefs = gridApi.value.getColumnDefs()
      const columnsArray = [];
      columnsArray.push(newColumnDefs[0]);
      columnsArray.push(...monthGroupColumnsDefs);
      columnsArray.push(newColumnDefs[newColumnDefs.length - 1]);
      columnDefs.value = columnsArray;
      gridApi.value.refreshInfiniteCache();
    }

    function updateMonthYear(newMonthYear) {
      timeRange.value = newMonthYear;
    }

    //watchers
    watch(() => timeRange.value,
      async(newTimeRange, oldTimeRange) => {
        if (
          newTimeRange.year !== oldTimeRange.year ||
          newTimeRange.month !==
            new Date(`${oldTimeRange.month} 1, 2000`).getMonth() + 1
        ) {
          totalRowCount.value = 0;
          setHeaderNames(JSON.parse(JSON.stringify(newTimeRange)));
        }
      }
    );

    //lifecycle hooks
    onBeforeMount(async() => {
      rowBuffer.value = 0;
      rowModelType.value = 'infinite';
      rowSelection.value = 'multiple';
      cacheBlockSize.value = 100;
      cacheOverflowSize.value = 2;
      maxConcurrentDatasourceRequests.value = 1;
      infiniteInitialRowCount.value = 0;
      maxBlocksInCache.value = 5;
      pinnedBottomRowData.value = [0];
      showLoader.value = true;
      suppressRowSelection.value = true;
      suppressRowClickSelection.value = true;
      stopEditingWhenGridLosesFocus.value = true;
      loadingOverlayComponent.value = CustomLoadingOverlay
    });

    return {
      currentFolder,
      selectedCells,
      updateMonthYear,
      showInOutTimeToggle,
      cellSelected,
      clearSelection,
      updateFilterValues,
      onCellKeyDown,
      onCellClicked,
      showLoader,
      columnDefs,
      gridApi,
      defaultColDef,
      gridHeight,
      rowBuffer,
      rowSelection,
      suppressRowSelection,
      suppressRowClickSelection,
      stopEditingWhenGridLosesFocus,
      rowModelType,
      cacheBlockSize,
      cacheOverflowSize,
      maxConcurrentDatasourceRequests,
      infiniteInitialRowCount,
      maxBlocksInCache,
      keyboardCommandsHandler,
      onGridReady,
      timeRange,
      pinnedBottomRowData,
      calendarParams,
      formFolder,
      txnForCalendar,
      formOpen,
      closeCalendar,
      currEmpAttData,
      updateKeepAliveState,
      dataSource,
      showSites,
      showDesignations,
      tabKey,
      tabsList,
      selectedIndex,
      addTab,
      changeTab,
      delTab,
      customTabsClass
    };
  },
};
</script>
<style scoped>

.grid-container {
  padding: 2px;
  border: 1px solid #83ACC8;
  display: flex;
  flex-direction: column;
}
.calendar-modal{
  z-index: 1000 !important;
}
#my-grid {
  width: 100%;
}

.ag-theme-quartz .ag-row-selected {
  border: 1px solid #00b7ff !important;
  background: #f5e9c8 !important;
}

:deep .ag-root-wrapper {
  overflow: visible;
}

.ag-theme-quartz {
  --ag-foreground-color: black;
  --ag-background-color: #ffffff;
  --ag-header-foreground-color: var(--main-background-color);
  --ag-header-background-color: #83ACC8;
  /* --ag-odd-row-background-color: rgb(0, 0, 0, 0); */
  --ag-font-size: 12px;
  --ag-overlay-background-color: var(--main-background-color) !important;
  --ag-selected-row-background-color: rgba(0, 102, 255, 0.1);
  --ag-font-family: 'Poppins', sans-serif;
  --ag-popup-shadow: black;
  --ag-borders: none;
  --ag-row-border-style: solid;
  --ag-row-border-width: 0.1px;
  --ag-row-border-color: #aebbcc;
  --ag-header-column-resize-handle-display: inline;
}

.month-switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex-shrink: 0;
  z-index: 10;
}
</style>
