<template>
  <div class="pinned-row-container">
    <div
      v-for="val in totalRows"
      :key="val"
      style="display: flex;"
    >
      <span>
        <span>{{ val }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';

export default {
  name: 'PinnedRowRenderer',
  setup(props) {
    const { getTotalDays, formatHrsAndDays, convertMinutesToHours, getDisplayStringForHrs } = attendanceGridUtils();

    const totalRows = computed(() => {
      let retVal = '';
      if (props.params.column.pinned === 'left') {
        const rowCount = props.params.api.getDisplayedRowCount();
        retVal = ['Total Rows: ' + rowCount];
      } else if (props.params.column.pinned === 'right') {
        const result = { present: 0, partialAttendance: 0, punchRequest: 0, absent: 0, rest: 0, weeklyOff: 0, holidays: 0, leaves: 0, leaveWithoutPay: 0, deficitHours: 0 };
        props.params.api.forEachNode((r, i) => {
          if (r.data !== null) {
            const totalDays = getTotalDays(r.data);
            result.present += totalDays.present || 0;
            result.absent += totalDays.absent || 0;
            result.rest += totalDays.rest || 0;
            result.holidays += totalDays.holidays || 0;
            result.leaves += totalDays.leaves || 0;
            result.deficitHours += totalDays.deficitHours || 0;
            result.partialAttendance += totalDays.partialAttendance || 0;
            result.punchRequest += totalDays.punchRequest || 0;
            result.weeklyOff += totalDays.weeklyOff || 0;
            result.leaveWithoutPay += totalDays.leaveWithoutPay || 0;
            result.deficitHours = parseFloat(result.deficitHours.toFixed(2))
          }
        });
        retVal = formatHrsAndDays(result);
      } else {
        let result = 0;
        props.params.api.forEachNode((r, i) => {
          if (r.data !== null && r.data !== undefined) {
            if (r.data[props.params.column.colId] !== undefined) {
              const workingHours = convertMinutesToHours(r.data[props.params.column.colId].working_minutes);
              const overtimeHours = convertMinutesToHours(r.data[props.params.column.colId].overtime_minutes);
              result += (workingHours || 0) + (overtimeHours || 0);
            }
          }
        });
        retVal = [getDisplayStringForHrs(result)];
      }
      return retVal;
    });

    return {
      totalRows
    };
  }
}
</script>

<style>
.ag-cell .pinned-row-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  border-right: 0.01px solid #dde2eb;
  flex-wrap: wrap;
}

.ag-cell .pinned-row-container div {
  margin: 5px;
  height: 20px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
</style>
