const commonModule = {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {

  }
}

export default commonModule;
