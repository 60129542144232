<template>
  <WfmToolbar
    :data-test-id="'form_' + folder.name"
    custom-class="action-class"
  >
    <template #left-utility>
      <WfmActionsList
        :actions="actionsToPerformPayslip.length>0?actionsToPerformPayslip:null"
        :data-test-id="'form_' + folder.name"
        @action-click="performActionModal"
      />
    </template>
  </WfmToolbar>
  <div class="payslip-container">
    <div class="p-4">
      <WfmLoader v-if="showLoader" />
      <div v-html="payslipsTemplate?.html" />
    </div>
  </div>
</template>


<script>
import { computed, ref, onMounted} from 'vue';
import WfmToolbar from '../../common/wfm-toolbar.vue';
// import getUtility from '../../composables/getUtility'
import WfmActionsList from '../../common/wfm-actions-list.vue';
import useIcons from '../../composables/getIcons'
import { sendMessage } from '../../services/websocket'
import { useStore } from 'vuex';
import WfmLoader from '../../common/loader/wfm-loader.vue'

export default {
  name: 'UploadForm',
  components: {
    WfmToolbar,
    WfmActionsList,
    WfmLoader
  },
  props: {
    folder: {
      type: Object,
      default: null,
    },
    payslipData:{
      type:Object,
      default:null
    }
  },
  emits:['close-payslips'],
  setup(props, context) {
    const showLoader = ref(false)
    const {getIcon} = useIcons()
    const store = useStore()
    const serverPort = store.getters['serverPortGetter']

    const actionsToPerformPayslip = computed(() => {
      const retVal = []
      if (actionsInPayslip.value.actions.length > 0) {
        retVal.push(actionsInPayslip.value)
      }
      return retVal
    })
    function performActionModal(action) {
      console.log('handle action in the modal')
      action()
    }
    const payslipsTemplate = ref()
    async function getPayslips() {
      try {
        showLoader.value = true
        const payslipsGetTxn = props.folder.txns.txn_payslips_get_payslip
        const params = {'uconst': props.payslipData.uconst}
        payslipsGetTxn.params = params
        const res = await sendMessage(payslipsGetTxn)
        showLoader.value = false
        if (res.output.type == 'success') {
          payslipsTemplate.value = res.output
        }
      } catch (error) {
        console.log(error)
      }

    }

    const actionsInPayslip = computed(() => {
      const retVal = {name:'Actions', actions:[
        {
          name:'Download',
          icon:getIcon('Download')?.name,
          fill:getIcon('Download')?.fill,
          action:() => {
            download()
          }
        }, {
          name:'Send Mail',
          icon:getIcon('Send')?.name,
          fill:getIcon('Send')?.fill,
          action:() => {
            console.log('send mail function here')
          }
        }
      ]}
      return retVal
    })
    function closeModal() {
      try {
        //emit to close
        context.emit('close-payslips')
      } catch (error) {
        console.log(error);
      }
    }
    function download() {

      const fileName = payslipsTemplate.value.fileName
      const start = fileName.indexOf('payslips');
      const result = fileName.substring(start);
      console.log(result, typeof result)
      const url = `http://${window.location.hostname}:${serverPort}/${result}`
      window.open(url, '_blank')

    }
    onMounted(() => {
      getPayslips()
    })
    return {
      actionsToPerformPayslip,
      performActionModal,
      payslipsTemplate,
      closeModal,
      getPayslips,
      showLoader


    };
  },
};
</script>

<style lang="scss">
.action-class{
  background-color: var(--toolbar-color);
  padding-top:30px;
  padding-bottom:30px;
  margin-top: 0px;
  padding-left:10px;
  color:white;
}
.payslip-container{
  width: 100%;
  height: 80vh;
  background-color: white;
}

</style>
