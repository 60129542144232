<template>
  <div
    v-if="employeeShow"
    class="employeeContainer"
  >
    <span class="employeeSpan">{{ employeeObject.name }}</span>
    <span class="employeeSpan">{{ employeeObject.code }}
      <v-icon
        v-if="currentView === 'attendance'"
        name="fa-calendar"
        fill="#292929"
        scale="0.9"
        @click="openCalendar()"
      />
    </span>
  </div>
  <div
    v-else
    class="employeeContainer"
  >
    <!-- <img src="https://www.ag-grid.com/example-assets/loading.gif"> -->
  </div>
</template>


<script>
import { computed, ref } from 'vue';

export default {
  name: 'EmployeeCellRenderer',
  setup(props) {
    const currentView = ref(props.params.gridView);
    const employeeAttData = props?.params?.data;
    const employeeShow = props.params.value === undefined ? false : true;
    const employeeObject = computed(() => {
      if (employeeShow) {
        const empObject = {
          name: Array.isArray(props.params.value) ? props.params.value?.[0]?.persons_id?.[0]?.first_name?.eng : props.params.value?.persons_id?.[0]?.first_name?.eng,
          code: Array.isArray(props.params.value) ? props.params.value?.[0]?.code : props.params.value?.code,
          id: Array.isArray(props.params.value) ? props.params.value?.[0]?.id : props.params.value?.id
        }
        return empObject;
      } else {
        return null;
      }
    })

    function openCalendar() {
      props.params.openCalendar(employeeAttData)
    }

    return {
      currentView,
      employeeShow,
      employeeObject,
      openCalendar
    };
  }
}
</script>
<style>
.ag-cell .employeeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ag-cell .employeeContainer .employeeSpan {
  position: relative;
  bottom: 12px;
  height: 14px;
  margin: 1px;
  font-size: 12px;
}

/* .ag-header-group-cell-label,
.ag-header-cell-label {
  justify-content: center !important;
} */
</style>
