const ls = window.localStorage;

function noop () {}

// Set a key-value pair in localStorage
function set(key, val) {
    ls.setItem(key, val);
}

// Get a value by key from localStorage
function get(key) {
    let val = ls.getItem(key);
    try {
        val = JSON.parse(val);
    }
    catch(e) {
        noop();
    }
    return val;
}

// Remove a key-value pair from localStorage
function remove(key) {
    ls.removeItem(key);
}

// Clear all key-value pairs from localStorage
function purge() {
    ls.clear();
}

// Set a session key (UUID) in localStorage
function setSessionKey(uuid) {
    set('sessionKey', uuid);
}

// Get the session key (UUID) from localStorage
function getSessionKey() {
    return get('sessionKey');
}

// Clear session data on logout
function clearSession() {
    remove('sessionKey');
    purge();  // Optionally, clear all other stored data
}

export {
    set,
    get,
    remove,
    purge,
    setSessionKey,
    getSessionKey,
    clearSession
}
