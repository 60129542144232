import { validate as isUuidValid } from 'uuid';
import store from '../../store/index';

const getFatRow = (data) => {
//TODO: refactor this code
  function fatRowGenerator(data) {
    const docpList = store.getters['folderModule/foldersDocpListGetter'];
    let currentFolderDocp = null;
    const finalData = [];
    if (docpList !== null && docpList[data.folder] !== undefined) {
      currentFolderDocp = docpList[data.folder];
      const records = JSON.parse(JSON.stringify(data.output.data.records));
      const dataObj = records[records.length - 1];
      const dataStart = dataObj['dataStart']
      const dataEnd = dataObj['dataEnd']
      const folderName = Object.keys(records[dataStart])[0]
      for (let i = dataStart; i < dataEnd; i++) {
        const uconst = records[i][folderName];
        let index = dataObj[uconst];
        if (typeof index !== 'number' && index !== undefined) {
          const parts = index.split(/\D+/); // Split based on non-numeric characters
          index = parseInt(parts[0], 10);
        }
        const record = JSON.parse(JSON.stringify(records[index]));
        for (let j = 0; j < currentFolderDocp.length; j++) {
          const keys = Object.keys(currentFolderDocp[j])
          if (keys.length > 1) {
            const docpName = currentFolderDocp[j].name;
            const isMultiple = currentFolderDocp[j].is_multiple;
            if (record[docpName] !== null && record[docpName] !== undefined) {
              if (isMultiple) {
                for (let key = 0; key < record[docpName].length; key++) {
                  if (record[docpName][key] !== null && record[docpName][key] !== undefined) {
                    const docpUconst = record[docpName][key];
                    let docpIndex = dataObj[docpUconst];
                    if (typeof docpIndex !== 'number' && docpIndex !== undefined) {
                      const parts = docpIndex.split(/\D+/); // Split based on non-numeric characters
                      docpIndex = parseInt(parts[0], 10);
                      for (const docpKey in records[docpIndex]) {
                        if (records[docpIndex][docpKey] !== null && Array.isArray(records[docpIndex][docpKey])) {
                          if (records[docpIndex][docpKey].length === 1) {
                            const docpUconst2 = records[docpIndex][docpKey][0];
                            const isUuid = isUuidValid(docpUconst2)
                            if (isUuid) {
                              let docpIdx = dataObj[docpUconst2];
                              if (typeof docpIdx !== 'number' && docpIdx !== undefined) {
                                const parts = docpIdx.split(/\D+/); // Split based on non-numeric characters
                                docpIdx = parseInt(parts[0], 10);
                                records[docpIndex][docpKey][0] = JSON.parse(JSON.stringify(records[docpIdx]));
                              }
                            }
                          } else {
                            for (let m = 0; m < records[docpIndex][docpKey].length; m++) {
                              for (const docpKey2 in records[docpIndex][docpKey][m]) {
                                if (records[docpIndex][docpKey][m][docpKey2] !== null && Array.isArray(records[docpIndex][docpKey][m][docpKey2])) {
                                  const docpKeyUconst = records[docpIndex][docpKey][m][docpKey2][0];
                                  const isUuid = isUuidValid(docpKeyUconst);
                                  if (isUuid) {
                                    let docpKeyIdx = dataObj[docpKeyUconst];
                                    if (typeof docpKeyIdx !== 'number' && docpKeyIdx !== undefined) {
                                      const parts = docpKeyIdx.split(/\D+/); // Split based on non-numeric characters
                                      docpKeyIdx = parseInt(parts[0], 10);
                                      records[docpIndex][docpKey][m][docpKey2][0] = JSON.parse(JSON.stringify(records[docpKeyIdx]));
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      record[docpName][key] = JSON.parse(JSON.stringify(records[docpIndex]));
                    }
                  }
                }
              } else {
                const docpUconst = record[docpName][0];
                let docpIndex = dataObj[docpUconst];
                if (typeof docpIndex !== 'number' && docpIndex !== undefined) {
                  const parts = docpIndex.split(/\D+/); // Split based on non-numeric characters
                  docpIndex = parseInt(parts[0], 10);
                  for (const docpKey in records[docpIndex]) {
                    if (docpKey === 'addresses') {
                      if (records[docpIndex][docpKey] !== null) {
                      }
                    }
                    if (records[docpIndex][docpKey] !== null && Array.isArray(records[docpIndex][docpKey])) {
                      if (records[docpIndex][docpKey].length === 1) {
                        const docpUconst2 = records[docpIndex][docpKey][0];
                        const isUuid = isUuidValid(docpUconst2)
                        if (isUuid) {
                          let docpIdx = dataObj[docpUconst2];
                          if (typeof docpIdx !== 'number' && docpIdx !== undefined) {
                            const parts = docpIdx.split(/\D+/); // Split based on non-numeric characters
                            docpIdx = parseInt(parts[0], 10);
                            records[docpIndex][docpKey][0] = JSON.parse(JSON.stringify(records[docpIdx]));
                          }
                        } else {
                          for (let m = 0; m < records[docpIndex][docpKey].length; m++) {
                            for (const docpKey2 in records[docpIndex][docpKey][m]) {
                              if (records[docpIndex][docpKey][m][docpKey2] !== null && Array.isArray(records[docpIndex][docpKey][m][docpKey2])) {
                                const docpKeyUconst = records[docpIndex][docpKey][m][docpKey2][0];
                                const isUuid = isUuidValid(docpKeyUconst);
                                if (isUuid) {
                                  let docpKeyIdx = dataObj[docpKeyUconst];
                                  if (typeof docpKeyIdx !== 'number' && docpKeyIdx !== undefined) {
                                    const parts = docpKeyIdx.split(/\D+/); // Split based on non-numeric characters
                                    docpKeyIdx = parseInt(parts[0], 10);
                                    records[docpIndex][docpKey][m][docpKey2][0] = JSON.parse(JSON.stringify(records[docpKeyIdx]));
                                  }
                                }
                              }
                            }
                          }
                        }
                      } else {
                        for (let m = 0; m < records[docpIndex][docpKey].length; m++) {
                          for (const docpKey2 in records[docpIndex][docpKey][m]) {
                            if (records[docpIndex][docpKey][m][docpKey2] !== null && Array.isArray(records[docpIndex][docpKey][m][docpKey2])) {
                              const docpKeyUconst = records[docpIndex][docpKey][m][docpKey2][0];
                              const isUuid = isUuidValid(docpKeyUconst);
                              if (isUuid) {
                                let docpKeyIdx = dataObj[docpKeyUconst];
                                if (typeof docpKeyIdx !== 'number' && docpKeyIdx !== undefined) {
                                  const parts = docpKeyIdx.split(/\D+/); // Split based on non-numeric characters
                                  docpKeyIdx = parseInt(parts[0], 10);
                                  records[docpIndex][docpKey][m][docpKey2][0] = JSON.parse(JSON.stringify(records[docpKeyIdx]));
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  record[docpName][0] = JSON.parse(JSON.stringify(records[docpIndex]));
                }
              }
            }
          } else {
            const detailName = keys[0];
            if (record[detailName] !== null && record[detailName] !== undefined) {
              for (let k = 0; k < currentFolderDocp[j][detailName].length; k++) {
                const detailDocpName = currentFolderDocp[j][detailName][k].name;
                if (Array.isArray(record)) {}
                for (let l = 0; l < record[detailName].length; l++) {
                  if (record[detailName][l][detailDocpName] !== null) {
                    const detailUconst = record[detailName]?.[l]?.[detailDocpName]?.[0];
                    let detailIndex = dataObj[detailUconst];
                    if (typeof detailIndex !== 'number' && detailIndex !== undefined) {
                      const parts = detailIndex.split(/\D+/); // Split based on non-numeric characters
                      detailIndex = parseInt(parts[0], 10);
                      for (const detailDocpKey in records[detailIndex]) {
                        if (records[detailIndex][detailDocpKey] !== null && Array.isArray(records[detailIndex][detailDocpKey])) {
                          if (records[detailIndex][detailDocpKey].length === 1) {
                            const detailUconst2 = records[detailIndex][detailDocpKey][0];
                            const isUuid = isUuidValid(detailUconst2)
                            if (isUuid) {
                              let detailIdx = dataObj[detailUconst2];
                              if (typeof detailIdx !== 'number' && detailIdx !== undefined) {
                                const parts = detailIdx.split(/\D+/); // Split based on non-numeric characters
                                detailIdx = parseInt(parts[0], 10);
                                records[detailIndex][detailDocpKey][0] = JSON.parse(JSON.stringify(records[detailIdx]));
                              }
                            }
                          } else {
                            for (let m = 0; m < records[detailIndex][detailDocpKey].length; m++) {
                              for (const docpKey in records[detailIndex][detailDocpKey][m]) {
                                if (records[detailIndex][detailDocpKey][m][docpKey] !== null && Array.isArray(records[detailIndex][detailDocpKey][m][docpKey])) {
                                  const docpKeyUconst = records[detailIndex][detailDocpKey][m][docpKey][0];
                                  const isUuid = isUuidValid(docpKeyUconst);
                                  if (isUuid) {
                                    let docpKeyIdx = dataObj[docpKeyUconst];
                                    if (typeof docpKeyIdx !== 'number' && docpKeyIdx !== undefined) {
                                      const parts = docpKeyIdx.split(/\D+/); // Split based on non-numeric characters
                                      docpKeyIdx = parseInt(parts[0], 10);
                                      records[detailIndex][detailDocpKey][m][docpKey][0] = JSON.parse(JSON.stringify(records[docpKeyIdx]));
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      record[detailName][l][detailDocpName][0] = JSON.parse(JSON.stringify(records[detailIndex]));
                    }
                  }
                }
              }
            }
          }
        }
        finalData.push(JSON.parse(JSON.stringify(record)));
      }
    }
    console.log('FINALDATA', finalData)
    return finalData;
  }

  return { fatRowGenerator };
};

export default getFatRow;
