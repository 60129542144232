import store from '../../store';
import { sendMessage } from '../services/websocket';
import { toast } from 'vue3-toastify';
import processFormData from './processFormData';
import makeTxn from './makeTxn';
import foldersUtility from './foldersUtility';
import makeFilter from './makeFilter';
import postData from './postData';
import getFatRow from './fatRowGenerator';
import makeTxnForBulk from './makeTxnForBulk';
import getFolders from './getFolders';
import getConverters from './getConverters';
import getFormInfo from './getFormInfo';

const attendanceGridUtils = () => {

  function createTimeRange(monthYear) {
    const month = monthYear.month;
    const startDay = 1;
    const year = monthYear.year;
    const endDay = new Date(year, month, 0).getDate();

    // Create a Date object with the specified date and time
    const timestamp = new Date(Date.UTC(year, month - 1, startDay, 0, 0, 0));
    const timestamp2 = new Date(Date.UTC(year, month - 1, endDay, 23, 59, 59));
    // Format the timestamp
    const formattedTimestamp = timestamp
      .toISOString()
      .replace('T', ' ')
      .slice(0, 19);
    const inputDate1 = new Date(formattedTimestamp);

    // Subtract 5 hours and 30 minutes
    inputDate1.setHours(inputDate1.getHours() - 5);
    inputDate1.setMinutes(inputDate1.getMinutes() - 31);
    const year1 = inputDate1.getFullYear();
    const month1 = String(inputDate1.getMonth() + 1).padStart(2, '0');
    const day1 = String(inputDate1.getDate()).padStart(2, '0');
    const hours1 = String(inputDate1.getHours()).padStart(2, '0');
    const minutes1 = String(inputDate1.getMinutes()).padStart(2, '0');
    const seconds1 = String(inputDate1.getSeconds()).padStart(2, '0');
    const formattedResult1 = `${year1}-${month1}-${day1} ${hours1}:${minutes1}:${seconds1}`;

    const formattedTimestamp2 = timestamp2
      .toISOString()
      .replace('T', ' ')
      .slice(0, 19);
    const inputDate2 = new Date(formattedTimestamp2);
    // Subtract 5 hours and 30 minutes
    inputDate2.setHours(inputDate2.getHours() - 5);
    inputDate2.setMinutes(inputDate2.getMinutes() - 30);
    const year2 = inputDate2.getFullYear();
    const month2 = String(inputDate2.getMonth() + 1).padStart(2, '0');
    const day2 = String(inputDate2.getDate()).padStart(2, '0');
    const hours2 = String(inputDate2.getHours()).padStart(2, '0');
    const minutes2 = String(inputDate2.getMinutes()).padStart(2, '0');
    const seconds2 = String(inputDate2.getSeconds()).padStart(2, '0');
    const formattedResult2 = `${year2}-${month2}-${day2} ${hours2}:${minutes2}:${seconds2}`;
    return [formattedResult1, formattedResult2];
  }

  function getRandomColor(docpName, code, alpha) {
    if (docpName === 'sites_id') {
      let hash = 0;
      for (let i = 0; i < code.length; i++) {
        hash = code.charCodeAt(i) + ((hash << 2) - hash);
      }

      // Use the hash code to determine the values for H, S, and L.
      const hue = ((hash % 360) + 360) % 360; // Ensure hue is between 0 and 359
      const saturation = 30 + (70 * (hash % 101)) / 100; // Set saturation between 25% and 95%
      const lightness = 65 + (25 * (hash % 101)) / 100; // Set lightness between 70% and 95%

      // Return the HSLA string with the specified alpha.
      return (
        'hsla(' +
        hue +
        ', ' +
        saturation +
        '%, ' +
        lightness +
        '%, ' +
        alpha +
        ')'
      );
    } else if (docpName === 'designation') {
      let hash = 0;
      for (let i = 0; i < code.length; i++) {
        hash = code.charCodeAt(i) + ((hash << 5) - hash);
      }

      // Use the hash code to determine the values for H, S, and L.
      const hue = ((hash % 360) + 360) % 360; // Ensure hue is between 0 and 359
      const saturation = 30 + (60 * (hash % 101)) / 100; // Set saturation between 25% and 95%
      const lightness = 67 + (10 * (hash % 101)) / 100; // Set lightness between 60% and 70%

      // Return the HSLA string with the specified alpha.
      return (
        'hsla(' +
        hue +
        ', ' +
        saturation +
        '%, ' +
        lightness +
        '%, ' +
        alpha +
        ')'
      );
    }
    return null;
  }

  function quickSort(array, sortBy) {
    if (array.length <= 1) {
      return array;
    }

    const pivotIndex = Math.floor(array.length / 2);
    const pivot = array.splice(pivotIndex, 1)[0];
    const left = [];
    const right = [];

    for (let i = 0; i < array.length; i++) {
      let comparison;
      if (sortBy === 'code') {
        comparison = array?.[i]?.['employees']?.[0]?.['code']
          .trim()
          .localeCompare(pivot['employees']?.[0]?.['code']?.trim());
      } else {
        comparison = array?.[i]?.['employees']?.[0]?.['persons_id']?.[0]?.['first_name']?.eng
          .trim()
          .localeCompare(
            pivot['employees']?.[0]?.['persons_id']?.[0]?.['first_name']?.eng?.trim()
          );
      }
      if (comparison === -1) {
        left.push(array[i]);
      } else {
        right.push(array[i]);
      }
    }

    return [...quickSort(left, sortBy), pivot, ...quickSort(right, sortBy)];
  }

  function getDisplayStringForHrs(hrs) {
    let result = '';
    hrs = Number(hrs);
    if (!isNaN(hrs)) {
      const hrsFloor = Math.floor(hrs);
      if (hrsFloor.toString().length < 2) {
        result += '0';
      }
      result += hrsFloor.toString() + ':';
      const decimal = hrs - hrsFloor;
      let mins = (decimal / 100) * 60;
      mins = mins.toString().split('.')[1] || '0';
      if (mins.toString().length < 2) {
        result += mins + '0';
      } else {
        let x = Number(mins.substr(0, 2) + '.' + mins.substr(2));
        x = Math.round(x);
        result += x.toString().length < 2 ? '0' + x : x;
      }
    } else {
      result = '00:00';
    }
    return result;
  }

  function formatHrsAndDays(val) {
    const retval = [];
    let result;
    if (val != null) {
      if (val.present > 0) {
        result = '';
        result = result + 'P:' + val.present;
        retval.push(result);
      }
      if (val.punchRequest > 0) {
        result = '';
        result = result + 'PR:' + val.punchRequest;
        retval.push(result);
      }
      if (val.partialAttendance > 0) {
        result = '';
        result = result + 'PA:' + val.partialAttendance;
        retval.push(result);
      }
      if (val.absent > 0) {
        result = '';
        result = result + 'A:' + val.absent;
        retval.push(result);
      }
      if (val.rest > 0) {
        result = '';
        result = result + 'R:' + val.rest;
        retval.push(result);
      }
      if (val.weeklyOff > 0) {
        result = '';
        result = result + 'WO:' + val.weeklyOff;
        retval.push(result);
      }
      if (val.holidays > 0) {
        result = '';
        result = result + 'H:' + val.holidays;
        retval.push(result);
      }
      if (val.leaves > 0) {
        result = '';
        result = result + 'L:' + val.leaves;
        retval.push(result);
      }
      if (val.leaveWithoutPay > 0) {
        result = '';
        result = result + 'LWP:' + val.leaveWithoutPay;
        retval.push(result);
      }
      if ('deficitHours' in val) {
        result = '';
        result = result + 'DH:' + convertMinutesToHours(val.deficitHours);
        retval.push(result);
      }
    }
    return retval;
  }

  function convertMinutesToHours(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const decimalHours = remainingMinutes / 60;
    const totalHours = hours + decimalHours;
    return totalHours;
  }

  function getTotalDays(data) {
    const totalHrsAndDays = {
      present: 0,
      absent: 0,
      partialAttendance: 0,
      punchRequest: 0,
      rest: 0,
      weeklyOff: 0,
      holidays: 0,
      leaves: 0,
      leaveWithoutPay: 0,
      deficitHours: 0
    };
    for (const prop in data) {
      if (prop !== 'employees') {
        if ('att_type' in data[prop]) {
          if (data[prop].att_type[0].code === 'P') {
            totalHrsAndDays.present += 1;
          } else if (data[prop].att_type[0].code === 'PA') {
            totalHrsAndDays.partialAttendance += 1;
          } else if (data[prop].att_type[0].code === 'PR') {
            if ('attendance_status' in data[prop]) {
              if (data[prop]['attendance_status']?.[0].code !== 'final_approved') {
                totalHrsAndDays.punchRequest += 1;
              }
            }
          } else if (data[prop].att_type[0].code === 'A') {
            totalHrsAndDays.absent += 1;
          } else if (data[prop].att_type[0].code === 'R') {
            totalHrsAndDays.rest += 1;
          } else if (data[prop].att_type[0].code === 'WO') {
            totalHrsAndDays.weeklyOff += 1;
          } else if (data[prop].att_type[0].code === 'H') {
            totalHrsAndDays.holidays += 1;
          } else if (data[prop].att_type[0].code === 'LWP') {
            totalHrsAndDays.leaveWithoutPay += 1;
          } else if (data[prop].att_type[0].code === 'HD') {
            totalHrsAndDays.leaves += 0.5;
            totalHrsAndDays.present += 0.5;
          } else {
            totalHrsAndDays.leaves += 1;
          }
        } else if ('deficit_minutes' in data[prop] && data[prop].deficit_minutes !== null) {
          totalHrsAndDays.deficitHours += data[prop].deficit_minutes;
        }
      }
    }
    return totalHrsAndDays;
  }

  function formatDays(currentDate, month, year, weekRange) {
    const monthAbbreviations = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const daysOfWeekAbbreviations = [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
    ];
    let lastDayOfMonth = null;
    const formattedDays = [];
    if (currentDate !== null && currentDate !== undefined && currentDate !== 1) {
      let prevYear = null;
      if (month === 1) {
        prevYear = year - 1;
      }
      let currentMonth = month - 1;
      lastDayOfMonth = new Date(prevYear !== null ? prevYear : year, currentMonth, 0).getDate();
      for (let day = currentDate; day <= lastDayOfMonth; day++) {
        currentMonth = month - 2;
        const currentDay = new Date(prevYear !== null ? prevYear : year, currentMonth !== -1 ? currentMonth : 11, day);
        const dayOfWeekAbbreviation =
          daysOfWeekAbbreviations[currentDay.getDay()];
        formattedDays.push({
          day: day,
          formattedDay: `${dayOfWeekAbbreviation} ${day}`,
          month: monthAbbreviations[currentMonth],
          year: prevYear !== null ? prevYear : year
        });
      }
      for (let day = 1; day <= currentDate - 1; day++) {
        currentMonth = month - 1;
        const currentDay = new Date(year, currentMonth, day);
        const dayOfWeekAbbreviation =
          daysOfWeekAbbreviations[currentDay.getDay()];
        formattedDays.push({
          day: day,
          formattedDay: `${dayOfWeekAbbreviation} ${day}`,
          month: monthAbbreviations[currentMonth],
          year: year
        });
      }
    } else if (currentDate !== null) {
      lastDayOfMonth = new Date(year, month, 0).getDate();
      for (let day = 1; day <= lastDayOfMonth; day++) {
        const currentDay = new Date(year, month - 1, day);
        const dayOfWeekAbbreviation =
          daysOfWeekAbbreviations[currentDay.getDay()];
        formattedDays.push({
          day: day,
          formattedDay: `${dayOfWeekAbbreviation} ${day}`,
          month: monthAbbreviations[month - 1],
          year: year
        });
      }
    } else {
      const startDay = weekRange.startOfWeek;
      const startMonth = weekRange.startMonth;
      const startYear = weekRange.startYear;
      const endDay = weekRange.endOfWeek;
      const endMonth = weekRange.endMonth;
      const endYear = weekRange.endYear;
      if (startMonth === endMonth) {
        lastDayOfMonth = endDay;
        for (let day = startDay; day <= lastDayOfMonth; day++) {
          const currentDay = new Date(startYear, startMonth, day);
          const dayOfWeekAbbreviation =
            daysOfWeekAbbreviations[currentDay.getDay()];
          formattedDays.push({
            day: day,
            formattedDay: `${dayOfWeekAbbreviation} ${day}`,
            month: monthAbbreviations[startMonth],
            year: startYear
          });
        }
      } else {
        lastDayOfMonth = new Date(startYear, startMonth + 1, 0).getDate()
        for (let day = startDay; day <= lastDayOfMonth; day++) {
          const currentDay = new Date(startYear, startMonth, day);
          const dayOfWeekAbbreviation =
            daysOfWeekAbbreviations[currentDay.getDay()];
          formattedDays.push({
            day: day,
            formattedDay: `${dayOfWeekAbbreviation} ${day}`,
            month: monthAbbreviations[startMonth],
            year: startYear
          });
        }
        lastDayOfMonth = endDay
        for (let day = 1; day <= lastDayOfMonth; day++) {
          const currentDay = new Date(endYear, endMonth, day);
          const dayOfWeekAbbreviation =
            daysOfWeekAbbreviations[currentDay.getDay()];
          formattedDays.push({
            day: day,
            formattedDay: `${dayOfWeekAbbreviation} ${day}`,
            month: monthAbbreviations[endMonth],
            year: endYear
          });
        }
      }
    }
    return formattedDays;
  }


  function convertUtcToIst(utcTimestamp) {
    if (utcTimestamp == null) {
      return '0:0:0';
    }
    // Convert the UTC timestamp to a Date object
    const utcDate = new Date(utcTimestamp);
    const ISTOffset = 330; // IST is UTC+5:30, so 330 minutes

    // Get the current timezone offset of the local system in minutes
    const localOffset = utcDate.getTimezoneOffset(); // Local system timezone offset in minutes

    // Calculate the time difference between UTC and IST
    const offsetDifference = ISTOffset - localOffset;
    // Convert the UTC date to IST by adding the offset
    const istDate = new Date(utcDate.getTime() + offsetDifference);
    // Extract hours, minutes, seconds
    let hours = istDate.getHours();  // Use getUTCHours to avoid local time zone interference
    const minutes = istDate.getMinutes();
    const seconds = istDate.getSeconds();
    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
    return formattedTime;
  }

  function convertUTCToISTDateTime(utcDateString) {
    const utcDate = new Date(utcDateString);
    const ISTOffset = 330; // IST is UTC+5:30, so 330 minutes
    const localOffset = utcDate.getTimezoneOffset(); // Local system timezone offset in minutes
    const offsetDifference = ISTOffset - localOffset;
    const istDate = new Date(utcDate.getTime() + offsetDifference);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = istDate.getDate();
    const month = monthNames[istDate.getMonth()];
    const year = istDate.getFullYear();
    let hours = istDate.getHours();
    const minutes = istDate.getMinutes().toString().padStart(2, '0');
    const seconds = istDate.getSeconds().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${period}`;
    return formattedDateTime;
  }

  function attendancePeriodColumns(timeRange, cellEditorDataSource, showSitesCellColor, showDesignationsCellColor, isEditedEnable, selectedCells, selectAll, showInOut) {
    let day = null;
    let month = null;
    let year = null;
    const today = new Date();
    month = today.getMonth();
    month++;
    year = today.getFullYear();
    if (timeRange !== null) {
      day = timeRange.day;
      month = timeRange.month;
      year = timeRange.year;
    }
    const formattedDays = formatDays(day, month, year);
    const result = [];
    for (let i = 0; i < formattedDays.length; i++) {
      const column = {
        headerName: `${formattedDays[i].formattedDay}`,
        field: `${formattedDays[i].day}${formattedDays[i].month}${formattedDays[i].year}`,
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: 'PinnedRowRenderer',
            };
          } else {
            return {
              component: 'AttendanceCellRenderer',
            };
          }
        },
        headerComponent: 'GridHeaderComponent',
        maxWidth: 65,
        cellEditor: 'cellEditor',
        cellEditorPopupPosition: 'over',
        cellEditorPopup: true,
        suppressMovable: true,
        tooltipValueGetter: (params) => {
          let tooltipText = null;
          if (params.value !== undefined && params.value !== null && params.value.exception !== null && params.value.exception !== undefined) {
            const { convertMinutesToHours } = getConverters();
            const exception = params.value.exception;
            const value = params.value;
            const labelMap = {
              att_type: 'Attendance Type',
              shift_code: 'Shift Code',
              working_minutes: 'Working Hrs',
              overtime_minutes: 'Overtime Hrs',
              observed_in_tmstmp: 'In Time',
              observed_out_tmstmp: 'Out Time',
            };
            tooltipText = [];
            for (let i = 0; i < exception.length; i++) {
              const tooltipLines = [];
              const prevValue = exception[i];
              const currValue = exception[i + 1];
              Object.keys(labelMap).forEach((key) => {
                if (key === 'att_type') {
                  if (currValue === undefined) {
                    if (value[key][0].id !== prevValue[key][0].id) {
                      tooltipLines.push(`${labelMap[key]}: ${prevValue[key][0].code} -> ${value[key][0].code}\n`)
                    }
                  } else if (currValue[key][0].id !== prevValue[key][0].id) {
                    tooltipLines.push(`${labelMap[key]}: ${prevValue[key][0].code} -> ${currValue[key][0].code}\n`)
                  }
                } else if (key === 'shift_code') {
                  if (currValue === undefined) {
                    if (value['shifts_id'][0][key] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${prevValue[key]} -> ${value['shifts_id'][0][key]}\n`)
                    }
                  } else if (currValue[key] !== prevValue[key]) {
                    tooltipLines.push(`${labelMap[key]}: ${prevValue[key]} -> ${currValue[key]}\n`)
                  }
                } else if (key === 'observed_in_tmstmp') {
                  if (currValue === undefined) {
                    if (value['in_time'] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(value['in_time'])}\n`)
                    }
                  } else if (currValue[key] !== prevValue[key]) {
                    tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(currValue[key])}\n`)
                  }
                } else if (key === 'observed_out_tmstmp') {
                  if (currValue === undefined) {
                    if (value['out_time'] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(value['out_time'])}\n`)
                    }
                  } else if (currValue[key] !== prevValue[key]) {
                    tooltipLines.push(`${labelMap[key]}: ${convertUtcToIst(prevValue[key])} -> ${convertUtcToIst(currValue[key])}\n`)
                  }
                } else {
                  if (currValue === undefined) {
                    if (prevValue[key] !== value[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertMinutesToHours(prevValue[key])} -> ${convertMinutesToHours(value[key])}\n`)
                    }
                  } else {
                    if (currValue[key] !== prevValue[key]) {
                      tooltipLines.push(`${labelMap[key]}: ${convertMinutesToHours(prevValue[key])} -> ${convertMinutesToHours(currValue[key])}\n`)
                    }
                  }
                }
              })
              if (tooltipLines.length > 0) {
                tooltipLines.unshift(`${tooltipText.length + 1}: ${exception[i].description}\nEdit date time:  ${convertUTCToISTDateTime(exception[i].edit_timestamp)} \n`);
                tooltipLines.push('\n');
                tooltipText.push(tooltipLines.join(''));
              } else {
                tooltipLines.unshift(`${tooltipText.length + 1}: ${exception[i].description}\nEdit date time:  ${convertUTCToISTDateTime(exception[i].edit_timestamp)} \n`);
                tooltipLines.push('\n');
                tooltipText.push(tooltipLines.join(''));
              }
            }
            if (params.value['att_type'] !== undefined) {
              if (params.value.att_type[0].code === 'PA') {
                if (params.value.out_time !== null) {
                  tooltipText.unshift(`Out Time : ${convertUtcToIst(params.value.out_time)}\n`)
                }
                if (params.value.in_time !== null) {
                  tooltipText.unshift(`In Time : ${convertUtcToIst(params.value.in_time)}\n`)
                }
              }
            }
            tooltipText = tooltipText.length > 0 ? tooltipText.join('') : null;
          }
          if (params.value !== null && params.value !== undefined && tooltipText === null) {
            tooltipText = [];
            if (params.value['att_type'] !== undefined) {
              if (params.value.att_type[0].code === 'PA') {
                if (params.value.in_time !== null) {
                  tooltipText.push(`In Time : ${convertUtcToIst(params.value.in_time)}\n`)
                }
                if (params.value.out_time !== null) {
                  tooltipText.push(`Out Time : ${convertUtcToIst(params.value.out_time)}\n`)
                }
              }
            }
            tooltipText = tooltipText.length > 0 ? tooltipText.join('') : null;
          }
          return tooltipText;
        },
        editable: (params) => {
          if (params.node.rowPinned) {
            return false;
          }
          return true;
        },
        cellRendererParams: {
          'showSitesCellColor':showSitesCellColor,
          'showDesignationsCellColor':showDesignationsCellColor,
          'cellsSelected': selectedCells,
          'selectAllCells': selectAll,
          'showInOut': showInOut,
        },
        cellEditorParams: {
          'cellEditorDataSource':cellEditorDataSource,
          'isEditedEnable':isEditedEnable
        },
        enableCellChangeFlash: true,
        onCellValueChanged: attendanceCellValueChanged(),
        cellStyle: (params) => {
          return { fontSize: '14px' };
        },
      };
      result.push(column);
    }
    return result;
  }

  // Helper function to get the parent cell element
  function getParentCellElement(target) {
    let parent = target;
    while (parent && !parent.classList.contains('ag-cell')) {
      parent = parent.parentElement;
    }
    return parent;
  }

  async function selectedCellsSetter(store, value, selectedCells, selectAll) {
    let cellsSelected = selectedCells;
    let selectAllCells = selectAll;
    const { data, rowPinned, column, event, api, node } = value;
    const colId = column.colId;
    const employee = data['employees'];
    let newCell = false;

    // Handle Ctrl+A (select all)
    if (event.ctrlKey && (event.keyCode === 65 || event.keyCode === 97)) {
      const selectedNodes = api.getSelectedNodes();
      const cellRendererInstances = api.getCellRendererInstances();
      const isSelectAll = selectAllCells;

      cellRendererInstances.forEach((instance) => {
        const { eGridCell, column, node } = instance.params;
        if (!['employees', 'total'].includes(column.colId) && node.rowPinned === undefined) {
          eGridCell.classList.toggle('ag-row-selected', !isSelectAll);
        }
      });

      cellsSelected = isSelectAll ? [] : selectedNodes.map((node) => {
        const clone = JSON.parse(JSON.stringify(node.data));
        delete clone.employees;
        return { [node.data.employees[0].id]: clone };
      });

      selectAllCells = !isSelectAll;
    } else if (colId === 'employees') {
      const isSelected = node.isSelected();
      const cellRendererInstances = api.getCellRendererInstances({ column: api.getColumnDefs(), rowNodes: [node] });

      cellRendererInstances.forEach((instance) => {
        const { eGridCell, column } = instance.params;
        if (!['employees', 'total'].includes(column.colId)) {
          eGridCell.classList.toggle('ag-row-selected', isSelected);
        }
      });

      if (isSelected) {
        const employeeId = employee[0].id;
        cellsSelected = cellsSelected.filter((obj) => Object.keys(obj)[0] !== employeeId);

        const selectedData = Object.keys(data).reduce((acc, key) => {
          if (key !== 'employees') {
            acc[key] = data[key];
          }
          return acc;
        }, {});

        if (Object.keys(selectedData).length > 0) {
          cellsSelected.push({ [employeeId]: selectedData });
          newCell = true;
        }
      } else {
        cellsSelected = cellsSelected.filter((obj) => Object.keys(obj)[0] !== employee[0].id);
        selectAllCells = false;
      }
    } else if (rowPinned === undefined) {
      const employeeId = employee[0].id;
      const selectedCell = data[colId];

      const existingEntry = cellsSelected.find((obj) => Object.keys(obj)[0] === employeeId);
      if (existingEntry) {
        if (existingEntry[employeeId][colId] === undefined) {
          existingEntry[employeeId][colId] = selectedCell;
          newCell = true;
        } else {
          delete existingEntry[employeeId][colId];
          newCell = false;
        }
      } else {
        newCell = true;
        cellsSelected.push({ [employeeId]: { [colId]: selectedCell } });
      }

      if (data[colId]?.['att_type']?.[0]?.code !== 'P' && data[colId]?.['att_type']?.[0]?.code !== 'PA' && data[colId]?.['att_type']?.[0]?.code !== 'PR' && data[colId]?.['att_type']?.[0]?.code !== 'P' && data[colId]?.['att_type']?.[0]?.code !== 'R' && data[colId]?.['att_type']?.[0]?.code !== 'A' && data[colId]?.['att_type']?.[0]?.code !== 'WO') {
        if (newCell) {
          const colIds = [];
          for (const key in data) {
            if (key !== 'employees' && key !== colId) {
              colIds.push(key)
            }
          }
          getAdjacentDays(colId, colIds)
        }
      }

      const parentCell = getParentCellElement(event.target);
      if (parentCell) {
        parentCell.classList.toggle('ag-row-selected', newCell);
        if (!newCell) selectAllCells = false;
      }
    }

    // Filter out empty objects
    cellsSelected = cellsSelected.filter((item) => {
      return Object.values(item).some((obj) => Object.keys(obj).length > 0);
    });

    return { cellsSelected, selectAllCells };
  }

  function getAdjacentDays(dateString, dateArray) {
    console.log('I am here key', dateString, dateArray)
  }

  async function makeParamsToSend(
    txn,
    bulkTxnName,
    currentFolder,
    bSettings,
    selectedRows
  ) {
    const sessionId = store.getters['sessionIdGetter'];
    const dataToChange = [];
    let docpSelected = [];
    const txnName = bulkTxnName;
    let propertyName = null;
    let docpSource = null;
    const { changeWorkingFolder } = foldersUtility();
    let docpSourceFolder = null;
    propertyName = 'attendance_status';
    docpSource = currentFolder.fields[propertyName]?.source;
    docpSourceFolder = changeWorkingFolder(
      docpSource,
      bSettings?.output.data.records[0]
    );
    //getting the docpId
    const { generateFilterParams } = makeFilter(txnName, docpSourceFolder);
    const filterParams = generateFilterParams();
    const txnParams = {
      filters: filterParams,
      getRowCount: true,
      refRows: true,
    };
    const { listData } = postData();
    const list = await listData(docpSourceFolder, txnParams);
    if (list.output.type === 'error') {
      console.log(list.output.message);
    } else {
      if (list.output.data.records.length > 0) {
        const { fatRowGenerator } = getFatRow();
        docpSelected = fatRowGenerator(list);
      }
    }
    //loop thru each selected row to create formData
    for (let i = 0; i < selectedRows.length; i++) {
      const key = Object.keys(selectedRows[i]);
      for (const obj in selectedRows[i][key[0]]) {
        dataToChange.push({
          oldDocpId: selectedRows[i][key[0]][obj].attendance_status[0].id,
          newId: docpSelected[0].id,
          txnOp: 'U',
          mainFolderId: selectedRows[i][key[0]][obj].id,
          propertyName: propertyName,
        });
      }
    }
    const formData = dataToChange;
    //generate the txnToRun with txn and fieldsData
    const { generateTxn } = makeTxnForBulk(
      formData,
      currentFolder,
      txn,
      txnName
    );
    const txnToRun = generateTxn();
    txnToRun.session_key = sessionId;
    txnToRun.foldertype = 'mviews';
    const response = await sendMessage(txnToRun);
    return response;
  }

  async function approveAttendance(store, gridApi, selectedCells) {
    let approveNotification = null;
    let selectedRows = selectedCells;
    let approvedCells = 0;
    let isPA = false;
    for (let i = 0; i < selectedRows.length; i++) {
      const key = Object.keys(selectedRows[i]);
      for (const obj in selectedRows[i][key[0]]) {
        if (
          selectedRows[i][key[0]][obj].attendance_status[0].code === 'final_approved'
        ) {
          approvedCells++;
          delete selectedRows[i][key[0]][obj];
        } else if (selectedRows[i][key[0]][obj]?.att_type?.[0]?.code === 'PA') {
          isPA = true;
          delete selectedRows[i][key[0]][obj];
        }
      }
    }
    for (let i = 0; i < selectedRows.length; i++) {
      for (const obj in selectedRows[i]) {
        if (Object.keys(selectedRows[i][obj]).length === 0) {
          delete selectedRows[i][obj];
        }
      }
    }
    selectedRows = selectedRows.filter((row) => Object.keys(row).length !== 0);
    if (isPA) {
      toast.warning('Partial attendance cannot be approved', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (approvedCells > 0) {
      toast.warning(`${approvedCells} attendance cells are already approved`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (selectedRows.length > 0) {
      approveNotification = toast.loading('Approving attendance...', {
        position: toast.POSITION.TOP_CENTER,
      });
      const bSettings = store.getters['bSettings'];
      const txn = JSON.parse(
        JSON.stringify(
          bSettings.output.data.records[0].business.containers.mviews.folders
            .attendance.txns.txn_attendance_approve
        )
      );
      const bulkTxnName = 'approve';
      const currentFolder =
        bSettings.output.data.records[0].business.containers.mviews.folders
          .attendance;
      const response = await makeParamsToSend(
        txn,
        bulkTxnName,
        currentFolder,
        bSettings,
        selectedRows
      );
      if (response.output.type === 'error') {
        toast.update(approveNotification, {
          render: response.output.message,
          autoClose: 4000,
          closeButton: true,
          type: 'error',
          isLoading: false,
        });
      } else {
        if (response.output.type === 'success') {
          toast.update(approveNotification, {
            render: 'attendance cells approved',
            autoClose: 4000,
            closeButton: true,
            type: 'success',
            isLoading: false,
          });
        }
        selectedRows = [];
        const selected = gridApi.getSelectedNodes();
        for (let i = 0; i < selected.length; i++) {
          selected[i].setSelected(false);
        }
        gridApi.refreshInfiniteCache();
      }
    }
    return selectedRows;
  }

  async function rejectAttendance(store, gridApi, selectedCells) {
    let rejectNotification = null;
    let selectedRows = selectedCells;
    let rejectedCells = 0;
    let isPA = false;
    for (let i = 0; i < selectedRows.length; i++) {
      const key = Object.keys(selectedRows[i]);
      for (const obj in selectedRows[i][key[0]]) {
        if (
          selectedRows[i][key[0]][obj].attendance_status[0].code === 'rejected'
        ) {
          rejectedCells++;
          delete selectedRows[i][key[0]][obj];
        } else if (selectedRows[i][key[0]][obj]?.att_type?.[0]?.code === 'PA') {
          isPA = true;
          delete selectedRows[i][key[0]][obj];
        }
      }
    }
    if (isPA) {
      toast.warning('Partial attendance cannot be rejected', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (rejectedCells > 0) {
      toast.warning(`${rejectedCells} attendance cells are already rejected`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    for (let i = 0; i < selectedRows.length; i++) {
      for (const obj in selectedRows[i]) {
        if (Object.keys(selectedRows[i][obj]).length === 0) {
          delete selectedRows[i][obj];
        }
      }
    }
    selectedRows = selectedRows.filter((row) => Object.keys(row).length !== 0);
    if (selectedRows.length > 0) {
      rejectNotification = toast.loading('Rejecting attendance...', {
        position: toast.POSITION.TOP_CENTER,
      });
      const bSettings = store.getters['bSettings'];
      const txn = JSON.parse(
        JSON.stringify(
          bSettings.output.data.records[0].business.containers.mviews.folders
            .attendance.txns.txn_attendance_reject
        )
      );
      const bulkTxnName = 'reject';
      const currentFolder =
        bSettings.output.data.records[0].business.containers.mviews.folders
          .attendance;
      const response = await makeParamsToSend(
        txn,
        bulkTxnName,
        currentFolder,
        bSettings,
        selectedRows
      );
      if (response.output.type === 'error') {
        toast.update(rejectNotification, {
          render: response.output.message,
          autoClose: 1000,
          closeButton: true,
          type: 'error',
          isLoading: false,
        });
      } else {
        if (response.output.type === 'success') {
          toast.update(rejectNotification, {
            render: 'attendance cells rejected',
            autoClose: 1000,
            closeButton: true,
            type: 'success',
            isLoading: false,
          });
        }
        selectedRows = [];
        const selected = gridApi.getSelectedNodes();
        for (let i = 0; i < selected.length; i++) {
          selected[i].setSelected(false);
        }
        gridApi.refreshInfiniteCache();
      }
    }
    return selectedRows;
  }

  async function save(store) {
    const list = [];
    let saveNotification = null;
    const txnList = store.getters['agGridModule/gridTxnsGetter'];
    if (txnList.length > 0) {
      saveNotification = toast.loading('Updating attendance...', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    for (let i = 0; i < txnList.length; i++) {
      list.push(await sendMessage(txnList[i]));
      if (list[i].output.type === 'success') {
        toast.update(saveNotification, {
          render: 'attendance marked',
          autoClose: 4000,
          closeButton: true,
          type: 'success',
          isLoading: false,
        });
        // gridApi.refreshInfiniteCache()
      } else if (list[i].output.type === 'error') {
        toast.error(list[i].output.message);
        toast.update(saveNotification, {
          render: list[i].output.message,
          autoClose: 4000,
          closeButton: true,
          type: 'error',
          isLoading: false,
        });
      }
    }
    store.commit('agGridModule/gridTxnsMutations', []);
  }

  function getAttendanceDate(params) {
    const str = params.colDef.field;
    const dateRegex = /(\d{1,2})([A-Za-z]{3})(\d{4})/;

    // Extract the parts of the date using the regex
    const matches = str.match(dateRegex);
    let month = null;
    let day = null;
    let attYear = null;

    if (matches) {
      day = matches[1];
      const monthAbbr = matches[2];
      attYear = matches[3];

      const monthMap = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12
      };

      month = monthMap[monthAbbr];
    } else {
      console.log('Invalid date format');
    }
    const dateObject = new Date(Date.UTC(attYear, month - 1, day, 0, 0, 0)); // Month is 0-based in JavaScript
    const dateString = dateObject.toISOString().replace('T', ' ').slice(0, 19);
    const inputDate = new Date(dateString);

    // Subtract 5 hours and 30 minutes
    inputDate.setHours(inputDate.getHours() - 5);
    inputDate.setMinutes(inputDate.getMinutes() - 30);
    const year = inputDate.getFullYear();
    const month1 = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day1 = String(inputDate.getDate()).padStart(2, '0');
    const hours = String(inputDate.getHours()).padStart(2, '0');
    const minutes = String(inputDate.getMinutes()).padStart(2, '0');
    const seconds = String(inputDate.getSeconds()).padStart(2, '0');

    // Format the output
    const formattedResult = `${year}-${month1}-${day1} ${hours}:${minutes}:${seconds}`;

    // const formattedResult = inputDate.toISOString().replace("T", " ").slice(0, -5);
    return formattedResult;
    // const uuid = uuidv4();
  }

  function txnDataSetter(value) {
    const txnData = {};
    const editData = value;
    const empId = editData['employees_id'][0].id;
    txnData[empId] = [];
    const edits = {};
    const attendanceKeys = Object.keys(editData).filter((key) => key);
    attendanceKeys.forEach((key) => {
      const value = editData[key];
      const item = value;
      if (Array.isArray(item)) {
        item[0]['type'] =  'docpicker';
        item[0]['oldDocpId'] = null;
        item[0]['docpNotSet'] = true;
        item[0]['detailType'] = false;
        item[0]['folderId'] = null;
        item[0]['docpType'] = true;
      }
      const isDateOrTimestamp = ['attendance_date', 'sync_timestamp', 'in_timestamp', 'out_timestamp'].includes(key);
      edits[key] = {
        path: 'attendance_edits.' + key,
        value: item,
        mandatory: false,
        ...(isDateOrTimestamp && {
          patchPath: isDateOrTimestamp ? 'business.folders.attendance_edits' : null
        })
      };
    });
    txnData[empId].push(edits);
    return txnData;
  }

  function attendanceCellValueChanged(context) {
    return async(params) => {
      if (!params?.newValue?.editing) {
        const attendance_edit = params.newValue.attendance_edit;
        const txnData = txnDataSetter(attendance_edit);
        await generateTxn(txnData);
      }
    };
  }

  async function generateTxn(txnData) {
    if (Object.keys(txnData).length !== 0) {
      const bSettings = store.getters['bSettings'];
      const { getAllFoldersList, getCurrentFolder } = getFolders();
      let txns = {};
      const folderList = getAllFoldersList(bSettings);
      const currentFolder = getCurrentFolder('attendance_edits', folderList);
      const sessionId = store.getters['sessionIdGetter'];
      for (const key in txnData) {
        for (let i = 0; i < txnData[key].length; i++) {
          const formData = txnData[key][i];
          const { getGroupedFormData } = processFormData();
          const {
            normalFieldList,
            docpList,
            detailList,
            detailKeyFieldName,
          } = getGroupedFormData(formData, currentFolder, undefined);
          //generate the txnToRun with txn and fieldsData
          const params = {
            normalFieldList: normalFieldList,
            docpList: docpList,
            detailList: detailList,
            folderDetails: currentFolder,
            currentTaskName: 'create',
            detailKeyName: detailKeyFieldName,
            txnType: undefined,
            currentFolderId: undefined,
            txn: currentFolder.txns.txn_attendance_edits_create,
          };
          const { generateTxn } = makeTxn(params);
          const txnToRun = generateTxn();
          txnToRun.session_key = sessionId;
          //run the txn
          txns = txnToRun;
        }
      }
      await store.dispatch('agGridModule/gridTxnsSetter', JSON.parse(JSON.stringify(txns)));
    }
  }

  async function getDocpickerList(
    docpOptions,
  ) {
    try {
      let cellEditorDataSource = {};
      const bSettings = store.getters['bSettings'];
      const { getAllFoldersList, getCurrentFolder } = getFolders();
      const folderList = getAllFoldersList(bSettings);
      let currentDocpFolder = [];
      let txnParams = null;
      const filterParams = null;
      let folderName = null;
      if (!docpOptions.source?.folder) {
        folderName = docpOptions.name;
      } else {
        folderName = docpOptions.source.folder;
      }
      currentDocpFolder = getCurrentFolder(folderName, folderList);
      if (currentDocpFolder) {
        if (txnParams !== null) {
          txnParams.filters = filterParams;
        } else {
          txnParams = { refRows: true };
        }
        const { listData } = postData();
        const docpList = await listData(currentDocpFolder, txnParams);
        if (docpList.output.type === 'error') {
          console.log(docpList.output.message);
        } else {
          const { fatRowGenerator } = getFatRow();
          const fatRow = fatRowGenerator(docpList);
          const docPObj = cellEditorDataSource;
          const key = docpOptions.name;
          docPObj[key] = fatRow;
          cellEditorDataSource = docPObj;
        }
      }
      return cellEditorDataSource
    }
    catch (e) {
      console.log(e);
    }
  }

  async function getAOandRoList(reportingMangerList) {
    const reportingMangerOptions = [];
    const filterInput = [];

    filterInput.push({
      operator: '=',
      path: 'employees.groups_id.code',
      value: 'RO'
    })
    const txnParams = {
      filters: {
        joinop: 'or',
      },
      getRowCount: true,
      refRows: true,
    }
    txnParams.filters['filterInput'] = filterInput;
    const bSettings = store.getters['bSettings'];
    const empFolder = bSettings?.output?.data?.records[0]?.business?.containers?.folders?.folders?.employees;
    const { listData } = postData();
    const empList = await listData(empFolder, txnParams);
    if (empList.output.type === 'error') {
      console.log(empList.output.message);
    } else {
      const { fatRowGenerator } = getFatRow();
      const fatRow = fatRowGenerator(empList);
      for (let i = 0; i < fatRow.length; i++) {
        reportingMangerOptions.push({
          name: fatRow[i]?.persons_id[0]?.first_name?.eng,
          id: fatRow[i].id,
        });
      }
    }
    const list = {
      'reportingMangerList': reportingMangerOptions
    }
    return list;
  }

  //attendance grid calendar utils

  function eventClassNames(arg) {
    if (arg.event.title === 'Absent') {
      return ['event_color_absent']
    } else if (arg.event.title === 'Present/OT') {
      return ['event_color_present']
    } else if (arg.event.title === 'Holiday') {
      return ['event_color_holiday']
    }
    return 'event_color'
  }

  function refBy(currEmpAttData, formFolder) {
    const ret = {
      label: 'Apply for leaves',
      component: 'FolderForm',
      name:'Apply leaves',
      params : propsheetBindings(currEmpAttData, formFolder)
    }
    // txnToUse :txnToUse.value,
    // rowNode :currentRowNode.value,
    // agGridApi:gridApi.value
    return [ret];
  }

  function propsheetBindings(currEmpAttData, formFolder) {
    const { getCurrentTxn } = getFormInfo()
    const displayFields = formFolder.forms.leaves_create_form;
    const txnToUse =  getCurrentTxn('create', formFolder)

    const ret = {
      rowData : null,
      filledData:{
        employees_id : JSON.stringify(currEmpAttData.value?.employees?.[0]?.code)
      },
      fieldsToDisplay : displayFields,
      formObjectFound : true,
      folder : formFolder,
      label : 'Leave Application',
      refBy : [],
      dateFieldKey: 'leave_period',
      makeParams: true,
      readOnly :false,
      currentTask:'create',
      txnToUse:txnToUse,
      customClass:'h-auto'
    }
    return ret
  }

  function events(currEmpAttData) {
    const { getUtcToIstString } = getConverters();
    const ret = []
    for (const key in currEmpAttData.value) {
      if (key !== 'employees') {
        const istDateString = getUtcToIstString(currEmpAttData.value[key].attendance_date);
        const currTitle = currEmpAttData.value[key]['att_type'][0]['name']['eng'];
        const inputDate = istDateString;
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
        const day = String(date.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        ret.push({
          title: currTitle,
          start: formattedDate,
          end: formattedDate,
          display: 'background',
          extendedProps: {
            currAttData: currEmpAttData.value[key]
          }
        })
      }
    }
    return ret;
  }

  function eventContent(args) {
    const { convertMinutesToHours } = getConverters();
    const att_status = {};
    const currAttData = args.event.extendedProps.currAttData;
    att_status['attendance_type'] = 'att_type' in currAttData ? currAttData['att_type']?.[0]['name']['eng'] : null;
    att_status['shift'] = 'shifts_id' in currAttData ? currAttData['shifts_id']?.[0]['name'] : null;
    att_status['overtime_hrs'] = convertMinutesToHours(currAttData['overtime_minutes']);
    att_status['working_hrs'] = convertMinutesToHours(currAttData['working_minutes']);
    att_status['designation'] = 'designation' in currAttData ? currAttData['designation']?.[0].name : null;
    const spanEl = document.createElement('div');
    spanEl.innerHTML += `<div class="ag-container">
        <div class="ag-status">
          <span class="shift">${att_status['shift']}</span>
        </div>
        <div class="att-status">
          <span class="attendance">${att_status['attendance_type']}</span>
        </div>
        <div class="ag-status">
          <span class="hours">W Hr: ${att_status['working_hrs']} | OT Hr: ${att_status['overtime_hrs']}</span>
        </div>
        <div
        class="ag-status"
      >
        <span>${att_status['designation']}</span>
      </div>
      </div>
    </div>`
    const arrayOfDomNodes = [ spanEl ]
    return { domNodes: arrayOfDomNodes }
  }

  return {
    createTimeRange,
    getRandomColor,
    selectedCellsSetter,
    approveAttendance,
    rejectAttendance,
    save,
    formatDays,
    convertUtcToIst,
    txnDataSetter,
    attendanceCellValueChanged,
    generateTxn,
    attendancePeriodColumns,
    getTotalDays,
    formatHrsAndDays,
    convertMinutesToHours,
    getDisplayStringForHrs,
    quickSort,
    getAttendanceDate,
    getDocpickerList,
    getAOandRoList,
    //calendar methods
    eventClassNames,
    refBy,
    events,
    eventContent,
  };
};

export default attendanceGridUtils;
