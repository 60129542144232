<template>
  <div class="settings-view">
    <div class="header-container">
      <span class="header-span">Settings</span>
    </div>
    <div class="folder-container">
      <WfmListIcons
        :items-list="settingsList"
        :get-value="getValue"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import WfmListIcons from '../../common/wfm-list-icons.vue';
export default {
  name: 'HomePage',
  components: {
    WfmListIcons
  },
  setup() {
    // Variables
    const router = useRouter();
    const i18n = useI18n();
    const currentLanguage = ref(i18n.locale.value);

    // Computed
    const settingsList = computed(() => [
      {
        name : 'profile',
        label : { eng : 'User Profile'},
        foldertype : 'settings'
      },
      {
        name : 'workflow',
        label : { eng : 'Workflow' },
        foldertype : 'settings'
      }
    ]);
    // Methods
    const getValue = async(setting) => {
      try {
        if (setting.name == 'profile') {
          router.push({ name: 'profile' });
        }
        else if (setting.name == 'workflow') {
          router.push({ name: 'workflow' });
        }
      } catch (err) {
        toast.error(err);
      }
    };

    return {
      getValue,
      settingsList,
      currentLanguage
    };
  },
};
</script>

<style scoped>
.settings-view {
  display: inline-block;
  align-items: center;
}

.header-container {
  height: 4rem;
  padding: 1rem;
  margin-top: 1.2rem;
}

.header-span {
  color: #292929;
  font-size: 1.2rem;
}

.folder-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: calc(11rem * 5 + 8rem); /* Adjust according to your card width */
  margin: 0rem auto;
  height: 30rem;
  margin-top: 1.2rem;
}

</style>
