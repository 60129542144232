const getConverters = () => {
  const tzmap = {
    UTC: 'Z',
    IST: '+05:30'
  }

  function utcToIst(utcTimestamp) {
    let date = new Date(utcTimestamp)
    date = new Date(date.setMinutes(date.getMinutes() + 330))
    const options = {
      hour12: false,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    }
    const dateTimeString = date.toLocaleString('en-US', options)
    const finalDateTimeString = dateTimeString.split('at').join('')
    return finalDateTimeString
  }
  function getUtcToIstString(utcTimeStamp) {
    const utcTimestamp = utcTimeStamp;
    const date = new Date(utcTimestamp);
    // Get IST offset in milliseconds (UTC+5:30)
    const istOffset = 5.5 * 60 * 60 * 1000;
    // Convert UTC to IST
    const istDate = new Date(date.getTime() + istOffset);
    // Format IST timestamp
    const istTimestamp = istDate.toISOString().replace('Z', '');
    return istTimestamp;
  }
  function storedRangeToUserFriendly(storedRange) {
    let startDate, endDate
    if (storedRange) {
      const array = []
      // convert the value to show to user
      array.push(
        JSON.parse(storedRange.replace(/\(/g, '[').replace(/\)/g, ']'))
      )

      array.map((eachValue) => {
        // const startDay = "Today"
        // let endDay = "Today"
        const startMin = eachValue[0]
        let endMin = eachValue[1]
        if (endMin > 1440) {
          // endDay = "next Day"
          endMin = endMin - 1440
        }
        const endTimeHour = Math.trunc(endMin / 60)
        const endTimeMin = endMin % 60
        const startTimeHour = Math.trunc(startMin / 60)
        const startTimeMin = startMin % 60
        startDate = `${startTimeHour}:${startTimeMin}`
        endDate = `${endTimeHour}:${endTimeMin}`
      })
      const userFriendlyRange = [startDate, endDate]
      return userFriendlyRange
    } else {
      return null
    }
  }
  function userFriendlyToStoredRange(timeRange) {
    const startRange = timeRange[0]
    const endRange = timeRange[1]

    const startRangeInMinutes = startRange.hours * 60 + startRange.minutes
    let endRangeInMinutes = endRange.hours * 60 + endRange.minutes
    if (startRangeInMinutes > endRangeInMinutes) {
      endRangeInMinutes = endRangeInMinutes + 1440
    }
    const rangeToStore = `[${startRangeInMinutes},${endRangeInMinutes}]`

    return rangeToStore
  }


  function tsRangeStoredToUserFriendly(rangeStr) {
    try {
      let retval = [];
      if (rangeStr != null) {
        const range = JSON.parse(rangeStr);
        retval = range.map((dtstr) => {
          const dt = new Date(dtstr);
          return dt.toLocaleDateString('en-GB')
        })
      }
      return retval;
    }
    catch (err) {
      throw err;
    }
  }
  // //used to make utc timeString with timezone
  // function padZero(num) {
  //   return (num < 10 ? "0" : "") + num
  // }
  function timeStampStoredToUserFriendly(timeStamp) {
    if (timeStamp) {
      const istTimeStamp = new Date(timeStamp)
      const timeStampDisplay = istTimeStamp.toLocaleDateString('en-gb', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      })
      return timeStampDisplay
    }
  }
  function tsRangeIstToUtc(range, snap) {
    if (snap == null) {
      snap = true;
    }
    const retVal = []
    for (var i = 0; i < range.length; i++) {
      let dt = range[i];
      if (snap) {
        dt = snapDate(dt);
      }
      retVal.push(dt.toISOString())
    }
    return retVal
  }

  function storedRangeToDatePickerFormat(tsRange) {
    if (tsRange) {
      // convert the value to show to user
      const tsRangeArray = JSON.parse('[' + tsRange + ']')
      for (var i = 0; i < tsRangeArray?.length; i++) {
        return tsRangeArray[i].map((each) => {
          const hours = Math.trunc(each / 60)
          const minutes = each % 60
          return { hours: hours, minutes: minutes, seconds: 0 }
        })
      }
    } else {
      return null
    }
  }
  function monthPickerToUtc(dateInput) {
    //dateInput gets value from both monthpicker and year picker
    const year = dateInput?.year || dateInput
    const month = dateInput?.month || '00'
    const day = '01'
    const date = new Date(year, month, day, '05', '30')
    let utcDate = null
    if (date != 'Invalid Date') {
      utcDate = date.toISOString()
    }
    return utcDate
  }

  // utilities to work with fullCalendar control selection Info

  function dateFormatter(date, format) {
    const dt = date.getDate()
    let mt = date.getMonth()
    const yr = date.getFullYear()

    let result = `${yr}-${mt++}-${dt}`
    if (format.toUpperCase() === 'DD-MM-YYYY') {
      result = `${dt}-${mt++}-${yr}`
    }
    else if (format.toUpperCase() === 'DD/MM/YYYY') {
      result = `${dt}/${mt++}/${yr}`
    }

    return result
  }

  function exclusiveDayRangeDisplayStr(selectionInfo, format) {
    const start = new Date(selectionInfo.start)
    const end = new Date(selectionInfo.end)
    // adjust the end to chop off the excluded day
    // else users get confused
    const adjustedEnd = new Date(end.setDate(end.getDate() - 1))
    return `[${dateFormatter(start, format)} to ${dateFormatter(adjustedEnd, format)}]`
  }

  function emptyDayRangeDisplayStr(format) {
    const today = new Date() // just a placeholder
    let result = exclusiveDayRangeDisplayStr({start: today, end: today}, format)
    result = result.replace(/\d/g, '_')
    result = result.replace(/\//g, '_')
    return result
  }

  function addHours(dt, hrs) {
    try {
      // Check if dt is date object. If now throw error
      dt.setTime(dt.getTime() + (hrs * 60 * 60 * 1000))
      return dt;
    }
    catch (err) {
      throw err;
    }
  }

  function snapDate(dt) {
    try {
      return dt.setHours(0, 0, 0, 0);
    }
    catch (err) {
      throw err;
    }
  }

  function arrayToSQLRange(range, snap, excludeStart, excludeEnd) {
    try {
      let retval = '';
      if (snap == null) {
        snap = false;
      }

      if (excludeStart == null) {
        excludeStart = false;
      }

      if (excludeEnd == null) {
        excludeEnd = true;
      }

      if (Array.isArray(range)) {
        if (snap) {
          range.forEach((dt) => {
            snapDate(dt)
          })
        }

        if (excludeStart) {
          range[0] = addHours(range[0], -24)
        }

        if (excludeEnd) {
          range[1] = addHours(range[1], 24)
        }

        retval = `${excludeStart ? '(' : '['}"${range[0].toISOString()}", "${range[1].toISOString()}"${excludeEnd ? ')' : ']'}`
      }

      return retval;
    }
    catch (err) {
      throw err;
    }

  }

  function SQLTsRangeToArray(rangeStr, timezone, snap) {
    try {
      if (snap == null) {
        snap = false;
      }

      if (timezone == null) {
        timezone = 'UTC';
      }

      if (rangeStr !== null) {
        const tzstr = tzmap[timezone];
        const range = rangeStr.trim();
        const excludeEndDate = (range[range.length - 1] === ')') ? true : false;
        const excludeStartDate = (range[0] === '(') ? true : false;

        const rangeJSON = range.replaceAll(')', ']').replaceAll('(', '[');
        const retval = JSON.parse(rangeJSON).map((dtStr) => {
          let dstr = dtStr;
          if (!(dtStr.toUpperCase().includes('T'))) {
            dstr = `${dtStr.replace(' ', 'T')}${tzstr}`
          }
          return new Date(dstr)
        });

        if (snap) {
          retval.map((dt) => {
            dt = snapDate(dt)
          })
        }

        if (excludeStartDate) {
          retval[0] = addHours(retval[0], 24)
        }

        if (excludeEndDate) {
          retval[1] = addHours(retval[1], -24)
        }
        return retval;
      }
    }
    catch (err) {
      throw err;
    }
  }

  function compareDates(dateStr1, dateStr2) {
    const parseDate1 = (dateStr) => {
      const [day, month, year] = dateStr.match(/(\d+)([a-zA-Z]+)(\d{4})/).slice(1);
      return new Date(`${month} ${day}, ${year}`);
    };

    const parseDate2 = (dateStr) => new Date(dateStr);

    const date1 = parseDate1(dateStr1);
    const date2 = parseDate2(dateStr2);

    // Compare the dates
    if ((date1.getDate() === date2.getDate()) && (date1.getMonth() === date2.getMonth()) && (date1.getFullYear() === date2.getFullYear())) {
      return true;
    }
    return false;
  }

  function convertMinutesToHours(minutes) {
    if (minutes === null || minutes === undefined) {
      return '0:0'
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes}`;
  }
  function compactRangeString(tsrange) {
    const dateRangeArray = SQLTsRangeToArray(tsrange)
    const startDate = dateRangeArray[0]
    const endDate = dateRangeArray[1]

    const startDay = startDate.getDate()
    const startMonth = startDate.getMonth()
    const startYear = startDate.getFullYear()

    const endDay =   endDate.getDate()
    const endMonth = endDate.getMonth()
    const endYear =  endDate.getFullYear()
    return `${startDay}/${startMonth}/${startYear}-${endDay}/${endMonth}/${endYear}`
  }

  return {
    utcToIst,
    getUtcToIstString,
    userFriendlyToStoredRange,
    storedRangeToUserFriendly,
    tsRangeStoredToUserFriendly,
    timeStampStoredToUserFriendly,
    storedRangeToDatePickerFormat,
    monthPickerToUtc,
    tsRangeIstToUtc,
    dateFormatter,
    exclusiveDayRangeDisplayStr,
    emptyDayRangeDisplayStr,
    SQLTsRangeToArray,
    arrayToSQLRange,
    compareDates,
    convertMinutesToHours,
    compactRangeString
  }
}

export default getConverters
